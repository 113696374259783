<template>
<div class="table-pdf-csv-download">
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined
        color="mainColor"
        class="button-with-icon text-none"
        v-bind="attrs" v-on="on">
        <i class="material-icons-outlined">file_download</i>
        <span>{{btnLabel?btnLabel:'Download all'}}</span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="downloadCSVAction">
        <v-list-item-title>Download CSV</v-list-item-title>
      </v-list-item>
      <v-list-item @click="generatePDF">
        <v-list-item-title>Download PDF</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</div>
</template>

<script>
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import { mixin as export_mixin } from '@/components/export_mixin'
import keywordInsightsLogo from "../../../public/static/keyword-insights-logo.png"
import topLogo from "../../../public/static/ki-report-logo.png"

function getBase64FromUrl(url, callback) {
  const xhr = new XMLHttpRequest();
  xhr.onload = function() {
    const reader = new FileReader();
    reader.onloadend = function() {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

export default {
  props: ['tableId', 'fileName', 'columns', 'rows', 'title', 'btnLabel'],
  mixins: [ export_mixin ],
  data () {
    return {
      bgImgCode: null,
      reportLogo: null
    }
  },
  methods: {
    generatePDF() {
        const doc = new jsPDF({ orientation: "landscape" });
        doc.setDrawColor(0);
        doc.setFillColor(1, 35, 35);
        doc.rect(0, 0, doc.internal.pageSize.getWidth(), 51, 'F');
        doc.addImage(this.bgImgCode, 'png', 140, 0, 158, 51)
        doc.addImage(this.reportLogo, 'png', 14, 10, 8, 8)

        doc.setTextColor(255, 255, 255);
        doc.setFontSize(18);
        doc.setFont("helvetica", "bold");
        doc.text('POP', 14, 30);
        doc.setTextColor(255, 87, 0);
        const reportTitle = this.title || 'Keyword Insight'

        doc.text(reportTitle, 29, 30);
        doc.setTextColor(255, 255, 255);
        doc.text('Report', reportTitle.length > 15 ? 123 : 80, 30);
        doc.setFontSize(12);
        doc.text('Generate hundreds of keyword ideas for your page.', 14, 40);

        const tableColumn = this.columns.map(col => col.label.toUpperCase());
        const tableRows = this.rows.map(row => this.columns.map(col => {
          const columnValue = col.downloadButtonValue || col.value  // use the download-specific value (if any) or fallback to the same value shown in-page
          if (typeof columnValue === 'function') {
            return columnValue(row)
          } else {
            const cellValue = row[columnValue]
            if (col.isPercentage && cellValue !== null && cellValue !== undefined) {
              return `${cellValue}%`
            }
            return cellValue
          }
        }))

        doc.autoTable({
            startY: 55,
            head: [tableColumn],
            body: tableRows,
            styles: { fontSize: 6 },
            margin: { left: 5, right: 5, top: 5, bottom: 5 },
            theme: 'grid',
            bodyStyles: {
                lineColor: [0, 0, 0, 0]
            },
            willDrawCell: function(data) {
              const doc = data.doc;
              const row = data.row;
              doc.setLineWidth(0);

              if (data.section === 'head') {
                  doc.setFillColor(255, 87, 0);
              }
              if (data.section === 'body' && row.index % 2 === 0) {
                  doc.setFillColor(220, 220, 220);
              }
            }
        });

        const timestamp = moment().format('YYYY_M_D_h_mm_ss')
        doc.save(`${this.fileName}_${timestamp}.pdf`);
    },
    downloadPdfAction () {
      const timestamp = moment().format('YYYY_M_D_h_mm_ss')
      let table = document.querySelector(`#${this.tableId}`);
        let SPAN_SIZE = 60;
        let width = table.clientWidth + SPAN_SIZE * 2;
        let height = width * 0.7;

        let doc = new jsPDF({
            orientation: "landscape",
            unit: "px",
            format: [width, height],
        })

        autoTable(doc, {
            html: `#${this.tableId}`,
            startY: 90,
            margin: {
                top: 90,
                bottom: 80
            },
            // horizontalPageBreak: true,
            // horizontalPageBreakRepeat: 0,
            styles: {
                fontSize: 15,
                cellWidth: 'auto',
            },
            headStyles: {
                cellWidth: 'auto',
                fillColor: 'white',
                textColor: '#212529'
            },
            columnStyles: {
                0: { cellWidth: 'auto' }
            },
        });
        doc.save(`${this.fileName}_${timestamp}.pdf`);
    },
    makeCSVData () {
      let results = []
      results.push(this.columns.map(c => c.label));
      for (let data of this.rows) {
        let row = []
        for (let col of this.columns) {
          const columnValue = col.downloadButtonValue || col.value  // use the download-specific value (if any) or fallback to the same value shown in-page
          if (typeof columnValue === 'function') {
            row.push(
              columnValue(data)
            );
          } else {
            let cellValue = data[columnValue]
            if (col.isPercentage && cellValue !== null && cellValue !== undefined) {
              cellValue = `${cellValue}%`
            }
            row.push(cellValue);
          }
        }
        results.push(row);
      }
      return results.map(item => {
        return item.join(',')
      }).join('\n');
    },
    downloadCSVAction () {
      let csvData = this.makeCSVData()
      const timestamp = moment().format('YYYY_M_D_h_mm_ss')
      return this.exportToCSV(csvData, `${ this.fileName }_${ timestamp }.csv`)    
    }

  },
  mounted () {
    getBase64FromUrl(keywordInsightsLogo, (base64) => {
      this.bgImgCode = base64;
    });
    getBase64FromUrl(topLogo, (base64) => {
      this.reportLogo = base64;
    });
  }

}
</script>

<style lang="scss" scoped>
.table-pdf-csv-download {
  display: inline-block;
}
</style>
<template>
<div class="tool-card pa-5">
  <div class="top-part">
    <span class="icon-part">
      <span class="material-icons-outlined">vpn_key</span>
    </span>
    <span class="name">Keyword Research</span>
  </div>
  <!-- NOTE: The tab selector code below is commented-out in order to hide the Page tab -->

  <!--
  <div class="mt-5">
    <div class="custom-tabs">
      <div v-for="(item, idx) in ['Domain', 'Pages']" :key="idx" :class="{ 'is-active': currentTab == item }"
        @click="() => { currentTab = item }">
        {{ item }}
      </div>
    </div>
    <v-text-field outlined dense
      v-model="searchTerm"
      hide-details="auto"
      label="Search"
      color="greenColor"
      class="mt-2"
      v-if="currentTab == 'Pages' && pageLevelCampaigns.length > 0">
    </v-text-field>
  </div>
  -->
  <template v-if="currentTab == 'Domain'">
    <div class="flex-grow-1 pages-wrapper mt-3">
      <template v-if="domainLevelCampaign">
        <div v-for="report in domainLevelCampaignLatestGeneratedReports" :key="report.data.id" class="page-item" @click="() => {
          $router.push(reportTypeToRoute(report._type))
        }">
          <div class="flex-grow-1">
            <div class="name">{{reportTypeToLabel(report._type)}}</div>
            <div class="time">
              {{ toLocalDateTimeString(report.data.created_at) }}
            </div>
          </div>
          <div class="action">
            <i class="material-icons-outlined" @click="() => {}">arrow_circle_right</i>
          </div>
        </div>
        <!-- <div class="page-item" @click="() => {}">
          <div class="flex-grow-1">
            <div class="name">Keyword tracking</div>
            <div class="time">
              
            </div>
          </div>
          <div class="action">
            <i class="material-icons-outlined" @click="() => {}">arrow_circle_right</i>
          </div>
        </div> -->
        <!-- <div class="page-item" @click="() => {}">
          <div class="flex-grow-1">
            <div class="name">Keyword wizard</div>
            <div class="time">
              
            </div>
          </div>
          <div class="action">
            <i class="material-icons-outlined" @click="() => {}">arrow_circle_right</i>
          </div>
        </div> -->
      </template>
      <div v-if="domainLevelCampaigns.length == 0" class="no-project-block">
        <div>
          <img src="/static/empty-folder.png" />
          <div>
            You haven't created any <br />
            keyword reports for this project yet
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 d-flex align-center justify-space-between">
      <v-btn v-if="!$store.getters.isDemoUser" rounded small color="profileAnchorColor"
        class="button-with-icon whiteColor--text text-none"
        :disabled="!!domainLevelCampaign"
        @click="() => {
          // $router.push(`/sites/${site.id}/newpage`)
          $refs.createDomainCampaignModalRef.showModalAction();
        }">
        <span>Create new</span>
      </v-btn>
      <!-- <span class="how-many" @click="() => {
        $router.push(`/sites/${site.id}/pages?tab=keyword-research`)
      }">
        {{ domainLevelCampaigns.length }} {{ domainLevelCampaigns.length > 1 ? 'domains' : 'domain' }}
      </span> -->
    </div>
  </template>
  <template v-else>
    <div class="flex-grow-1 pages-wrapper mt-3">
      <div v-for="c in filteredPageLevelCampaigns" :key="c.id"  class="page-item" @click="() => {
          $router.push(`/sites/${ siteId }/campaign/${ c.id }`)
        }">
        <div class="flex-grow-1">
          <div class="name">{{ c.campaignName }}</div>
          <div class="time">
            {{ toLocalDateTimeString(c.createdAt) }}
          </div>
        </div>
        <div class="action">
          <i class="material-icons-outlined" @click="() => {}">arrow_circle_right</i>
        </div>
      </div>
      <div v-if="pageLevelCampaigns.length == 0" class="no-project-block">
        <div>
          <img src="/static/empty-folder.png" />
          <div>
            You haven't created any <br />
            keyword reports for this project yet
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 d-flex align-center justify-space-between">
      <v-btn v-if="!$store.getters.isDemoUser" rounded small color="profileAnchorColor"
        class="button-with-icon whiteColor--text text-none"
        @click="() => {
          // $router.push(`/sites/${site.id}/newpage`)
          $refs.createPageCampaignModalRef.showModalAction()
        }">
        <span>Create new</span>
      </v-btn>
      <span class="how-many" @click="() => {
        $router.push(`/sites/${site.id}/pages?tab=keyword-research`)
      }">
        {{ pageLevelCampaigns.length }} {{ pageLevelCampaigns.length > 1 ? 'pages' : 'page' }}
      </span>
    </div>
  </template>

  <create-domain-level-campaign-modal ref="createDomainCampaignModalRef"></create-domain-level-campaign-modal>
  <create-page-level-campaign-modal ref="createPageCampaignModalRef"></create-page-level-campaign-modal>

</div>
</template>

<script>
import { mixin } from '@/components/mixin'

import CreateDomainLevelCampaignModal from '@/components/Tools/CreateDomainLevelCampaignModal.vue'
import CreatePageLevelCampaignModal from '@/components/Tools/CreatePageLevelCampaignModal.vue'


export default {
  mixins: [mixin, ],
  components: {
    CreateDomainLevelCampaignModal,
    CreatePageLevelCampaignModal,
  },
  computed: {
    // site () {
    //   return this.$store.getters.siteById(parseInt(this.$route.params.siteId)) || {}
    // },
    siteId () {
      return parseInt(this.$route.params.siteId)
    },
    domainLevelCampaigns () {
      return this.campaigns.filter(item => item.isDomainLevelCampaign)
    },
    pageLevelCampaigns () {
      return this.campaigns.filter(item => !item.isDomainLevelCampaign)
    },
    domainLevelCampaign () {
      if (this.domainLevelCampaigns.length > 0) {
        return this.domainLevelCampaigns[0]
      }
      return null
    },
    filteredPageLevelCampaigns () {
      return this.pageLevelCampaigns.filter(item => item.campaignName.includes(this.searchTerm))
    },
  },
  watch: {
    siteId: function (newVal, oldVal) {
      this.loadCampaigns()
    },
    domainLevelCampaign: function (newVal, oldVal) {
      if (!newVal) {
        this.domainLevelCampaignLatestGeneratedReports = []
      } else if (newVal && newVal != oldVal) {
        this.domainLevelCampaignLatestGeneratedReports = []
        this.loadCampaignLatestGeneratedReports()
      }
    },
  },
  data () {
    return {
      currentTab: 'Domain', // site / page
      searchTerm: '',
      
      campaigns: [],

      domainLevelCampaignLatestGeneratedReports: [],

    }
  },
  methods: {
    loadCampaigns () {
      this.$store.dispatch('getCampaignsForSite', this.siteId)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.campaigns = response.data.campaigns
        }
      })
    },
    loadCampaignLatestGeneratedReports () {
      this.$store.dispatch('getCampaignLatestGeneratedReports', this.domainLevelCampaign.id)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.domainLevelCampaignLatestGeneratedReports = response.data.result?.length
            ? response.data.result
            : [
              {
                '_type': 'KeywordResearch',
                'data': {
                  id: undefined,
                  created_at: this.domainLevelCampaign.createdAt
                },
              }
            ]
        }
      })
    },
    reportTypeToLabel (reportType) {
      return ({
        'KeywordResearch': 'Keyword Insight',
        'SupportKeywordResearch': 'Supporting Keyword Research',
        'KeywordWizardResearch:PAGE_OPPORTUNITY': 'Keyword Wizard Scenario 1',
        'KeywordWizardResearch:SITE_ONLY_EXIST': 'Keyword Wizard Scenario 2',
        'TopLevelSupportKeywordResearch': 'Keyword Wizard Scenario 3',
        'SiloReport': 'Silo Report',
      })[reportType] || reportType
    },
    reportTypeToRoute (reportType) {
      return ({
        'KeywordResearch': `/sites/${ this.siteId }/campaign/${ this.domainLevelCampaign.id }/keyword-insight`,
        'SupportKeywordResearch': `/sites/${ this.siteId }/campaign/${ this.domainLevelCampaign.id }/supporting-keyword-research`,
        'KeywordWizardResearch:PAGE_OPPORTUNITY': `/sites/${ this.siteId }/campaign/${ this.domainLevelCampaign.id }/keyword-wizard/page-opportunity`,
        'KeywordWizardResearch:SITE_ONLY_EXIST': `/sites/${ this.siteId }/campaign/${ this.domainLevelCampaign.id }/keyword-wizard/site-only`,
        'TopLevelSupportKeywordResearch': `/sites/${ this.siteId }/campaign/${ this.domainLevelCampaign.id }/keyword-wizard/keyword-only`,
        'SiloReport': `/sites/${ this.siteId }/campaign/${ this.domainLevelCampaign.id }/silo`,
      })[reportType] || `/sites/${ this.siteId }/campaign/${ this.domainLevelCampaign.id }/keyword-insight`  // default
    }
  },
  mounted () {
    this.loadCampaigns()    
  }
}
</script>

<style lang="scss" scoped>
.custom-tabs {
  display: flex;
  background: var(--v-lightYellowColor-base);
  border-radius: 10px;
  padding: 3px;
  margin-top: 5px;
  & > div {
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
    color: var(--v-mainColor-base);
    &.is-active {
      background: var(--v-whiteColor-base);
      font-weight: 700;
    }
  }
}
</style>

<template>
<div>
  <div class="card-big-title">
    Keyword Insight
    <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
      @click="() => {
        // $store.commit('setCurrentVideoTutorialToDisplay', {
        //   title: 'How to Set up Bulk Run in POP',
        //   videoLink: 'https://www.youtube.com/embed/Dlz3EKlgKUo?si=fgT-31PaxcSMagps',
        //   description: 'Would you like to set up multiple report runs simultaneously? In this video, Dade, POP\'s Customer Success Manager, will guide you through the process of setting up bulk runs in POP, making project execution faster and smoother. Important: The bulk run feature is available for the team plan users only.',
        //   time: '0m 54s'
        // });
      }">
      videocam
    </span>
  </div>
  <div class="mt-10">
    <div class="d-flex align-center gap10">
      <div class="card-small-title">
        Add keywords <span class="profileAnchorColor--text" >({{ config.keywords.length }}/100)</span>
      </div>
      <div>
        <!-- <i class="material-icons keyword-paste-icon" @click="showEasyKeywordPastePopupAction">content_paste</i>           -->
      </div>
    </div>
    <div class="mt-3 limited-width-box">
      <v-textarea outlined hide-details color="greenColor" v-model="keywordsText" />
    </div>
  </div>
  <div class="mt-10 location-lang-domain-area">
    <div>
      <div class="card-small-title mb-5">
        Select location
      </div>
      <!-- <multiselect class="mt-5 customized-multiselect1"
        v-model="selectedLocation"
        :options="locationOptions"
        placeholder="Select location"
        >
      </multiselect> -->
      <v-autocomplete outlined dense hide-details="auto"
        :items="availableLocations"
        item-text="location_name"
        item-value="location_code"
        :value="config.selectedLocation"
        @input="value => setConfig('selectedLocation', value)"
        color="grayColor">
      </v-autocomplete>
    </div>
    <div>
      <div class="card-small-title mb-5">
        Select language
      </div>
      <!-- <multiselect class="mt-5 customized-multiselect1"
        v-model="selectedLanguage"
        :options="targetLanguages"
        placeholder="Select language"
        >
      </multiselect> -->
      <v-autocomplete outlined dense hide-details
        :items="targetLanguages"
        :value="config.selectedLanguage"
        @input="value => setConfig('selectedLanguage', value)"
        item-text="language_name"
        item-value="language_code"
        color="grayColor">
      </v-autocomplete>
    </div>
    <div>
      <div class="card-small-title mb-5">
        Domain name
      </div>
      <v-text-field outlined dense
        v-model="domain"
        readonly
        color="grayColor"
      ></v-text-field>
    </div>
  </div>
  <div class="mt-5 d-flex align-center gap10">
    <v-btn rounded
      color="profileAnchorColor"
      class="text-none whiteColor--text"
      :disabled="!canProceed"
      @click="getDataAction">
      <span>Get data</span>
    </v-btn>
    <div v-if="config.isCalculating" class="flex-grow-1">
      <v-progress-linear rounded height="8"
        color="profileAnchorColor"
        :value="config.progressObj.value || 0">
      </v-progress-linear>
      <div class="mt-2 mainColor--text">
        <b-icon
          pack="fa"
          icon="spinner"
          custom-class="fa-spin">
        </b-icon>
        <span class="ml-1">{{ config.progressObj.msg }}</span>
      </div>
    </div>
  </div>

  <keyword-research-result
    v-if="config.researchReport && !config.isCalculating"
    title="Keyword Insight"
    reportType="KeywordResearch"
    :showSiloExtraActions="false"
    :report="config.researchReport"
    :researchHistory="config.researchHistory"
    @onChangeResearchId="(id) => setSelectedResearchReportId(id)"
    @onAIResultReady="() => loadResearchReport()"
    @onKeywordLabelUpdateSuccess="onKeywordLabelUpdateSuccess"
    @removeKeywordFromReport="removeKeywordFromReport">
  </keyword-research-result>
</div>
</template>

<script>
import { getHostName } from '@/utils';
import languages from '@/consts/dataforseo_languages.json';
import dataForSeoLocations from '@/consts/dataforseo_locations.json';
import KeywordResearchResult from '@/components/Campaigns/KeywordResearchResult.vue';


const MAX_KEYWORDS_LIMIT = 100

export default {
  props: ['campaignId'],
  components: {
    KeywordResearchResult
  },
  data () {
    return {
      showEasyKeywordPastePopup: false,
      keywordsText: '',

      keywords: [],
      keywordOptions: [],

      domain: '',
      selectedLocation: '',
      selectedLanguage: '',


      campaign: null,
      researchHistory: [],
      selectedResearchReportId: null,
      researchReport: null,

      taskId: null,
      taskInterval: null,
      isCalculating: false,
      progressObj: {},
    }
  },
  computed: {
    config() {
      return this.$store.getters.insightKeywordsConfig;
    },
    availableLocations () {
      return dataForSeoLocations.locations;
    },
    targetLanguages () {
      return languages;
    },

    site () {
      return this.$store.getters.siteById(parseInt(this.$route.params.siteId)) || {}
    },
    canProceed () {
      if (this.config.isCalculating) {
        return false;
      }
      return this.config.keywords.length > 0 && this.config.selectedLocation && this.config.selectedLanguage
    },
    storeTaskId () {
      return this.config.taskId
    },
    selectedReportId () {
      return this.config.selectedResearchReportId
    }
  },
  watch: {
    keywordsText: function (newVal, oldVal) {
      if (newVal != oldVal) {
        let keywords = this.keywordsText.split('\n').reduce((acc, curVal) => {
          if (curVal.trim()) {
            acc.push(curVal.trim())
          }
          return acc
        }, [])
        this.setConfig("keywords", keywords.slice(0, MAX_KEYWORDS_LIMIT))
        if (keywords.length > 100) {
          this.$nextTick(() => {
            this.keywordsText = keywords.concat('').join('\n')
          })
        }
      }
    },
    campaignId: function (newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.getCampaignById()
        this.loadKeywordResearchHistoryForCampaign()
        // this.$nextTick(() => {
        // })
      }
    },
    storeTaskId: function (newVal) {
      if (!newVal) {
        this.clearCalculateStatus();
        return;
      }
      this.createTaskMonitor();
    },
  },
  methods: {
    setConfig(key, value) {
      this.$store.commit("setInsightKeywordsConfig", { key, value });
    },
    setSelectedResearchReportId(id) {
      this.setConfig("selectedResearchReportId", id)
      this.loadResearchReport()
    },
    loadResearchReport () {
      this.$store.dispatch('loadKeywordResearchData', {
        researchId: this.config.selectedResearchReportId,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          const researchReport = response.data.research
          this.setConfig("researchReport", researchReport);
          this.setConfig("taskId", researchReport.taskId);
          this.keywordsText = researchReport.keywords.join("\n")
          this.setConfig("selectedLanguage", researchReport.languageCode)
          this.setConfig("selectedLocation", parseInt(researchReport.locationCode || 0))
        }
      })
    },
    showEasyKeywordPastePopupAction () {
      this.easyKeywordsContent = this.config.keywords.join('\n');
      this.showEasyKeywordPastePopup = true;
    },
    removeKeywordFromReport (item) {
      let data = {
        keyword: item.keyword,
        researchId: this.config.researchReport.id
      }
      this.$store.commit('showLoading')
      this.$store.dispatch('removeKeywordFromKeywordResearch', data)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.loadResearchReport()
          this.$notify({
            group: 'info', type: 'success',
            text: 'Successfully removed the keyword'
          })
        } else {
          this.$notify({
            group: 'info', type: 'error',
            text: 'Failed to remove keyword.'
          })
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      })
    },
    getCampaignById () {
      this.$store.dispatch('getCampaignById', this.campaignId)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            const campaign = response.data.campaign
            this.setConfig("campaign", campaign)
            this.$store.commit('setCurrentCampaign', this.campaign)
            
            this.domain = getHostName(campaign.targetUrl)
            this.setConfig("selectedLanguage", campaign.languageCode || 'en');
            if (campaign.locationCode) {
              this.setConfig("selectedLocation", parseInt(campaign.locationCode));
            }
          }
        })
    },
    loadKeywordResearchHistoryForCampaign( scrollBottom = false ) {
      this.$store.dispatch('getKeywordResearchHistoryForCampaign', {
        campaignId: this.campaignId
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          const researchHistory = response.data.researches
          this.setConfig("researchHistory", researchHistory);
          if (researchHistory.length > 0) {
            this.setSelectedResearchReportId(researchHistory[0].id)
          }
          if (scrollBottom) {
            setTimeout(() => {
              let divObj = document.getElementById("mainBodyScrollArea");
              divObj.scrollTop = divObj.scrollHeight;
            }, 500);
          }
        }
      })
    },
    getDataAction () {
      this.setConfig("isCalculating", true)
      this.setConfig("researchReport", null)
      this.setConfig("progressObj", {
        value: 0,
        msg: ''
      })

      const data = {
        campaignId: this.campaignId,
        keywords: this.config.keywords,
        domain: this.domain,
        locationCode: this.config.selectedLocation,
        languageCode: this.config.selectedLanguage,
      }
      this.$store.dispatch('createKeywordInsightResearch', data)
      .then(response => {
        if (response.data) {
          if (response.data.status == 'SUCCESS') {
            this.setConfig("taskId", response.data.taskId)
          } else if (response.data.status == 'FAILURE') {
            this.$notify({
              group: 'info',
              type: 'warning',
              text: response.data.msg || 'Please provide valid information'
            })
            this.setConfig("isCalculating", false);
            this.setConfig("taskId", null);
          }
        }
      })
    },
    createTaskMonitor () {
      this.setConfig("taskInterval", setInterval(() => {
        this.$store.dispatch('getTaskStatus', this.config.taskId)
        .then(response => {
          if (response.data) {
            if (response.data.status === 'PENDING') {
              // ignore
              // Celery's PENDING state may as well mean that the task is simply unknwon
              // (i.e. could be a really pending task, but could also be an invalid/non-existing task id or could be a completed but forgotten task)
            } else if (response.data.status === 'PROGRESS') {
              this.setConfig("isCalculating", true)
              this.setConfig("progressObj", {
                value: response.data.value,
                msg: `${ response.data.msg }`
              })
            } else if (response.data.status === 'SUCCESS') {
              this.clearCalculateStatus();
              this.loadKeywordResearchHistoryForCampaign();
              this.$notify({
                group: 'info',
                type: 'success',
                text: 'Completed!'
              })
            } else if (response.data.status === 'FAILURE') {
              this.clearCalculateStatus();
              this.$notify({
                group: 'info',
                type: 'error',
                text: response.data.msg || 'Failed!'
              })
            }
          }
        })
      }, 3000))
    },
    clearCalculateStatus () {
      this.setConfig("isCalculating", false)
      if (this.config.taskInterval) {
        clearInterval(this.config.taskInterval)
      }
    },
    onKeywordLabelUpdateSuccess(keyword, label) {
      this.setConfig("researchReport", {
        ...this.config.researchReport,
        result: this.config.researchReport.result.map((row) => {
          if (label === 'T' && row.isTopLevelKeyword) {
            return {
              ...row,
              isTopLevelKeyword: false
            }
          }
          if (row.keyword === keyword) {
            return {
              ...row,
              isTopLevelKeyword: label === 'T',
              isSupportingKeyword: label === 'S',
            }
          }
          return row
        })
      })
    }
  },
  mounted () {
    if (!this.config.campaign) {
      this.getCampaignById()
      this.loadKeywordResearchHistoryForCampaign()
    } else if (!this.domain) {
      this.domain = getHostName(this.config.campaign.targetUrl)
    }
    if (!this.keywordsText && this.config.keywords.length) this.keywordsText = this.config.keywords.join("\n")
  }
}
</script>

<style lang="scss" scoped>
@import "../keyword-research.scss";
</style>
<template>
<div class="mb-5">
  <div class="card-big-title">
    Keyword Gap Research
    <span class="material-icons">do_not_disturb_on</span>
  </div>
  <div class="mt-10">
    <div class="scenarios mt-5">
      <div :class="{ 'is-active': selectedScenario == 'HAVE_GSC' }" @click="() => { selectedScenario = 'HAVE_GSC' }">
        <img src="/static/gap_scenario1.png" />
        <div class="name">Scenario 1</div>
        <div class="explain">
          I have access to GSC data and<br />can connect it to POP
        </div>
      </div>
      <div :class="{ 'is-active': selectedScenario == 'HAVE_NOT_GSC' }" @click="() => { selectedScenario = 'HAVE_NOT_GSC' }">
        <img src="/static/gap_scenario2.png" />
        <div class="name">Scenario 2</div>
        <div class="explain">
          I don't have access to GSC data,<br />I just have the domain name
        </div>
      </div>
    </div>
  </div>
  <div class="mt-10">
    <div class="card-small-title">
      Connect Google account
    </div>
    <div class="mt-5">
      <google-login></google-login>      
    </div>
  </div>
  <div class="mt-10 location-lang-domain-area">
    <div>
      <div class="card-small-title mb-5">
        Select location
      </div>
      <!-- <multiselect class="mt-5 customized-multiselect1"
        v-model="selectedLocation"
        :options="locationOptions"
        placeholder="Select location"
        >
      </multiselect> -->
      <v-autocomplete outlined dense hide-details="auto"
        :items="availableLocations"
        item-text="location_name"
        item-value="location_code"
        v-model="selectedLocation"
        color="grayColor">
      </v-autocomplete>
    </div>
    <div>
      <div class="card-small-title mb-5">
        Select language
      </div>
      <!-- <multiselect class="mt-5 customized-multiselect1"
        v-model="selectedLanguage"
        :options="targetLanguages"
        placeholder="Select language"
        >
      </multiselect> -->
      <v-autocomplete outlined dense hide-details
        :items="targetLanguages"
        v-model="selectedLanguage"
        item-text="language_name"
        item-value="language_code"
        color="grayColor">
      </v-autocomplete>
    </div>
    <!-- <div v-if="selectedScenario == 'HAVE_NOT_GSC'">
      <div class="card-small-title mb-5">
        Add Domain name
      </div>
      <v-text-field outlined dense
        v-model="domain"
        readonly
        color="grayColor"
      ></v-text-field>
    </div> -->
  </div>
  <div class="mt-10 small-width-area" v-if="selectedScenario == 'HAVE_GSC'">
    <div class="d-flex align-center gap10">
      <div class="card-small-title">
        Exclude branded keywords
      </div>
      <span class="input-count-info">{{ brandedKeywords.length }} / 10</span>
      <div class="flex-grow-1"></div>
    </div>
    <v-textarea outlined hide-details
      v-model="brandedKeywordText"
      color="greenColor"
      class="mt-3"></v-textarea>
  </div>
  <!-- <div>
    {{ domain }} //
    {{ competitors }}
  </div> -->
  <div class="mt-10">
    <div class="card-small-title">
      Enter up to 10 competitors
    </div>
    <div v-for="(c, idx) in competitors" :key="idx" class="mt-3 mb-2 d-flex align-center gap10" style="max-width: 700px;">
      <div class="flex-grow-1">
        <v-text-field outlined dense hide-details
          color="greenColor"
          v-model="competitors[idx].url"
          :append-icon="'cancel'">
        </v-text-field>
      </div>
      <div style="width: 120px;">
        <v-select outlined dense hide-details
          v-model="competitors[idx].type"
          item-text="label"
          item-value="value"
          :items="[
            {label: 'Domain', value: 'site'}, {label: 'Page', value: 'page'}
          ]"
        ></v-select>
      </div>
    </div>
    <div class="d-flex">
      <div class="add-new-competitor-btn" @click="addNewCompetitorAction">
        <i class="material-icons">add</i>
        <span>Add new site</span>
      </div>
    </div>
  </div>
  <div class="mt-10">
    <v-btn rounded @click="findOppotunitiesAction"
      color="profileAnchorColor"
      :disabled="!canProceed"
      class="text-none whiteColor--text">
      <span>Find opportunities</span>
    </v-btn>
  </div>

  <div class="mt-10">
    <div class="d-flex align-center gap10">
      <div class="card-small-title">
        Keyword Gap Overview
      </div>
      <div style="max-width: 340px;">
        <v-autocomplete outlined dense hide-details
          :items="keywordGapHistory"
          item-value="id"
          :value="selectedGapReportId"
          @change="(id) => {
            selectedGapReportId = id
          }"
        >
          <template v-slot:item="{ item }">
            <div>
              <div class="has-text-weight-bold">Report ID: {{ item.id }}</div>
              <div>{{ toLocalDateTimeString(item.createdAt) }}</div>
            </div>
          </template>
          <template v-slot:selection="{ item }">
            <span class="has-text-weight-bold">Report ID: {{ item.id }}</span>
            &nbsp;&nbsp;
            <span>{{ toLocalDateTimeString(item.createdAt) }}</span>
          </template>
        </v-autocomplete>
      </div>
    </div>
  </div>

  <keyword-gap-result
    :gapReport="gapReport"></keyword-gap-result>

</div>
</template>

<script>
import GoogleLogin from '@/components/Campaigns/GoogleLogin.vue';
import KeywordGapResult from './KeywordGapResult.vue';

import dataForSeoLocations from '@/consts/dataforseo_locations.json';
import languages from '@/consts/dataforseo_languages.json';
import { toLocalDateString, toLocalDateTimeString, getHostName } from '@/utils'

export default {
  components: {
    GoogleLogin,
    KeywordGapResult
  },
  data () {
    return {
      campaign: null,
      
      keywordGapHistory: [],
      selectedGapReportId: null,
      gapReport: null,

      selectedScenario: 'HAVE_GSC',  // HAVE_GSC, HAVE_NOT_GSC
      selectedLocation: '',
      selectedLanguage: '',
      domain: '',
      // period: '',
      brandedKeywordText: '',
      competitors: [
        {
          type: 'site',
          url: '',
        }
      ],

      taskId: '',
      taskInterval: null,
    }
  },
  computed: {
    campaignId () {
      return parseInt(this.$route.params.campaignId || 0)
    },
    availableLocations () {
      return dataForSeoLocations.locations;
    },
    targetLanguages () {
      return languages;
    },
    availablePeriods () {
      return ['Last 3 months', 'Last 6 months']
    },
    googleUser () {
      return this.$store.state.googleUser
    },
    brandedKeywords () {
      return this.brandedKeywordText.split('\n').reduce((acc, item) => {
        if (item && item.trim()) {
          acc.push(item.trim().toLowerCase())
        }
        return acc
      }, [])
    },
    canProceed () {
      if (this.competitors.length == 0) {
        return false;
      }
      for (const c of this.competitors) {
        if (!c.url.trim() || !c.url.startsWith('http')) {
          return false;
        }
      }
      return this.selectedLanguage && this.selectedLocation;
    }
  },
  watch: {
    campaignId: function (newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.getCampaignById()
        this.loadGapSearchHistoryForCampaign()
      }
    },
    selectedGapReportId: function (newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.loadGapReportData()
      }
    },
    taskId: function (newVal, oldVal) {
      if (newVal) {
        if (newVal != oldVal) {
          this.taskInterval = setInterval(() => {
            this.$store.dispatch('getTaskStatus', this.taskId)
            .then(response => {
              if (response.data) {
                if (response.data.status == 'SUCCESS') {
                  this.clearCalculateStatus();
                  this.loadGapSearchHistoryForCampaign()
                } else if (response.data.status == 'PROGRESS') {
                  // yet progressing...
                } else if (response.data.status == 'FAILURE') {
                  this.clearCalculateStatus();
                }
              } else {
                this.clearCalculateStatus();
              }
            })
            .catch((error) => {
              console.log(error);
              this.clearCalculateStatus();
            })
          }, 3000);
        }
      } else {
        this.$store.commit('hideLoading')
        // if (t)
      }
    }
  },
  methods: {
    toLocalDateTimeString (d) {
      return toLocalDateTimeString(d)
    },

    clearCalculateStatus () {
      if (this.taskInterval) {
        clearInterval(this.taskInterval);
      }
      this.$store.commit('hideLoading');
    },
    getCampaignById () {
      this.$store.dispatch('getCampaignById', this.campaignId)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.campaign = response.data.campaign
            // this.$store.commit('setCurrentCampaign', this.campaign)
            
            this.domain = getHostName(this.campaign.targetUrl)
            this.selectedLanguage = this.campaign.languageCode || 'en'
            if (this.campaign.locationCode) {
              this.selectedLocation = parseInt(this.campaign.locationCode || 0)
            }
          }
        })
    },
    addNewCompetitorAction () {
      this.competitors = [
        ...this.competitors,
        {
          type: 'site',
          url: ''
        }
      ]
    },
    loadGapSearchHistoryForCampaign(scrollBottom = false) {
      this.$store.dispatch('loadKeywordGapHistory', {
        campaignId: this.campaignId,
      }).then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.keywordGapHistory = response.data.keywordGapHistory
          if (this.keywordGapHistory.length > 0 && (!this.gapReport || this.selectedGapReportId < this.keywordGapHistory[0].id)) {
            this.selectedGapReportId = this.keywordGapHistory[0].id
          }
        }

        if (scrollBottom) {
          setTimeout(() => {
            let divObj = document.getElementById("mainBodyScrollArea");
            divObj.scrollTop = divObj.scrollHeight;
          }, 500);
        }
      })
    },
    loadGapReportData () {
      this.$store.dispatch('loadOneKeywordGapData', {
        reportId: this.selectedGapReportId,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.gapReport = response.data.report;
          this.selectedLanguage = this.gapReport.languageCode
          this.selectedLocation = parseInt(this.gapReport.locationCode || 0)
          this.competitors = this.gapReport.competitorsSites.filter(c => c.url != this.domain)
          this.brandedKeywordText = this.gapReport.brandedKeywords.join('\n')

          setTimeout(() => {
            let divObj = document.getElementById("mainBodyScrollArea");
            divObj.scrollTop = divObj.scrollHeight;
          }, 500);
        }
      })
    },
    findOppotunitiesAction () {
      // if (this.selectedScenario == 'HAVE_GSC' && !this.googleUser) {
      //   this.$notify({
      //     group: 'info', type: 'warning',
      //     text: 'Please connect google account first.'
      //   })
      // }
      const data = {
        campaignId: this.campaignId,
        competitorsSites: this.competitors,
        domain: this.domain,
        locationCode: this.selectedLocation,
        languageCode: this.selectedLanguage,
        brandedKeywords: this.brandedKeywords,
      }
      console.log(data)
      this.$store.commit('showLoading');
      this.$store.dispatch('createKeywordGapAnalysis', data)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.taskId = response.data.taskId
          this.$notify({
            group: 'info', type: 'success',
            text: 'Keyword gap analysis is started..'
          })
        } else {
          this.$store.commit('hideLoading')
          this.$notify({
            group:' info', type: 'error',
            text: 'Failed to create keyword gap analysis'
          })
        }
      })
    }
  },
  mounted () {
    if (!this.campaign) {
      this.getCampaignById()
      this.loadGapSearchHistoryForCampaign()
    }
  }

}
</script>

<style lang="scss" scoped>
@import "../keyword-research.scss";

.scenarios {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  & > div {
    border: 1px solid var(--v-grayColor-base);
    border-radius: 3.7px;
    min-width: 290px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--v-cardBg-base);
    &.is-active {
      border: 1px solid var(--v-profileAnchorColor-base);
    }



    img {
      width: auto;
      height: 60px;
    }
    .name {
      color: var(--v-mainColor-base);
      font-size: 0.9rem;
      font-weight: 800;
    }
    .explain {
      color: var(--v-mainColor-base);
      font-size: 0.75rem;
    }
  }
}
.add-new-competitor-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  i {
    background: var(--v-lightYellowColor-base);
    color: var(--v-profileAnchorColor-base);
    border-radius: 50%;
    font-size: 1rem;
  }
  span {
    color: var(--v-mainColor-base);
  }
}
</style>
<template>
  <div>
    <voltage-search-form
      :isForPageLevel="selectedScenario == 'SITE_URL_EXIST'"
      :selectedScenario="selectedScenario"
    ></voltage-search-form>

    <div v-if="isLoadingHistory" class="mt-10">
      Loading last saved data...
    </div>
    <div v-else-if="Boolean(config.wizardResultsHistoryMetadata)" class="mt-10">
      Showing last saved data ({{ config.wizardResultsHistoryMetadata.createdAt }})
    </div>

    <voltage-table
      :isForPageLevel="selectedScenario == 'SITE_URL_EXIST'"
      :resultData="config.wizardResults"
      :showSiloExtraActions="false"
      @onKeywordLabelUpdateSuccess="onKeywordLabelUpdateSuccess"
      @onSearchVolumeTaskStarted="() => {
        this.createMonthlySearchTaskMonitor()
      }"
      @onLabelsTaskStarted="() => {
        this.createLabelsTaskMonitor()
      }"></voltage-table>
  
  </div>
  </template>
  
  
  <script>
  import VoltageSearchForm from './VoltageSearchForm.vue';
  import VoltageTable  from './VoltageTable.vue';
  import { chain, uniq } from 'lodash'
  
  export default {
    props: {
      selectedScenario: {
        default: '',
      },
      campaignId: {
        default: 0,
      }
    },
    components: {
      VoltageSearchForm,
      VoltageTable,
    },
    computed: {
      campaign () {
        return this.$store.state.currentCampaign
      },
      config() {
        return this.$store.getters.insightKeywordsConfig;
      },
    },
    watch: {
      selectedScenario: function (newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          if (this.resultData.length == 0) {
            this.loadHistory({})
          }
        }
      }
    },
    data () {
      return {
        isLoadingHistory: false,
      }
    },
    methods: {
      setConfig(key, value) {
        this.$store.commit("setInsightKeywordsConfig", { key, value });
      },
      loadHistory ({silent = false}) {
        if (!silent) {
          this.isLoadingHistory = true
          this.setConfig('wizardResults', null)
          this.setConfig('wizardResultsHistoryMetadata', null)
        }

        let data = {
          campaignId: this.campaignId,
          tabType: this.selectedScenario
        }
        return this.$store.dispatch('loadRecentKeywordWizardData', data)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            if (response.data.wizardData) {
              // put stored result in component state
              this.resultData = response.data.wizardData.result;
              const pageUrls = chain(this.resultData).map('page').uniq().value();
              this.setConfig('wizardPageUrls', pageUrls);
              this.setConfig('wizardResults', response.data.wizardData.result)

              // also put history meta data in component state
              const {result: _, ...historyMetaData} = response.data.wizardData
              this.setConfig('wizardResultsHistoryMetadata', historyMetaData)
            }
          }
          this.isLoadingHistory = false
        }).catch(() => {
          this.isLoadingHistory = false
        })
      },
      createMonthlySearchTaskMonitor () {
        this.setConfig("wizardMonthlySearchInterval", setInterval(() => {
          this.$store.dispatch('getTaskStatus', this.config.wizardResultsHistoryMetadata.monthlySearchTaskId)
          .then(response => {
            if (response.data) {
              if (response.data.status == 'PENDING') {
                // ignore
                // Celery's PENDING state may as well mean that the task is simply unknwon
                // (i.e. could be a really pending task, but could also be an invalid/non-existing task id or could be a completed but forgotten task)
              } else if (response.data.status == 'PROGRESS') {
                this.setConfig('wizardIsCalculating', true)
                this.setConfig('wizardProgressObj', { value: response.data.value, msg: response.data.msg })
              } else if (response.data.status == 'SUCCESS') {
                this.clearMonthlySearchInterval();
                this.loadHistory({silent: true})
                  .then(() => this.setConfig('wizardIsCalculating', false))
              } else if (response.data.status == 'FAILURE') {
                this.clearMonthlySearchInterval();
                this.setConfig('wizardIsCalculating', false)
                this.$notify({
                  group: 'info',
                  type: 'error',
                  text: response.data.msg || 'Failed!'
                })
              }
            }
          })
        }, 3000))
      },
      createLabelsTaskMonitor () {
        this.setConfig("wizardLabelsTaskInterval", setInterval(() => {
          this.$store.dispatch('getTaskStatus', this.config.wizardResultsHistoryMetadata.labelsTaskId)
          .then(response => {
            if (response.data) {
              if (response.data.status == 'PENDING') {
                // ignore
                // Celery's PENDING state may as well mean that the task is simply unknwon
                // (i.e. could be a really pending task, but could also be an invalid/non-existing task id or could be a completed but forgotten task)
              } else if (response.data.status == 'PROGRESS') {
                this.setConfig('wizardIsCalculating', true)
                this.setConfig('wizardProgressObj', { value: response.data.value, msg: response.data.msg })
              } else if (response.data.status == 'SUCCESS') {
                this.clearLabelsTaskInterval();
                this.loadHistory({silent: true})
                  .then(() => this.setConfig('wizardIsCalculating', false))
              } else if (response.data.status == 'FAILURE') {
                this.clearLabelsTaskInterval();
                this.setConfig('wizardIsCalculating', false)
                this.$notify({
                  group: 'info',
                  type: 'error',
                  text: response.data.msg || 'Failed!'
                })
              }
            }
          })
        }, 3000))
      },
      clearMonthlySearchInterval () {
        if (this.config.wizardMonthlySearchInterval) {
          clearInterval(this.config.wizardMonthlySearchInterval)
        }
      },
      clearLabelsTaskInterval () {
        if (this.config.wizardLabelsTaskInterval) {
          clearInterval(this.config.wizardLabelsTaskInterval)
        }
      },
      onKeywordLabelUpdateSuccess(page, keyword, label) {
        this.setConfig("wizardResults", this.config.wizardResults.map((row) => {
          if (page === row.page) {
            if (label === 'T' && row.isTopLevelKeyword) {
              return {
                ...row,
                isTopLevelKeyword: false
              }
            }
            if (row.keyword === keyword) {
              return {
                ...row,
                isTopLevelKeyword: label === 'T',
                isSupportingKeyword: label === 'S',
              }
            }
          }
          return row
        }))
      }
    },
    mounted () {
      // ON MOUNTED
      // - init form with custom values from route params (if applicable)
      // - try loading data from backend (but only if global state is empty)
      // - then, check if we have a background monthly search data retrieval task (if we do show loading for that)

      if (this.$route.params.initialFormValue) {
        Object.keys(this.$route.params.initialFormValue).forEach(key => {
          if (key === 'wizardSelectedPageUrls') {
            const urls = this.$route.params.initialFormValue[key]

            // make sure urls exist as options (mainly needed when wizardPageUrls are not yet loaded)
            this.setConfig('wizardPageUrls', uniq([...this.config.wizardPageUrls, ...urls]))

            // set selected
            this.setConfig('wizardSelectedPageUrls', this.$route.params.initialFormValue[key])
          }
        })
      }

      (
        this.config.wizardResultsHistoryMetadata
          ?Promise.resolve()
          :this.loadHistory({})
      ).then(() => {
        if (this.config.wizardResultsHistoryMetadata?.monthlySearchTaskId) {
          this.$store.dispatch('getTaskStatus', this.config.wizardResultsHistoryMetadata.monthlySearchTaskId)
            .then(response => {
              if (response?.data?.status == 'PENDING') {
                // ignore
                // Celery's PENDING state may as well mean that the task is simply unknwon
                // (i.e. could be a really pending task, but could also be an invalid/non-existing task id or could be a completed but forgotten task)
              } else if (response?.data?.status == 'PROGRESS') {
                this.setConfig('wizardIsCalculating', true)
                this.setConfig('wizardProgressObj', { value: response.data.value, msg: response.data.msg })
                this.createMonthlySearchTaskMonitor()
              } 
            })
        }
        if (this.config.wizardResultsHistoryMetadata?.labelsTaskId) {
          this.$store.dispatch('getTaskStatus', this.config.wizardResultsHistoryMetadata.labelsTaskId)
            .then(response => {
              if (response?.data?.status == 'PENDING') {
                // ignore
                // Celery's PENDING state may as well mean that the task is simply unknwon
                // (i.e. could be a really pending task, but could also be an invalid/non-existing task id or could be a completed but forgotten task)
              } else if (response?.data?.status == 'PROGRESS') {
                this.setConfig('wizardIsCalculating', true)
                this.setConfig('wizardProgressObj', { value: response.data.value, msg: response.data.msg })
                this.createLabelsTaskMonitor()
              } 
            })
        }
      })
    },
    destroyed () {
      this.setConfig('wizardIsCalculating', false)
      this.clearMonthlySearchInterval()
      this.clearLabelsTaskInterval()
    }
  }
  </script>
  
  
  <style scoped lang="scss">
  
  </style>

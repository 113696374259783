<template>
<div>
  <section>
    <div>
      <div class="ml20 mr20 dashboard-name">{{ site.name }}</div>
      <div class="tools-tab mt-7">
        <ul class="pl20 pr20">
          <li v-for="tool in toolTabs" :key="tool.key" :class="{ 'is-active': tool.key == currentToolTab }"
            @click="toolChangeAction(tool.key)">
            {{ tool.label }}
          </li>
        </ul>
      </div>
      <div class="mt-5 ml20 mr20 dashboard-cards-name">
        {{ currentToolTab === "on-page-optimization" ? "Pages in this project" : '' }}
        {{ currentToolTab === "ai-writer" ? "Written with AI" : '' }}
        {{ currentToolTab === "keyword-research" ? "Pages in this project" : '' }}
        
      </div>
    </div>
    <template v-if="['on-page-optimization', 'ai-writer'].includes(currentToolTab)">
      <div v-if="pagesInCurrentTool.length == 0">
        <div v-if="!user.isVendor" class="empty-project">
          <img class="no-page-img" src="/static/no-page.png" />
          <div class="title-label">You don't have any pages yet</div>
          <div class="explain">
            Your project is like a folder for the website you are working on.
            Within a project you can have multiple pages. These all must be within the same domain.
          </div>
          <v-btn rounded outlined
            color="grayColor"
            class="button-with-icon text-none mt20 gray2Color--text"
            @click="() => {
              if (currentToolTab == 'on-page-optimization') {
                addPage()
              } else {
                $refs.aiReportPopupRef.showAiReportPopup();
              }
            }"
            >
            <i class="material-icons font-weight-bold">add</i>
            <span class="font-weight-bold">Add new page</span>
          </v-btn>
        </div>
      </div>
      <p v-if="errMsg" class='subtitle has-text-centered' style="color: red;">{{ errMsg }}</p>
      <div class="pl20 pr20" ref="observer">
        <div v-if="selectedPageIds.length > 0"
          class="flex flex-column white-common-box mb-3">
          <div class="d-flex align-center pa-3">
            <div class="gray2Color--text mr20">
              {{ selectedPageIds.length }} Page{{ selectedPageIds.length>1 ? 's' : '' }} selected
            </div>
            <v-btn small outlined
              color="red3Color"
              class="button-with-icon whiteColor--text text-none ml10"
              @click.stop="showDeletePagesConfirmModal = true">
              <i class="material-icons">delete_outline</i>
              <span>Delete pages</span>
            </v-btn>
            <v-btn small outlined
              color="mainColor"
              class="button-with-icon text-none ml10"
              @click.stop="cancelAllSelection">
              <span>Unselect all</span>
            </v-btn>
          </div>
        </div>
        <div class='sites-or-pages-container mt-6' ref="sitesContainer">
          <div tag="div"
            class='site-page-card'
            :class="{'selected-site-page': isSelectedPage(page.id)}"
            v-for='page in paginatedPages'
            :key='page.id'>
            <div class="top-part border-bottom is-size-6 title-font mainColor--text flex align-center">
              <span class="d-flex align-center flex-gap-10">
                <i v-if="isSelectedPage(page.id)" class="material-icons checked-box" @click="unSelectPage(page.id)">check_box</i>
                <i v-else class="material-icons checked-box" @click="selectPage(page.id)">check_box_outline_blank</i>
                <v-tooltip top color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"
                      class="material-icons pin-icon" :class="{ 'pinned': page.isPinned }"
                      @click="() => pinPageSettingAction(page.id, !page.isPinned)">push_pin</span>
                  </template>
                  <div class="white-bg-tooltip small-tooltip">
                    {{ page.isPinned ? 'Click to unpin' : 'Pin this Page to the top of your dashboard to find it easily.' }}
                  </div>
                </v-tooltip>
                <v-tooltip top color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"
                      class="material-icons guard-icon" :class="{ 'guarded': page.isGoogleGuardEnabled && page.guardRepeat != '' }">
                      verified_user
                    </span>
                  </template>
                  <div class="white-bg-tooltip small-tooltip">
                    {{ page.isGoogleGuardEnabled && page.guardRepeat != '' ? 'This Page is monitored by POP Watchdog.' : 'This Page is not monitored by POP Watchdog.' }}
                  </div>
                </v-tooltip>
                
                
              </span>
              <div class="flex-grow-1 ml-3 mr-3">
                  <div v-if="isAiTab && page.lastPageRunInfo && page.lastPageRunInfo.pageRunId"
                    class="signal-element">
                    <img src="/static/starIcon.svg" class="mr-2" />
                    <span>Written with AI</span>
                  </div>
              </div>
              <div>
                <v-btn icon :id="`dot-menu-for-${page.id}`">
                  <span class="material-icons-outlined mainColor--text">more_horiz</span>
                </v-btn>
                <v-menu 
                  :activator="`#dot-menu-for-${page.id}`"
                  bottom offset-y>
                  <v-list>
                    <v-list-item link @click="enterPageSetting(page)">
                      <v-list-item-title
                        class="mainColor--text">
                        Page setting
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="displayDeletePageConfirmModal(page.id)">
                      <v-list-item-title
                        class="mainColor--text">
                        Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <div class="main-part">
              <div class="flex-grow-1">
                <div class="d-flex align-center" :class="{'mt-3': !isAiTab}">
                  <div class="flex-grow-1">
                    <div class="mt-3 mainColor--text font-weight-regular">
                      Date created: {{ getDateAsLocalString(page.createdAt) }}
                    </div>
                  </div>
                </div>
                <div class="mt-3 tooltip is-tooltip-top"
                  style="max-width: 100%;" :data-tooltip="page.name">
                  <div class="project-name truncate-ellipse-one-line">
                    {{ page.name }}
                  </div>
                </div>
                <report-score 
                  :currentPage="page" 
                  :currentToolTab="currentToolTab" 
                />
              </div>
              <footer>
                <v-btn
                  rounded block outlined
                  color="white"
                  :to="`/sites/${$route.params.siteId}/pages/${page.id}?tab=${currentToolTab}`"
                  class="view-project-page-button mainColor--text text-none">
                  <span v-if="isAiTab">
                    View AI Writer page runs
                  </span>
                  <span v-else>
                    View PageRuns
                  </span>
                  <i class="material-icons">arrow_forward</i>
                </v-btn>
              </footer>
            </div>
          </div>
        </div>
      </div>
      <paginate
        v-if="pageCount > 1"
        :value="currentPageForPage"
        :page-count="pageCount"
        prev-text="<i class='material-icons'>navigate_before</i>"
        next-text="<i class='material-icons'>navigate_next</i>"
        container-class="card-pagination"
        :click-handler="paginateFunc">
      </paginate>
    </template>
    <template v-else-if="currentToolTab == 'keyword-research'">
      <keyword-researches></keyword-researches>
    </template>
  </section>

  <div class="modal" :class="{'is-active': showDeleteConfirmPageModal}">
    <div class="modal-background" @click="showDeleteConfirmPageModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden;">
        <div class="modal-content-root">
          <div class="title-font is-size-4 mb-5 mainColor--text">
            Are you sure you want to delete this page? <br/>
            <span v-if="errMsg!=''">{{ errMsg }}</span>
          </div>
          <div class="control d-flex">
            <div class="flex-grow-1">
            </div>
            <v-btn rounded outlined
              color="mainColor"
              class="button-with-icon mainColor--text text-none"
              @click="showDeleteConfirmPageModal=false">
              <i class="fa fa-times mr5"></i>
              <span>Cancel</span>
            </v-btn>
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon text-none whiteColor--text" @click="deletePage">
              <i class="fa fa-trash mr5"></i>
              <span>Delete</span>
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showDeleteConfirmPageModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>

  <div class="modal" :class="{'is-active': showDeletePagesConfirmModal}">
    <div class="modal-background" @click="showDeletePagesConfirmModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden;">
        <div>
          <div class="title-font is-size-4 mb20">
            Are you sure you want to delete these Pages and all their PageRun Reports? <br/>
          </div>
          <div class="body-font has-text-weight-bold is-size-6">
            The following Pages have been selected:
          </div>
          <div v-for="page in selectedPages" :key="page.id" class="is-size-6" style="max-height: 400px; overflow-y: auto;">
            <span class="has-text-weight-bold">- {{ page.name }}</span>
            <span class="is-size-7">{{ getDateAsLocalString(page.createdAt) }}</span>
          </div>
        </div>
        <div>
          <v-btn rounded
            color="profileAnchorColor"
            class="button-with-icon mainColor--text text-none" @click="showDeletePagesConfirmModal=false">
            <i class="fa fa-times mr5"></i>
            <span>Cancel</span>
          </v-btn>
          <v-btn rounded
            color="grayColor"
            class="button-with-icon darkGrayColor--text text-none ml10" @click="deletePages">
            <i class="fa fa-trash mr5"></i>
            Delete
          </v-btn>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showDeletePagesConfirmModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>

  <page-setting-modal ref="pageSettingModal" />

  <ai-report-popup ref="aiReportPopupRef" />

</div>

</template>

<script>
import moment from 'moment'
import { mixin } from '@/components/mixin'
import PageSettingModal from '@/components/PageSettingModal.vue'
import ReportScore from '@/components/ReportScore.vue'
import { mixin as notificaitons_mixin } from '@/components/mixins/common_notifies';
import Paginate from 'vuejs-paginate'
import { toolTabs } from '@/consts/ui_consts.js';
import AiReportPopup from "@/components/AiReportPopup.vue";
import KeywordResearches from "@/components/pages/KeywordResearches.vue";

export default {
  mixins: [mixin, notificaitons_mixin],
  components: {
    PageSettingModal,
    Paginate,
    ReportScore,
    KeywordResearches,

    AiReportPopup
  },
  data () {
    return {
      currentToolTab: '',

      errMsg: '',

      // This is for edit the site name
      siteId: '',
      siteName: '',
      isEditMode: false,


      selectedPageId: 0,
      showDeleteConfirmPageModal: false,

      selectedPageIds: [],
      showDeletePagesConfirmModal:false,

      ro: null,
    }
  },
  computed: {
    selectedSubAccountRelation () {
      return this.$store.state.selectedSubAccountRelation
    },
    toolTabs () {
      if (
        this.isSpecialAdminUser || this.isSpecialOwnerSelected || 
        ( this.selectedSubAccountRelation && this.selectedSubAccountRelation.grantedDevModeTools.length > 0 )
      ) {
        return toolTabs
      } else if (this.user.grantedDevModeTools.length > 0) {
        return toolTabs
      } else {
        return toolTabs.filter(item => !item.isSpecialUserOnly)
      }
    },
    _ () {
      return moment
    },
    selectedPages () {
      return this.selectedPageIds.map(pageId => {
        let found = this.pages.find(ele => {
          return ele.id==pageId
        })
        return found
      })
    },
    user () {
      return this.$store.state.user
    },
    isWatchdogOnlyUser () {
      return this.$store.getters.isWatchdogOnlyUser
    },
    isSpecialAdminUser () {
      return this.$store.getters.isSpecialAdminUser
    },
    isSpecialOwnerSelected () {
      return this.$store.getters.isSpecialOwnerSelected
    },
    availableReportCreditsOfUserOrTeam () {
      return this.$store.getters.availableReportCreditsOfUserOrTeam
    },
    canCreateNewPage () {
      if (this.availableReportCreditsOfUserOrTeam >= 2) {
        return true;
      }
      return false;
    },
    canCreateRerun () {
      if (this.availableReportCreditsOfUserOrTeam >= 1) {
        return true;
      }
      return false;
    },
    pagesInCurrentTool () {
      return this.pages.filter(p => {
        switch (this.currentToolTab) {
          case 'on-page-optimization':
            return true;
          case 'ai-writer':
            return p.hasAiArticle
          default:
            return false;
        }
      })
    },
    pagesPerPage () {
      return this.$store.state.pagesPerPage
    },
    currentPageForPage () {
      return this.$store.state.currentPageForPage
    },
    pageCount () {
      return Math.ceil(this.pagesInCurrentTool.length / this.pagesPerPage)
    },
    isAiTab () {
      return this.currentToolTab === 'ai-writer'
    },
    paginatedPages () {
      let from = (this.currentPageForPage - 1) * this.pagesPerPage
      let to = this.currentPageForPage * this.pagesPerPage
      return this.pagesInCurrentTool.slice(from, to)
    }
  },
  methods: {
    toolChangeAction (toolKey) {
      this.currentToolTab = toolKey
      this.selectedPageIds = []
    },
    paginateFunc (pageNumber) {
      this.$store.commit('setCurrentPageForPage', pageNumber)
    },
    addPage () {
      if (this.isWatchdogOnlyUser) {
        this.showCanNotCreatePageBecauseYouWatchdogUserNotification();
        return;
      }
      if (this.canCreateNewPage) {
        this.errMsg = ''
        this.$router.push(`/sites/${this.site.id}/newpage`)
      } else {
        this.showInsufficientFundModal = true
        this.errMsg = 'Insufficient report balance to create a page.  Please purchase more reports in your user profile dashboard.'
      }
    },
    isSelectedPage(pageId) {
      return this.selectedPageIds.includes(pageId)
    },
    selectPage (pageId) {
      if (this.selectedPageIds.indexOf(pageId)===-1) {
        this.selectedPageIds.push(pageId)
      }
    },
    unSelectPage (pageId) {
      let idx = this.selectedPageIds.indexOf(pageId)
      if(idx>=0) {
        this.selectedPageIds.splice(idx, 1)
      }
    },
    cancelAllSelection () {
      this.selectedPageIds = []
    },
    enterPageSetting(page) {
      this.$refs.pageSettingModal.show(page)
    },
    displayDeletePageConfirmModal (pageId) {
      this.selectedPageId = pageId
      this.errMsg = ''
      this.showDeleteConfirmPageModal = true
    },
    deletePage () {
      console.log('Delete Page ' + this.selectedPageId)
      if (this.selectedPageId>0) {
        this.$store.commit('showLoading')

        const { siteId } = this.$route.params
        this.$store.dispatch('removePage', { siteId, pageId: this.selectedPageId })
          .then((response) => this.$store.dispatch('loadSites'))
          .then((response) => {
            if (response.data && response.data.status === 'SUCCESS') {
              this.$store.commit('setSites', response.data.sites)
              return 1
            }
          })
          .finally(() => {
            this.showDeleteConfirmPageModal = false
            this.$store.commit('hideLoading')
          })
      }
    },
    deletePages () {
      if (this.selectedPageIds.length>0) {
        this.$store.commit('showLoading')

        const { siteId } = this.$route.params
        this.$store.dispatch('removePages', { siteId, pageIds: this.selectedPageIds })
          .then((response) => {
            this.selectedPageIds = []
            return this.$store.dispatch('loadSites')
          })
          .then((response) => {
            if (response.data && response.data.status === 'SUCCESS') {
              this.$store.commit('setSites', response.data.sites)
              return 1
            }
          })
          .finally(() => {
            this.showDeletePagesConfirmModal = false
            this.$store.commit('hideLoading')
          })
      }      
    },
    loadSites() {
      this.$store.dispatch('loadSites')
      .then((response) => {
        if (response.data && response.data.status === 'SUCCESS') {
          this.$store.commit('setSites', response.data.sites)
          this.$store.commit('hideLoading')
        }
      })
      .finally(() => this.$store.commit('hideLoading'))
    },
    // loadPages () {
    //   return this.$store.dispatch('loadPages', this.$route.params)
    //     .then((response) => {
    //       if (response.data && response.data.status === 'SUCCESS') {
    //         this.$store.commit('setPages', response.data.pages)
    //         return 1
    //       }
    //     })
    // },
    loadSite () {
      this.$store.commit('showLoading')
        return this.$store.dispatch('loadSite', this.site.id)
          .then((response) => {
            if (response.data && response.data.status === 'SUCCESS') {
              if (response.data.site) {
                this.$store.commit('setSite', response.data.site)
              }
            }
          })
          .finally(() => this.$store.commit('hideLoading'))
    },
    pinPageSettingAction (pageId, isPinned) {
      this.$store.commit('showLoading');
      this.$store.dispatch("updatePageSettings", {
        pageId,
        changePinOnly: true,
        isPinned: isPinned ? 1 : 0,
      })
      .then(response => {
        if (response.data && response.data.status == "SUCCESS") {
          this.$store.commit("setPage", response.data.page);
          // this.$store.commit("setUser", response.data.user);
          this.$notify({
            group: 'info', type: 'success',
            text: `Successfully saved.`
          });
        } else {
          this.$notify({
            group: 'info', type: 'error',
            text: `Error on saving: ${ response.data.msg }`
          })
        }
      })
      .catch(() => { })
      .finally(() => {
        this.$store.commit("hideLoading");
      });
    },
  },
  
  updated() {
    console.log('UPDATED:::', this.site.id, this.siteId)
    if (this.site.id != this.siteId) {
      this.siteName = this.site.name
      this.siteId = this.site.id
      this.isEditMode = false;
      this.selectedPageIds = []
      this.$store.commit('setCurrentSiteById', { siteId: this.siteId })
      this.loadSite()
    }
  },
  beforeMount() {

    this.siteName = this.site ? this.site.name : ''
    if (!this.$store.state.sites.length) {
      this.$store.commit('showLoading')
      return this.$store.dispatch('loadSites')
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {
            this.$store.commit('setSites', response.data.sites)
          }
        })
        .finally(() => this.$store.commit('hideLoading'))
    } else {
      this.loadSite()
    }
  },
  mounted () {
    this.$store.commit('setHideSideBar', this.$store.state.isUserClosedSideBar)
    // sitesContainer
    // this.ro = new ResizeObserver(this.onResize)
    // this.ro.observe(this.$refs.observer);

    let tabKey = this.$route.query.tab || this.toolTabs[0].key
    this.toolChangeAction(tabKey)
  },
  beforeUnmount() {
    // this.ro.unobserve(this.$refs.observer)
  },
}
</script>

<style lang="scss" scoped>

.signal-element {
  width: 92%;
  display: flex;
  font-weight: 700;
  padding: 2px 5px;
  border-radius: 5px;
  background: var(--v-link9Color-base);
  color: var(--v-mainColor-base);
  font-size: 13px;
}

</style>

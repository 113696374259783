<template>
<div>
  <wizard-header
    v-if="selectedScenario"
    :options="availableOptions"
    :curScenario="selectedScenario"
    @option_changed="((s) => changeScenario(s))"
  />

  <router-view
    v-if="selectedScenario"
    :campaignId="campaignId"
    :selectedScenario="selectedScenario"
  ></router-view>
  
</div>
</template>

<script>
import { invert } from 'lodash'
import WizardHeader from '@/components/Campaigns/keyword-wizard/WizardHeader.vue';

const SITE_URL_EXIST = 'SITE_URL_EXIST'
const SITE_ONLY_EXIST = 'SITE_ONLY_EXIST'
const KEYWORD_ONLY_EXIST = 'KEYWORD_ONLY_EXIST'
const PAGE_OPPORTUNITY = 'PAGE_OPPORTUNITY'

const scenarioToRouteName = {
  'SITE_URL_EXIST' : 'site-url',
  'SITE_ONLY_EXIST' : 'site-only',
  'KEYWORD_ONLY_EXIST' : 'keyword-only',
  'PAGE_OPPORTUNITY' : 'page-opportunity'
}

export default {
  components: {
    WizardHeader,
  },
  computed: {
    config() {
      return this.$store.getters.insightKeywordsConfig;
    },
    campaignId () {
      return parseInt(this.$route.params.campaignId || 0)
    },
    campaign () {
      return this.$store.state.currentCampaign
    },
    currentScenarioRouteName () {
      let name = null
      const availableNames = Object.values(scenarioToRouteName)
      this.$route.matched.forEach((matchedRoute) => {
        if (availableNames.indexOf(matchedRoute.name) >= 0) {
          name = matchedRoute.name
        }
      })
      return name
    },
    selectedScenario () {
      const scenario = invert(scenarioToRouteName)[this.currentScenarioRouteName]
      if (this.availableOptions.length && this.availableOptions.indexOf(scenario) >= 0) {
        return scenario
      }
      return undefined
    },
    availableOptions () {
      if (this.campaign) {
        if (this.campaign.isDomainLevelCampaign) {
          return [PAGE_OPPORTUNITY, SITE_ONLY_EXIST, KEYWORD_ONLY_EXIST]
        } else {
          return [SITE_URL_EXIST, SITE_ONLY_EXIST, KEYWORD_ONLY_EXIST]
        }
      }
      return []
    },
  },
  watch: {
    campaignId: function (newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.getCampaignById()
      }
    },
    availableOptions: function(newVal, oldVal) {
      if (newVal && newVal.length > 0 && !this.selectedScenario) {
        this.changeScenario(newVal[0])
      }
    },
  },
  methods: {
    setConfig(key, value) {
      this.$store.commit("setInsightKeywordsConfig", { key, value });
    },
    changeScenario (scenario) {
      if (scenario !== this.selectedScenario && this.availableOptions.indexOf(scenario) >= 0) {
        const {siteId, campaignId} = this.$route.params
        this.$router.push(`/sites/${siteId}/campaign/${campaignId}/keyword-wizard/${scenarioToRouteName[scenario]}`)
      }
    },
    getCampaignById () {
      return this.$store.dispatch('getCampaignById', this.campaignId)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            let campaign = response.data.campaign
            this.$store.commit('setCurrentCampaign', campaign)
            // this.domain = getHostName(this.campaign.targetUrl)
            this.selectedLanguage = campaign.languageCode || 'en'
            if (campaign.locationCode) {
              this.selectedLocation = parseInt(campaign.locationCode || 0)
            }
            return Promise.resolve()
          }
          return Promise.reject('Invalid response')
        })
    },
  },
  mounted () {
    // load campaign (if not already loaded)
    // then switch to first available scenario if no valid scenario is selected yet

    (!this.campaign || this?.campaign?.id != this.campaignId)
      ?this.getCampaignById()
      :Promise.resolve()
    .then(() => {
      if (!this.selectedScenario) {
        this.changeScenario(this.availableOptions[0])
      }
    })
  }
}
</script>

<style scoped lang="scss">
@import "../keyword-research.scss";



</style>
export const toolTabs = [
  {
    key: 'on-page-optimization',
    label: 'On Page Optimization'
  },
  {
    key: 'ai-writer',
    label: 'AI Writer'
  },
  {
    key: 'keyword-research',
    label: 'Keyword Research',
    isSpecialUserOnly: true,
  },
]
<template>
  <div
    :class="['flex flex-column site-page-card silo-card mb-8 p-5', !isCardInEditMode?'cursor-pointer':'']"
    @click="!isCardInEditMode ? onSiloClick(silo.id) : undefined"
  >
    <div class="silo-card-head">
      <div
        class="silo-card-title"
      >
        <div class="project-name truncate-ellipse-one-line">
          <span
            v-if="!isCardInEditMode"
            style="cursor:text;"
            @click.stop="editableSiteName = silo.name; isCardInEditMode = true;"
          >
            {{ silo.name }}
          </span>
          <v-text-field
            v-else
            autofocus
            outlined
            v-model="editableSiteName"
            @blur="saveEdit()"
            dense
            hide-details
            style="max-width: max-content; background-color: white"
          >
          </v-text-field>
        </div>
        <div class="count-label">
          {{ silo.silo_keywords?.length || 0 }}
        </div>
      </div>

      <div
        class="is-size-6 title-font mainColor--text"
      >
        <v-btn icon :id="`dot-menu-for-${silo.id}`">
          <span
            class="material-icons-outlined mainColor--text"
            style="font-size: 30px"
            >more_horiz</span
          >
        </v-btn>
        <v-menu :activator="`#dot-menu-for-${silo.id}`" bottom offset-y>
          <v-list>
            <v-list-item @click="showDeleteModal(silo.id)">
              <v-list-item-title class="mainColor--text">
                Delete
              </v-list-item-title>
            </v-list-item>
            <template v-if="isWhiteGloveUser && isMissionControlSession">
              <v-list-item
                v-if="silo.content_report_order.fulfillement_status !== 'pending'"
                @click="updateOrder({set_pending: true})"
              >
                <v-list-item-title class="mainColor--text">
                  Mark as {{ getFulfillementStatusLabel('pending')}}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="silo.content_report_order.fulfillement_status !== 'in_progress'"
                @click="updateOrder({set_in_progress: true})"
              >
                <v-list-item-title class="mainColor--text">
                  Mark as {{ getFulfillementStatusLabel('in_progress')}}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="silo.content_report_order.fulfillement_status !== 'completed'"
                @click="updateOrder({set_completed: true})"
              >
                <v-list-item-title class="mainColor--text">
                  Mark as {{ getFulfillementStatusLabel('completed')}}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="mt-1 mainColor--text font-weight-regular">
      Updated {{ showElaspedTime(silo.createdAt) }}
    </div>
    <footer class="footer-container">
      <div
        v-if="['in_cart', 'in_cart_checkout'].includes(silo?.content_report_order?.order_status)"
        class="footer-item"
      >
        <span class="material-icons-outlined">shopping_cart</span>
        <span class="price-on-checkout">Order in cart</span>
      </div>
      <div
        v-if="silo?.content_report_order?.order_status === 'paid' && silo?.content_report_order?.price_on_checkout"
        class="footer-item footer-item-price-on-checkout"
      >
        <span class="material-icons-outlined">receipt_long</span>
        <v-menu
          open-on-hover
          :nudge-width="250"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              class="material-icons"
              style="color: #B3C3BA;"
              v-bind="attrs"
              v-on="on"
            >info</span>
          </template>

          <v-card class="p-4 mainColor--text">
            <div class="font-weight-bold mb-4" style="font-size: 1rem;">You have ordered {{ 1 + silo.content_report_order.selected_supporting_keyword_ids.length }} reports for ${{ silo.content_report_order.price_on_checkout }}</div>
            <div v-if="silo.content_report_order?.items_on_checkout?.length">
              <div class="font-weight-bold mb-1">1 report for the Top-level keyword</div>
              <div>{{ silo.content_report_order?.items_on_checkout[0].report_label }}</div>
              <div class="mb-3">(${{ silo.content_report_order?.items_on_checkout[0].unit_amount }} USD)</div>
              <div class="font-weight-bold mb-1">{{ silo.content_report_order?.items_on_checkout[1].quantity }} reports for Supporting keywords</div>
              <div>{{ silo.content_report_order?.items_on_checkout[1].report_label }}</div>
              <div>({{ silo.content_report_order?.items_on_checkout[1].quantity }}x ${{ silo.content_report_order?.items_on_checkout[1].unit_amount }} USD)</div>
            </div>
          </v-card>
        </v-menu>

        <span class="price-on-checkout">${{ silo.content_report_order.price_on_checkout }}</span>
      </div>
      <div
        v-if="['paid', 'to_be_paid_by_invoice'].includes(silo?.content_report_order?.order_status) && silo?.content_report_order?.due_date"
        class="footer-item tooltip"
        data-tooltip="Due date"
      >
        <span class="material-icons-outlined">alarm_on</span>
        <span>{{ showDate(silo.content_report_order.due_date) }}</span>
      </div>
    </footer>
  </div>
</template>

<script>
import moment from 'moment';
import { getPureServicePkgName } from '@/utils';
import { whiteGlovePlans } from "@/components/plans_constants"
import { getFulfillementStatusLabel } from './utils'

export default {
  props: ['silo', 'onSiloClick', 'showDeleteModal', 'onSaveEdit', 'refreshData'],
  data() {
    return {
      isCardInEditMode: false,
      editableSiteName: '',
    };
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isWhiteGloveUser () {
      if (!this.user) return false;

      const accountType = this.$store.state.selectedSubAccountRelation
        ?this.$store.state.selectedSubAccountRelation.ownerAccountType
        :this.user.accountType

      if (whiteGlovePlans.includes(getPureServicePkgName(accountType))) {
        return true;
      }
      return false;
    },
    isMissionControlSession () {
      return this.$store.state.selectedSubAccountRelation?.isMissionControlSubAccount
    },
  },
  methods: {
    getFulfillementStatusLabel: getFulfillementStatusLabel,
    showElaspedTime(time) {
      return moment(time).fromNow();
    },
    showDate(time) {
      return moment(time).format("MMM DD");
    },
    saveEdit() {
      if (this.editableSiteName === this.silo.name) {
        // wait a little in case save is too fast
        // (this fix is needed to prevent navigating to silo when save is triggered by clicking on the silo card)
        return new Promise(r => setTimeout(r, 300))  
          .then(() => {
            this.isCardInEditMode = false;
          }) 
      }
  
      this.$store.commit('showLoading');
      this.onSaveEdit(this.silo, this.editableSiteName)
        .then(() => {
          this.$notify({
            group: 'info',
            type: 'success',
            text: 'Successfully updated silo.',
          });
  
          // wait a little in case save is too fast
          // (this fix is needed to prevent navigating to silo when save is triggered by clicking on the silo card)
          return new Promise(r => setTimeout(r, 300))  
            .then(() => {this.isCardInEditMode = false;// this.editableSiteId = null;
          })
        })
        .finally(() => {
          this.$store.commit('hideLoading');
        });
    },
    updateOrder(payload) {
      this.$store.commit('showLoading');
      this.$store.dispatch('updateSiloContentReportOrder', {
        siloId: this.silo.id,
        body: payload
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.refreshData()
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      })
    },
  },
};

</script>

<style lang="scss" scoped>
@import './silo.scss';
</style>

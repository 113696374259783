<template>
<div>
  <div class="card-big-title">
    Top-level Keyword Research
    <span class="material-icons">do_not_disturb_on</span>
  </div>
  <div class="mt-10">
    <div class="card-small-title">
      Select scenario
    </div>
    <div class="scenarios mt-5">
      <div v-if="options.includes('PAGE_OPPORTUNITY')"
        :class="{ 'is-active': curScenario == 'PAGE_OPPORTUNITY' }" @click="() => { $emit('option_changed', 'PAGE_OPPORTUNITY') }">
        <img src="/static/scenario3.svg" />
        <div class="name">Scenario {{ options.indexOf('PAGE_OPPORTUNITY') + 1 }}</div>
        <div class="explain">
          I have an established site<br />and I am looking for pages to start with
        </div>
      </div>
      <div v-if="options.includes('SITE_URL_EXIST')"
        :class="{ 'is-active': curScenario == 'SITE_URL_EXIST' }" @click="() => { $emit('option_changed', 'SITE_URL_EXIST')  }">
        <img src="/static/scenario1.svg" />
        <div class="name">Scenario {{ options.indexOf('SITE_URL_EXIST') + 1 }}</div>
        <div class="explain">
          I have both established site<br />and target page URL
        </div>
      </div>
      <div v-if="options.includes('SITE_ONLY_EXIST')"
        :class="{ 'is-active': curScenario == 'SITE_ONLY_EXIST' }" @click="() => { $emit('option_changed', 'SITE_ONLY_EXIST') }">
        <img src="/static/scenario2.svg" />
        <div class="name">Scenario {{ options.indexOf('SITE_ONLY_EXIST') + 1 }}</div>
        <div class="explain">
          I have established site but<br />don't have target keyword
        </div>
      </div>
      <div v-if="options.includes('KEYWORD_ONLY_EXIST')"
        :class="{ 'is-active': curScenario == 'KEYWORD_ONLY_EXIST' }" @click="() => { $emit('option_changed', 'KEYWORD_ONLY_EXIST') }">
        <img src="/static/scenario3.svg" />
        <div class="name">Scenario {{ options.indexOf('KEYWORD_ONLY_EXIST') + 1 }}</div>
        <div class="explain">
          I have target keyword but<br />don't have established site
        </div>
      </div>
    </div>
  </div>
  <div class="mt-10" v-if="curScenario != 'KEYWORD_ONLY_EXIST'">
    <div class="card-small-title">
      Connect Google account
    </div>
    <div class="mt-5">
      <google-login></google-login>      
    </div>
  </div>
</div>
</template>

<script>
import GoogleLogin from '@/components/Campaigns/GoogleLogin.vue';



export default {
  props: {
    options: {
      default: [],
    },
    curScenario: {
      default: ''
    }
  },
  components: {
    GoogleLogin,
  },
  data () {
    return {}
  },
  methods: {

  },
  mounted () {

  }
}</script>


<style lang="scss" scoped>
@import "../keyword-research.scss";
.scenarios {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  & > div {
    cursor: pointer;
    border: 1px solid var(--v-grayColor-base);
    border-radius: 3.7px;
    min-width: 290px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--v-cardBg-base);
    &.is-active {
      border: 1px solid var(--v-profileAnchorColor-base);
    }



    img {
      width: auto;
      height: 60px;
    }
    .name {
      color: var(--v-mainColor-base);
      font-size: 0.9rem;
      font-weight: 800;
    }
    .explain {
      text-align: center;
      color: var(--v-mainColor-base);
      font-size: 0.75rem;
    }
  }
}
</style>
<template>
<section class="section">
  <div class="container">
    <div class="columns">
      <div class="column">
        <div class="columns is-desktop">
          <div class="column is-2">
            <div class="field">
              <div class="control">
                <div class="select is-fullwidth">
                  <select v-model="perPage" @change="onPerPageChange">
                    <option value="10">10 per page</option>
                    <option value="15">15 per page</option>
                    <option value="25">25 per page</option>
                    <option value="50">50 per page</option>
                    <option value="100">100 per page</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="field">
              <div class="control">
                <input type="text" class="input" placeholder="Search" v-model="searchText" @input="changeSearchText">
              </div>
            </div>
          </div>
          <div class="column is-2">
            <button class="button is-info is-fullwidth" @click="onFilter">Search</button>
          </div>
          <div class="column is-2">
            <button @click="exportPage" class="button is-link is-fullwidth">Export Page</button>
          </div>
        </div>
        
        <b-table :key="detailViewKey"
          :data="records"
          :loading="isLoading"
          paginated
          backend-pagination
          :total="totalRows"
          :per-page="perPage"
          :current-page="currentPage"
          @page-change="onPageChange"
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
          >
          <template slot-scope="props">
            <b-table-column field="createdAt" label="Date" sortable numeric>{{ props.row.createdAt }}</b-table-column>
            <b-table-column field="reportId" label="Report Id" sortable numeric>{{ props.row.reportId }}</b-table-column>
            <b-table-column field="userEmail" label="Email" sortable>{{ props.row.userEmail }}</b-table-column>
            <b-table-column field="siteDomain" label="Project" sortable>{{ props.row.siteDomain }}</b-table-column>
            <b-table-column field="tool" label="Report Type" sortable>{{ props.row.tool }}</b-table-column>
            <b-table-column field="targetLanguage" label="Language" sortable>{{ availableLanguages[props.row.targetLanguage]?.language_name }}</b-table-column>
            <b-table-column field="targetLocation" label="Location" sortable>{{ availableLocations[props.row.targetLocation]?.location_name }}</b-table-column>
          </template>
        </b-table>
      </div>
    </div>

  </div>
  <page-setting-modal ref="pageSettingModal" :disableDeletePage="true" :isAdminSetupTheGuard="true" />
</section>
</template>

<script>
import { mixin } from '@/components/mixin'
import PageSettingModal from "@/components/PageSettingModal.vue";
import dataForSeoLocations from '@/consts/dataforseo_locations.json';
import languages from '@/consts/dataforseo_languages.json';

import { mixin as exportmixin } from '@/components/export_mixin'
import { toLocalDateString } from '@/utils'
import { keyBy } from 'lodash'
import moment from 'moment'

export default {
  components: { PageSettingModal },
  mixins: [mixin, exportmixin],
  data () {
    return {
      records: [],
      totalRows: 0,
      isLoading: false,
      sortField: 'createdAt',
      sortOrder: 'desc',
      defaultSortOrder: 'asc',
      currentPage: 1,
      perPage: 15,
      isSimple: false,
      isRounded: false,
      searchText: '',
      proxies: [],
      downloadAllUrl: `${API_URL}/download/?file=/home/hvseo/web/highvoltageseo.com/pageoptimizer/webapp/web/Report/allhistory.txt`,

      detailViewKey: 0,
    }
  },
  computed: {
    availableLocations () {
      return keyBy(dataForSeoLocations.locations, 'location_code');
    },
    availableLanguages () {
      return keyBy(languages, 'language_code');
    },
    currentUser () {
      if (this.$store.state.user) {
        return this.$store.state.user.id
      }
      return {}
    }
  },
  methods: {
    test() {
      console.log('history tab function is called as intended')
      this.$nextTick(() => this.loadData())
    },
    openPageSettingModal(row) {
      this.$refs.pageSettingModal.show(row.page, this.loadData, row.email, row.pageRun);
    },


    showDetailsForPageRun (pageRun) {
      // console.log('detail', pageRun)
      this.$store.commit('showLoading');
      this.$store.commit('setCurrentPageRun', pageRun)
      this.$store.commit('hideLoading');
    },
    getBaseUrl (fullUrl) {
      let pathArray = fullUrl.split( '/')
      let protocol = pathArray[0]
      let host = pathArray[2]
      let url = protocol + '//' + host
      return url
    },
    lsaPhrases (pageRun) {
      if(pageRun['lsaPhrases'] == undefined || !Array.isArray(pageRun['lsaPhrases']))  {
        return []
      } else  {
        return pageRun.lsaPhrases
          .map(item => { // round weight as 2 digits
            let newItem = Object.assign({}, item)
            newItem.weight = Math.round(newItem.weight * 100) / 100
            return newItem;
          })
      }
    },
    exportPage () {
      const rows = this.records.map((record) =>
        [
          moment(record.createdAt).format(),
          record.reportId,
          record.userEmail,
          record.siteDomain,
          record.tool,
          this.availableLanguages[record.targetLanguage]?.language_name || record.targetLanguage || '',
          this.availableLocations[record.targetLocation]?.location_name || record.targetLocation || '',
        ].join(',')
      );
      const data = ['Date,Report Id,User Email,Project,Report Type,Language,Location']
        .concat(rows)
        .join('\n');
      const now = new Date();
      const fileName = "History_" + now.getUTCFullYear() + "_" + (now.getUTCMonth()+1) + "_"+ now.getUTCDate() + ".csv"
      this.exportToCSV(data, fileName)
    },
    avgCompetitorWordCount (pageRun) {
      if (pageRun.hasOwnProperty('keywordCounts')) {
        const wordCount = pageRun.keywordCounts.rows.find(wc => wc.signal === 'Word Count')
        return Math.round(wordCount.signalData.competitorsAvg)
      }
      return 0
    },
    avgFeaturedCompetitorWordCount (pageRun) {
      if (pageRun.hasOwnProperty('keywordCounts')) {
        const wordCount = pageRun.keywordCounts.rows.find(wc => wc.signal === 'Word Count')
        return Math.round(wordCount.signalData.featuredCompetitorsKeywordAvg)
      }
      return 0
    },
    mainPageWordCount (pageRun) {
      const wordCount = pageRun.keywordCounts.rows.find(wc => wc.signal === 'Word Count')
      return wordCount.signalData.targetKeywordCnt
    },
    toLocalDateString (dateStr) {
      return toLocalDateString(dateStr)
    },
    onPerPageChange () {
      this.$nextTick(() => this.loadData())
    },
    onPageChange (page) {
      this.currentPage = page
      this.loadData()
    },
    onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
      this.loadData()
    },
    onFilter () {
      // console.log('filtering')
      this.$nextTick(() => {
        this.loadData()
      })
    },
    loadData () {
      this.isLoading = true
      this.$store.commit('showLoading')
      const params = [
        `sortField=${this.sortField}`,
        `sortOrder=${this.sortOrder}`,
        `page=${this.currentPage}`,
        `perPage=${this.perPage}`,
        `filterText=${this.searchText}`
      ].join('&')
      this.$store.dispatch('loadKeywordResearchForAdmin', { params })
        .then(({ data }) => {
          const { total, records } = data
          this.totalRows = total
          this.records = records
          this.isLoading = false
          this.$store.commit('hideLoading')
          this.detailViewKey = parseInt(Math.random() * 1000, 10)
        })
    },
    changeSearchText () {
      console.log(this.searchText);
    }
  },
  beforeMount () {
    this.loadData()
  }
}
</script>

<style lang="scss">
.table-mobile-sort {
  display: none;
}
</style>

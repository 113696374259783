<template>
  <div>
    <section>
      <div>
        <div class="ml20 mr20 dashboard-name">{{ page.name }}</div>
        <div class="tools-tab mt-7">
          <ul class="pl20 pr20">
            <li
              v-for="tool in toolTabs"
              :key="tool.key"
              :class="{ 'is-active': tool.key == currentToolTab }"
              @click="toolChangeAction(tool.key)"
            >
              {{ tool.label }}
            </li>
          </ul>
        </div>
        <div class="mt-5 ml20 mr20 dashboard-cards-name">
          <template v-if="currentToolTab == 'on-page-optimization'">
            PageRuns in this page
          </template>
          <template v-else-if="currentToolTab == 'ai-writer'">
            PageRuns with AI Writer report
          </template>
          <template v-else-if="currentToolTab == 'keyword-research'">
            Keyword research
          </template>
        </div>

        <p v-if="errMsg" class="subtitle has-text-centered" style="color: red">
          {{ errMsg }}
        </p>
        <div class="pl20 pr20">
          <div
            v-if="
              currentToolTab == 'ai-writer' &&
              pageRunsByType[`normalRun`].length == 0
            "
            class="mt-5 no-run-placeholder"
          >
            <div class="img-wrapper">
              <img src="/static/no-ai-writer.png" />
            </div>
            <div>
              <div class="title-part">Create your first AI Writer report</div>
              <div class="desc-part">
                POP AI Writer will take your content easily and quickly to a
                <b>100 POP Optimization Score</b> without you having to write
                the content yourself. Add rich article elements, select
                competitor pages to train the AI and use article personalization
                features for quick SEO gains.
              </div>
              <v-btn
                rounded
                color="profileAnchorColor"
                class="mt-5 text-none whiteColor--text button-with-icon"
                @click="createAiWriterForRecentReport"
              >
                <img
                  src="/static/ai-writer-stars.png"
                  width="26px"
                  style="margin-right: 5px"
                />
                Write with AI
              </v-btn>
            </div>
          </div>
          <div
            v-if="currentToolTab == 'keyword-research'"
            class="mt-5 no-run-placeholder"
          >
            <div class="img-wrapper">
              <img src="/static/no-keyword-research.png" />
            </div>
            <div>
              <div class="title-part">
                Create your first Keyword Research report
              </div>
              <div class="desc-part">
                POP Keyword Research provides key metrics for keywords in your
                project  and helps you decide what terms to target. Check search
                volume and find low-competition keywords with ease.
              </div>
              <v-btn
                rounded
                color="profileAnchorColor"
                class="mt-5 text-none whiteColor--text button-with-icon"
                @click="
                  () => {
                    $refs.createPageCampaignModalRef.showModalAction();
                  }
                "
              >
                <img
                  src="/static/ai-writer-stars.png"
                  width="26px"
                  style="margin-right: 5px"
                />
                Start Keyword Research
              </v-btn>
            </div>
          </div>
        </div>

        <div class="mt15 mb20">
          <div class="pl20 pr20 pageruns-container" ref="observer">
            <div v-for="(section, idx) in sections" :key="section.type">
              <div
                v-if="idx > 0"
                class="mt-10 mb20 mainColor--text font-weight-bold is-size-5"
              >
                {{ section.label }}
              </div>
              <div v-if="idx == 0">
                <!-- pageruns selected box only once -->
                <div
                  v-if="selectedPageRunIds.length > 0"
                  class="flex flex-column white-common-box mb-3"
                >
                  <div class="d-flex align-center pa-3">
                    <div class="gray2Color--text mr20">
                      {{ selectedPageRunIds.length }} page run report{{
                        selectedPageRunIds.length > 1 ? "s" : ""
                      }}
                      selected
                    </div>
                    <v-btn
                      small
                      outlined
                      color="red3Color"
                      class="button-with-icon whiteColor--text text-none ml10"
                      @click.stop="showDeletePageRunssConfirmModal = true"
                    >
                      <i class="material-icons">delete_outline</i>
                      <span>Delete pagerun reports</span>
                    </v-btn>
                    <v-btn
                      small
                      outlined
                      color="mainColor"
                      class="button-with-icon text-none ml10"
                      @click.stop="cancelAllSelection"
                    >
                      <span>Unselect all</span>
                    </v-btn>
                  </div>
                </div>
              </div>
              <div
                class="page-runs-container"
                ref="pageRunsContainers"
                v-if="page && page.keyword && pageRuns && pageRuns.length"
              >
                <div
                  v-for="pageRun in pageRunsByType[`${section.type}`]"
                  :key="pageRun.id"
                  :id="`_page_run_${pageRun.id}_`"
                  class="is-fullheight flex flex-column pagerun-card"
                  :class="{ 'selected-pagerun': isSelectedPageRun(pageRun.id) }"
                >
                  <div
                    class="top-part border-bottom is-size-6 title-font mainColor--text flex align-center"
                  >
                    <span
                      v-if="pageRun.status != 'PENDING'"
                      class="d-flex align-center flex-gap-10"
                    >
                      <template v-if="!$store.getters.isDemoUser">
                        <i
                          v-if="isSelectedPageRun(pageRun.id)"
                          class="material-icons checked-box"
                          @click="unSelectPageRun(pageRun.id)"
                          >check_box</i
                        >
                        <i
                          v-else
                          class="material-icons checked-box"
                          @click="selectPageRun(pageRun.id)"
                          >check_box_outline_blank</i
                        >
                      </template>
                      <v-tooltip top color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                            class="material-icons pin-icon"
                            :class="{ pinned: pageRun.isPinned }"
                            @click="
                              () =>
                                pinPageRunSettingAction(
                                  pageRun.id,
                                  !pageRun.isPinned
                                )
                            "
                            >push_pin</span
                          >
                        </template>
                        <div class="white-bg-tooltip small-tooltip">
                          {{
                            pageRun.isPinned
                              ? "Click to unpin"
                              : "Pin this Report to the top of your dashboard to find it easily."
                          }}
                        </div>
                      </v-tooltip>
                      <v-tooltip
                        top
                        color="transparent"
                        v-if="!pageRun.pageNotBuilt"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                            class="material-icons guard-icon clickable"
                            :class="{
                              guarded:
                                page.isGoogleGuardEnabled &&
                                page.guardRunId == pageRun.id,
                            }"
                            @click="() => enterPageSetting(pageRun)"
                          >
                            verified_user
                          </span>
                        </template>
                        <div class="white-bg-tooltip small-tooltip">
                          {{
                            page.isGoogleGuardEnabled &&
                            page.guardRunId == pageRun.id
                              ? "This Report is monitored by POP Watchdog."
                              : "This Report is not monitored by POP Watchdog."
                          }}
                        </div>
                      </v-tooltip>

                      <span
                        class="pagerun-type"
                        v-if="
                          pageRun.pageRunType === 0 || pageRun.pageRunType === 1
                        "
                        >{{
                          pageRun.pageRunType == 0
                            ? "Custom run"
                            : pageRun.pageRunType == 1
                            ? "Pro run"
                            : ""
                        }}</span
                      >
                    </span>
                    <div class="flex-grow-1 ml-2 mr-1">
                      <div
                        v-if="
                          isAiRun(pageRun) &&
                          !(
                            pageRun.pageRunType === 0 ||
                            pageRun.pageRunType === 1
                          )
                        "
                        class="signal-element"
                      >
                        <img src="/static/starIcon.svg" class="mr-2" />
                        <span>Written with AI</span>
                      </div>
                    </div>
                    <div class="flex-grow-1"></div>
                    <div>
                      <v-btn
                        v-show="!isPageRunCreating()"
                        icon
                        :id="`dot-menu-for-${pageRun.id}`"
                      >
                        <span class="material-icons-outlined mainColor--text"
                          >more_horiz</span
                        >
                      </v-btn>
                      <v-menu
                        :activator="`#dot-menu-for-${pageRun.id}`"
                        bottom
                        offset-y
                      >
                        <v-list>
                          <template v-if="isAiRun(pageRun)">
                            <v-list-item
                              link
                              @click="
                                window.open(downloadUrl(pageRun), '_blank')
                              "
                            >
                              <v-list-item-title class="mainColor--text">
                                Download report
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              v-if="!$store.getters.isDemoUser"
                              link
                              @click="
                                displayDeletePageRunConfirmModal(pageRun.id)
                              "
                            >
                              <v-list-item-title class="mainColor--text">
                                Delete
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                          <template v-else>
                            <v-list-item
                              v-if="
                                !$store.getters.isDemoUser &&
                                !pageRun.pageNotBuilt
                              "
                              link
                              @click="() => enterPageSetting(pageRun)"
                            >
                              <v-list-item-title class="mainColor--text">
                                Watchdog settings
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              link
                              @click="
                                window.open(downloadUrl(pageRun), '_blank')
                              "
                            >
                              <v-list-item-title class="mainColor--text">
                                Download report
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              v-if="!$store.getters.isDemoUser"
                              link
                              @click="showNoteModalAction(pageRun)"
                            >
                              <v-list-item-title class="mainColor--text">
                                Add note
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              link
                              :to="`/sites/${site.id}/pages/${pageRun.pageId}/pageruns/${pageRun.id}?activeTab=report_history`"
                            >
                              <v-list-item-title class="mainColor--text">
                                View report history
                              </v-list-item-title>
                            </v-list-item>
                            <template v-if="!$store.getters.isDemoUser">
                              <v-list-item
                                link
                                @click="
                                  () => {
                                    if (pageRun.pageNotBuilt) {
                                      runBuiltPage(pageRun.id);
                                    } else {
                                      makeRerunAnalysis(pageRun);
                                    }
                                  }
                                "
                              >
                                <v-list-item-title class="mainColor--text">
                                  {{
                                    pageRun.pageNotBuilt
                                      ? "Run built page"
                                      : "Re-run"
                                  }}
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item link>
                                <v-list-item-title
                                  class="mainColor--text"
                                  v-clipboard:copy="pageRun.secretKey"
                                  v-clipboard:success="
                                    () =>
                                      $notify({
                                        group: 'info',
                                        type: 'success',
                                        text: 'Copied to clipboard',
                                      })
                                  "
                                  v-clipboard:error="
                                    () =>
                                      $notify({
                                        group: 'info',
                                        type: 'error',
                                        text: `${
                                          !isUnlimitedUser
                                            ? 'Secret key is only available on Unlimited and Teams Plan. Please upgrade to be able to use this feature.'
                                            : 'can not copy to clipboard'
                                        }`,
                                      })
                                  "
                                >
                                  <div class="d-flex align-center">
                                    Copy secret key
                                  </div>
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                link
                                @click="
                                  window.open(
                                    uniqueUrlForEditor(pageRun),
                                    '_blank'
                                  )
                                "
                              >
                                <v-list-item-title class="mainColor--text">
                                  Open content editor
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                link
                                @click="
                                  displayDeletePageRunConfirmModal(pageRun.id)
                                "
                              >
                                <v-list-item-title class="mainColor--text">
                                  Delete
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                          </template>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>

                  <div class="main-part">
                    <div v-if="!isAiTab" class="flex-grow-1">
                      <div class="mt-3 mainColor--text font-weight-regular">
                        Report ID: #{{ pageRun.id }}
                      </div>
                      <div class="mt-3 mainColor--text font-weight-regular">
                        Time: {{ getDateAsLocalString(pageRun.createdAt) }}
                      </div>
                      <div class="mt-8 keyword">
                        {{ pageRun.keyword ? pageRun.keyword : page.keyword }}
                      </div>
                      <div class="mt-3 flex align-center flex-gap-5">
                        <a
                          class="flex-grow-1 title-font link15Color--text is-underline truncate-ellipse-one-line"
                          @click="
                            () => {
                              if (pageRun.pageNotBuilt) {
                                return;
                              }
                              window.open(
                                pageRun.url ? pageRun.url : page.url,
                                '_blank'
                              );
                            }
                          "
                        >
                          {{
                            getSubFolderPartFromUrl(
                              pageRun.url ? pageRun.url : page.url,
                              pageRun.pageNotBuilt
                            )
                          }}
                        </a>
                        <div class="icon-area">
                          <button
                            type="button"
                            class="copy-to-clipboard-button"
                            v-clipboard:copy="
                              getSubFolderPartFromUrl(
                                pageRun.url ? pageRun.url : page.url,
                                pageRun.pageNotBuilt
                              )
                            "
                            v-clipboard:success="
                              () =>
                                $notify({
                                  group: 'info',
                                  type: 'success',
                                  text: 'Copied to clipboard',
                                })
                            "
                            v-clipboard:error="
                              () =>
                                $notify({
                                  group: 'info',
                                  type: 'error',
                                  text: 'can not copy to clipboard',
                                })
                            "
                          >
                            <img src="/static/copyicon.png" />
                          </button>
                        </div>
                      </div>
                      <!-- <div class="is-size-4 has-text-black title-font">{{ pageRun.keyword ? pageRun.keyword : page.keyword }}</div> -->
                      <div v-if="pageRun.note" class="my-5 flex align-center">
                        <i class="material-icons note-icon">assignment</i>
                        <div
                          class="flex-grow-1 is-size-7 pagerun-note truncate-ellipse-one-line"
                        >
                          &nbsp;{{ pageRun.note }}
                        </div>
                      </div>
                    </div>
                    <div v-else class="flex-grow-1">
                      <div class="mt-3 mainColor--text font-weight-regular">
                        Report ID: #{{ pageRun.id }}
                      </div>
                      <div class="mt-5 keyword">
                        {{ pageRun.keyword ? pageRun.keyword : page.keyword }}
                      </div>
                      <div class="mt-3 mainColor--text font-weight-regular">
                        Created on:
                        {{ getDateAsLocalString(pageRun.createdAt) }}
                      </div>
                    </div>

                    <template
                      v-if="!$store.getters.isDemoUser || progress >= 100"
                    >
                      <div class="score-box-section mt-8">
                        <div
                          v-if="getPageRun(pageRun).status == 'PENDING'"
                          class="has-text-centered"
                        >
                          <span
                            class="tooltip is-tooltip-multiline mainColor--text"
                            data-tooltip="Please wait while PageOptimizer Pro analayzes your page data"
                          >
                            Processing
                          </span>
                          <br />
                          <breeding-rhombus-spinner
                            :animation-duration="2000"
                            :size="20"
                            color="#000000"
                            class="calc-spinner"
                          />
                          <br />
                          <v-progress-linear
                            rounded
                            height="15"
                            class="mb-5"
                            color="green1Color"
                            :value="
                              getPageRun(pageRun)['progress']
                                ? getPageRun(pageRun).progress
                                : 0
                            "
                          >
                          </v-progress-linear>
                          <p class="gray1Color--text">
                            Processing may take up to 3 minutes.
                          </p>
                          <p style="word-wrap: break-word">
                            <span
                              v-if="getPageRun(pageRun).progress <= 50"
                              class="gray1Color--text"
                            >
                              Processing:
                              {{
                                getPageRun(pageRun)["competitors"] &&
                                getPageRun(pageRun).competitors.length > 1
                                  ? getPageRun(pageRun).competitors[
                                      getPageRun(pageRun).competitors.length - 1
                                    ].url
                                  : "target url"
                              }}
                            </span>
                            <span v-else class="gray1Color--text">
                              {{
                                getJoke(
                                  getPageRun(pageRun).progress,
                                  getPageRun(pageRun).competitors.length
                                )
                              }}
                              ...
                            </span>
                          </p>
                        </div>
                        <div
                          v-else-if="getPageRun(pageRun).status == 'FAILURE'"
                          class="mainColor--text"
                        >
                          <div>Click to update and retry submission</div>
                          <div class="mt-2">
                            <span
                              class="icon tooltip"
                              data-tooltip="Errors occurred. Click check errors to resolve issues and resubmit."
                            >
                              <i class="fa fa-ban" style="font-size: 24px"></i>
                            </span>
                          </div>
                          <div class="mt-2">Failed to process results.</div>
                        </div>
                        <div
                          v-else-if="getPageRun(pageRun).status == 'SUCCESS'"
                        >
                          <template v-if="getPageRun(pageRun).isGuardRun">
                            <div
                              class="guard-status-box"
                              :class="
                                getClassesForGuardRun(getPageRun(pageRun))
                              "
                            >
                              <div class="d-flex">
                                <template
                                  v-if="
                                    hasDroppedOutOfTop10Url(getPageRun(pageRun))
                                  "
                                >
                                  <i class="material-icons">info</i>
                                  <div class="texts">
                                    <div class="title-label">
                                      Target Page has Fallen OFF of Page One
                                    </div>
                                    <div class="explain"></div>
                                  </div>
                                </template>
                                <template
                                  v-else-if="
                                    getPageRun(pageRun).guardDiffData
                                      .new_tasks_pending
                                  "
                                >
                                  <i class="material-icons">info</i>
                                  <div class="texts">
                                    <div class="title-label">
                                      New tasks pending
                                    </div>
                                    <div class="explain">
                                      Refactor your page to avoid losing your
                                      traffic and rankings
                                    </div>
                                  </div>
                                </template>
                                <template v-else>
                                  <i class="material-icons">check_circle</i>
                                  <div class="texts">
                                    <div class="title-label">All Good</div>
                                    <div class="explain">
                                      There is nothing to update on your page at
                                      this time
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <div
                              v-if="
                                getPageRun(pageRun).pageNotBuilt && !isAiTab
                              "
                            >
                              <div class="score-chart-box">
                                <radial-progress-bar
                                  :diameter="70"
                                  :completed-steps="0"
                                  :total-steps="100"
                                  :strokeWidth="7"
                                  startColor="#40c8f9"
                                  stopColor="#40c8f9"
                                  :innerStrokeColor="'#eeeeee'"
                                  strokeLinecap="square"
                                >
                                  <div
                                    class="has-text-weight-bold"
                                    :style="{ color: '#40c8f9' }"
                                  >
                                    N/A
                                  </div>
                                </radial-progress-bar>
                                <div class="score-label">
                                  <span>Optimization score</span>
                                  <div v-if="!isAiTab" class="score-explain">
                                    No Optimization Score just yet. After
                                    creating your content, re-run POP with that
                                    content to see your score.
                                  </div>
                                  <div v-else class="score-explain">
                                    Optimization Score is currently unavailable.
                                    To view your score, please finalize the
                                    creation of your AI writer report.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="
                                !getPageRun(pageRun).pageNotBuilt || isAiTab
                              "
                            >
                              <div class="score-chart-box">
                                <radial-progress-bar
                                  v-if="$store.getters.isDemoUser"
                                  :diameter="70"
                                  :completed-steps="
                                    isAiRun(pageRun)
                                      ? 16
                                      : getStrategyScoreForPageRun(
                                          getPageRun(pageRun)
                                        )
                                  "
                                  :total-steps="100"
                                  :strokeWidth="8"
                                  :startColor="`${pageScoreTintColor(
                                    isAiTab
                                      ? 16
                                      : getStrategyScoreForPageRun(
                                          getPageRun(pageRun)
                                        )
                                  )}FF`"
                                  :stopColor="`${pageScoreTintColor(
                                    isAiTab
                                      ? 16
                                      : getStrategyScoreForPageRun(
                                          getPageRun(pageRun)
                                        )
                                  )}55`"
                                  :innerStrokeColor="`${pageScoreTintColor(
                                    isAiTab
                                      ? 16
                                      : getStrategyScoreForPageRun(
                                          getPageRun(pageRun)
                                        )
                                  )}22`"
                                  strokeLinecap="square"
                                >
                                  <div
                                    class="has-text-weight-bold"
                                    :style="{ color: 'blue' }"
                                    v-if="
                                      pageStrategySetting.strategy == 'custom'
                                    "
                                  >
                                    N/A
                                  </div>
                                  <div
                                    class="has-text-weight-bold"
                                    :style="{
                                      color: pageScoreTintColor(
                                        isAiTab
                                          ? 16
                                          : getStrategyScoreForPageRun(
                                              getPageRun(pageRun)
                                            )
                                      ),
                                    }"
                                    v-else
                                  >
                                    {{
                                      isAiRun(pageRun)
                                        ? 16
                                        : getStrategyScoreForPageRun(
                                            getPageRun(pageRun)
                                          ) | round(1)
                                    }}
                                  </div>
                                </radial-progress-bar>
                                <radial-progress-bar
                                  v-else
                                  :diameter="70"
                                  :completed-steps="
                                    isAiRun(pageRun)
                                      ? pageRun.aiRunScore
                                      : getStrategyScoreForPageRun(
                                          getPageRun(pageRun)
                                        )
                                  "
                                  :total-steps="100"
                                  :strokeWidth="8"
                                  :startColor="`${pageScoreTintColor(
                                    isAiTab
                                      ? pageRun.aiRunScore
                                      : getStrategyScoreForPageRun(
                                          getPageRun(pageRun)
                                        )
                                  )}FF`"
                                  :stopColor="`${pageScoreTintColor(
                                    isAiTab
                                      ? pageRun.aiRunScore
                                      : getStrategyScoreForPageRun(
                                          getPageRun(pageRun)
                                        )
                                  )}55`"
                                  :innerStrokeColor="`${pageScoreTintColor(
                                    isAiTab
                                      ? pageRun.aiRunScore
                                      : getStrategyScoreForPageRun(
                                          getPageRun(pageRun)
                                        )
                                  )}22`"
                                  strokeLinecap="square"
                                >
                                  <div
                                    class="has-text-weight-bold"
                                    :style="{ color: 'blue' }"
                                    v-if="
                                      pageStrategySetting.strategy == 'custom'
                                    "
                                  >
                                    N/A
                                  </div>
                                  <div
                                    class="has-text-weight-bold"
                                    :style="{
                                      color: pageScoreTintColor(
                                        isAiTab
                                          ? pageRun.aiRunScore
                                          : getStrategyScoreForPageRun(
                                              getPageRun(pageRun)
                                            )
                                      ),
                                    }"
                                    v-else
                                  >
                                    {{
                                      isAiRun(pageRun)
                                        ? pageRun.aiRunScore
                                        : getStrategyScoreForPageRun(
                                            getPageRun(pageRun)
                                          ) | round(1)
                                    }}
                                  </div>
                                </radial-progress-bar>
                                <div
                                  class="score-label"
                                  v-html="
                                    getStrategyScoreForPageRun(
                                      getPageRun(pageRun),
                                      true
                                    )
                                  "
                                ></div>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>

                      <footer
                        v-show="
                          pageRun.status === 'SUCCESS' ||
                          pageRun.status === 'FAILURE' ||
                          isAiRun(pageRun)
                        "
                        class="mt-8"
                      >
                        <div v-if="!isAiTab" class="d-flex flex-gap-5">
                          <template v-if="pageRun.isGuardRun">
                            <v-btn
                              rounded
                              color="profileAnchorColor"
                              class="button-with-icon text-none whiteColor--text mt25"
                              @click="
                                () => {
                                  if (pageRun.status === 'SUCCESS') {
                                    $router.push(
                                      `/sites/${site.id}/pages/${pageRun.pageId}/pageruns/${pageRun.id}`
                                    );
                                  } else {
                                    if (pageRun.failureReason) {
                                      $refs.reportErrorModal.showModalAction(
                                        pageRun.id,
                                        pageRun.failureReason
                                      );
                                    } else {
                                      $router.push(
                                        `/sites/${site.id}/pages/${pageRun.pageId}/pageRun/${pageRun.id}/rerun`
                                      );
                                    }
                                  }
                                }
                              "
                            >
                              <i class="material-icons">verified_user</i>
                              <span class="font-weight-bold">{{
                                pageRun.status === "SUCCESS"
                                  ? "Open Watchdog Report"
                                  : "Check errors"
                              }}</span>
                            </v-btn>
                          </template>
                          <template v-else>
                            <v-btn
                              rounded
                              color="profileAnchorColor"
                              class="open-button text-none whiteColor--text"
                              @click="
                                () => {
                                  if (pageRun.status === 'SUCCESS') {
                                    $router.push(
                                      `/sites/${site.id}/pages/${pageRun.pageId}/pageruns/${pageRun.id}`
                                    );
                                  } else {
                                    if (pageRun.failureReason) {
                                      $refs.reportErrorModal.showModalAction(
                                        pageRun.id,
                                        pageRun.failureReason
                                      );
                                    } else {
                                      $router.push(
                                        `/sites/${site.id}/pages/${pageRun.pageId}/pageRun/${pageRun.id}/rerun`
                                      );
                                    }
                                  }
                                }
                              "
                            >
                              <i class="material-icons">assignment</i>
                              <span>{{
                                pageRun.status === "SUCCESS"
                                  ? "Open report"
                                  : "Check errors"
                              }}</span>
                              <i class="material-icons">arrow_forward</i>
                            </v-btn>
                            <v-btn
                              rounded
                              outlined
                              color="mainColor"
                              class="open-button text-none"
                              @click="() => openAIWriter(pageRun)"
                              :disabled="getPageRun(pageRun).status == 'FAILURE'"
                            >
                              <i class="material-icons">auto_awesome</i>
                              <span>AI Writer</span>
                            </v-btn>
                          </template>
                        </div>
                        <v-btn
                          v-else
                          target="_blank"
                          rounded
                          block
                          outlined
                          color="white"
                          :to="`/content-editor?pageRunId=${pageRun.pageRunId}&aiRunId=${pageRun.id}&editorMode=AI_MODE`"
                          class="view-project-page-button mainColor--text text-none"
                        >
                          <span> Open AI Writer </span>
                          <i class="material-icons">arrow_forward</i>
                        </v-btn>
                      </footer>
                    </template>
                    <template v-else>
                      <div class="score-box-section mt-8">
                        <div
                          v-if="getPageRun(pageRun).status == 'PENDING'"
                          class="has-text-centered"
                        >
                          <span
                            class="tooltip is-tooltip-multiline mainColor--text"
                            data-tooltip="Please wait while PageOptimizer Pro analayzes your page data"
                          >
                            Processing
                          </span>
                          <br />
                          <breeding-rhombus-spinner
                            :animation-duration="2000"
                            :size="20"
                            color="#000000"
                            class="calc-spinner"
                          />
                          <br />
                          <v-progress-linear
                            rounded
                            height="15"
                            class="mb-5"
                            color="green1Color"
                            :value="progress"
                          />
                          <p class="gray1Color--text">
                            Processing may take up to 3 minutes.
                          </p>
                          <p style="word-wrap: break-word">
                            <span
                              v-if="getPageRun(pageRun).progress <= 50"
                              class="gray1Color--text"
                            >
                              Processing:
                              {{
                                getPageRun(pageRun)["competitors"] &&
                                getPageRun(pageRun).competitors.length > 1
                                  ? getPageRun(pageRun).competitors[
                                      getPageRun(pageRun).competitors.length - 1
                                    ].url
                                  : "target url"
                              }}
                            </span>
                            <span v-else class="gray1Color--text">
                              {{
                                getJoke(
                                  getPageRun(pageRun).progress,
                                  getPageRun(pageRun).competitors.length
                                )
                              }}
                              ...
                            </span>
                          </p>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <template
                v-if="
                  section.type == 'watchdogRun' &&
                  pageRunsByType[`${section.type}`].length == 0
                "
              >
                <div
                  class="notify-label is-success"
                  v-if="page.isGoogleGuardEnabled && page.guardNextRunAt"
                >
                  <i class="material-icons">check_circle</i>
                  <span>
                    Watchdog has been successfully set up and is monitoring your
                    specified target page and keyword. You can expect to receive
                    the first report soon.
                  </span>
                </div>
                <div class="notify-label" v-else>
                  <i class="material-icons">error_outline</i>
                  <span>
                    It looks like you haven't set up monitoring for this page
                    yet. Enable Watchdog now to keep track of changes in the
                    SERPS for specific keywords.<br />
                    Click the three dots in the top right corner of a page run
                    card >> Watchdog Settings to get started!
                  </span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <run-best-practices-model ref="bestPractices" />
      <page-run-note
        :pageRun="currentPageRun"
        :showModal="showNoteModal"
      ></page-run-note>

      <div
        class="modal"
        :class="{ 'is-active': showDeletePageRunConfirmModal }"
      >
        <div
          class="modal-background"
          @click="showDeletePageRunConfirmModal = false"
        ></div>
        <div class="modal-content">
          <div class="box modal-container" style="overflow: hidden">
            <div class="modal-content-root">
              <div class="is-size-4 mainColor--text font-weight-bold mb-5">
                Do you want to delete this page run report?
              </div>
              <div class="control flex mt20">
                <div class="flex-grow-1"></div>
                <v-btn
                  rounded
                  outlined
                  color="mainColor"
                  class="button-with-icon mainColor--text text-none"
                  @click="showDeletePageRunConfirmModal = false"
                >
                  <span>Cancel</span>
                </v-btn>
                <v-btn
                  rounded
                  color="profileAnchorColor"
                  class="button-with-icon whiteColor--text text-none ml10"
                  @click="
                    () => {
                      deleteAction();
                    }
                  "
                >
                  <span>Delete</span>
                </v-btn>
              </div>
            </div>
          </div>
          <button
            class="custom-modal-close-btn"
            aria-label="close"
            @click="showDeletePageRunConfirmModal = false"
          >
            <i class="material-icons">close</i>
          </button>
        </div>
      </div>
    </section>

    <div
      class="modal"
      :class="{ 'is-active': showDeletePageRunssConfirmModal }"
    >
      <div
        class="modal-background"
        @click="showDeletePageRunssConfirmModal = false"
      ></div>
      <div class="modal-content">
        <div class="box modal-container" style="overflow: hidden">
          <div class="modal-content-root">
            <div class="title-font is-size-4 mb-5 mainColor--text">
              Are you sure you want to delete these pagerun reports? <br />
            </div>
            <div class="body-font is-size-6 mainColor--text mb-3">
              The following pagerun reports have been selected:
            </div>
            <div
              v-for="p in selectedPageRuns"
              :key="p.id"
              class="is-size-6"
              style="max-height: 400px; overflow-y: auto"
            >
              <span class="mainColor--text font-weight-bold"
                >- {{ p.keyword ? p.keyword : page.keyword }}</span
              >
              <span class="ml-3 is-size-7">{{
                getDateAsLocalString(p.createdAt)
              }}</span>
            </div>
            <div class="control flex mt20">
              <div class="flex-grow-1"></div>
              <v-btn
                rounded
                outlined
                color="mainColor"
                class="button-with-icon mainColor--text text-none"
                @click="showDeletePageRunssConfirmModal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                rounded
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none"
                @click="deletePageRuns"
              >
                <span>Delete</span>
              </v-btn>
            </div>
          </div>
        </div>
        <button
          class="custom-modal-close-btn"
          aria-label="close"
          @click="showDeletePageRunssConfirmModal = false"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </div>

    <page-setting-modal ref="pageSettingModal" :disableDeletePage="true" />
    <insufficient-fund-modal ref="insufficientFundModal" />
    <report-error-modal ref="reportErrorModal" />
    <create-page-level-campaign-modal
      ref="createPageCampaignModalRef"
    ></create-page-level-campaign-modal>
  </div>
</template>

<script>
import RadialProgressBar from "vue-radial-progress";
import { BreedingRhombusSpinner } from "epic-spinners";
import { shortenUrl, isSameUrl } from "@/utils";
import { cloneDeep } from "lodash";
import { DEMO_SESSION_KEYS } from "@/components/PageRun/constants";

import AppMenu from "@/components/Menu.vue";
import { mixin } from "@/components/mixin";
import PageNoteModal from "@/components/PageNoteModal.vue";
import OptimizationsBarChart from "@/components/OptimizationsBarChart.vue";
import SimpleBarChart from "@/components/SimpleBarChart.vue";
import RunBestPracticesModel from "@/components/PageRun/RunBestPracticesModal.vue";
import PageSettingModal from "@/components/PageSettingModal.vue";
import InsufficientFundModal from "@/components/InsufficientFundModal.vue";
import ReportErrorModal from "@/components/ReportErrorModal.vue";
import CreatePageLevelCampaignModal from "@/components/Tools/CreatePageLevelCampaignModal.vue";

import { toolTabs } from "@/consts/ui_consts.js";

const DEMO_LOADER_KEY = "isDemoLoaderShown";

function getExternalData() {
  return JSON.parse(sessionStorage.getItem('popReortExternalData')) || {};
}

export default {
  components: {
    RadialProgressBar,
    AppMenu,
    PageRunNote: PageNoteModal,
    OptimizationsBarChart,
    RunBestPracticesModel,
    SimpleBarChart,
    BreedingRhombusSpinner,
    PageSettingModal,
    InsufficientFundModal,
    ReportErrorModal,
    CreatePageLevelCampaignModal,
  },
  mixins: [mixin],
  data() {
    return {
      progress: 1,
      currentToolTab: "",

      errMsg: "",

      pageId: "",
      pageName: "",
      isEditMode: false,

      showNoteModal: false,
      currentPageRun: {},

      showDeletePageRunConfirmModal: false,
      selectedPageRunId: 0,

      selectedPageRunIds: [],
      showDeletePageRunssConfirmModal: false,

      processingJokes: [
        "Petting unicorns",
        "Calculating PI",
        "Talking to Pythagoras",
        "Carrying the 1",
        "Passing the event horizon",
        "Finding the nexus of the universe",
        "A short ride on the Curiosity Rover",
        "Forming a tesseract",
        "Taking the elevator up",
      ],

      ro: null,
      refreshKey: 0,
    };
  },
  watch: {
    pageRunsByType: function (newVal) {
      const externalData = getExternalData();
      if (newVal?.normalRun?.length && externalData.openWdSettings) {
        const pageRun = newVal.normalRun.find(run => run.id === externalData.pageRunId);
        if (!pageRun) return;

        this.enterPageSetting(pageRun, true)
      }
    },
    $route: function (newVal, oldVal) {
      const oldParams = Object.keys(oldVal.params)
        .sort()
        .map((param) => oldVal.params[param].toString());
      const newParams = Object.keys(newVal.params)
        .sort()
        .map((param) => newVal.params[param].toString());
      if (oldParams.toString() !== newParams.toString()) {
        this.loadPageRuns();
      }
    },
    progress: function (newVal) {
      if (newVal >= 100) {
        clearInterval(this.timer);
        localStorage.setItem(DEMO_LOADER_KEY, true);
      }
    },
    pageRuns: function (newVal) {
      if (this.$store.getters.isDemoUser && newVal?.length) {
        const updatedPageRuns = cloneDeep(newVal);
        const pageRun = updatedPageRuns[0];
        const [isPageCreated, isPageRunCreating, isPageRunCreated] = [
          DEMO_SESSION_KEYS.pageCreated,
          DEMO_SESSION_KEYS.runCreating,
          DEMO_SESSION_KEYS.runCreated,
        ].map((key) => sessionStorage.getItem(key));
        if (
          !(
            isPageCreated &&
            !isPageRunCreated &&
            (!isPageRunCreating || pageRun.status != "PENDING")
          )
        ) {
          return;
        }

        const oldStatus = pageRun.status;
        pageRun.status = "PENDING";
        sessionStorage.setItem(DEMO_SESSION_KEYS.runCreating, true);
        this.$store.commit("setPageRuns", updatedPageRuns);
        setTimeout(() => {
          pageRun.status = oldStatus;
          sessionStorage.setItem(DEMO_SESSION_KEYS.runCreated, true);
          this.$store.commit("setPageRuns", updatedPageRuns);
        }, 10000); // 10 seconds
      }
    },
  },
  computed: {
    toolTabs() {
      if (this.isSpecialAdminUser) {
        return toolTabs;
      } else {
        return toolTabs.filter((item) => !item.isSpecialUserOnly);
      }
    },
    window() {
      return window;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isSpecialAdminUser() {
      return this.$store.getters.isSpecialAdminUser;
    },
    isUnlimitedUser() {
      return this.$store.getters.isUnlimitedUser;
    },
    isAiTab() {
      return this.currentToolTab === "ai-writer";
    },
    selectedPageRuns() {
      return this.selectedPageRunIds.reduce((rlt, pageRunId) => {
        let found = this.pageRuns.find((ele) => {
          return ele.id == pageRunId;
        });
        if (found) {
          rlt.push(found);
        }
        return rlt;
      }, []);
    },
    selectedSubAccountRelation() {
      return this.$store.state.selectedSubAccountRelation;
    },
    pageRunFromAiRun() {
      return this.pageRuns.reduce((obj, pgRun) => {
        obj[pgRun.id] = pgRun;
        return obj;
      }, {});
    },
    sections() {
      if (this.currentToolTab == "on-page-optimization") {
        return [
          {
            label: "Page runs",
            type: "normalRun",
          },
          {
            label: "Watchdog runs",
            type: "watchdogRun",
          },
        ];
      } else {
        return [
          {
            label: "Page runs",
            type: "normalRun",
          },
        ];
      }
    },
    pageRunsByType() {
      this.refreshKey; // just mention to use refresh
      let rlt = {};
      if (this.currentToolTab == "on-page-optimization") {
        rlt["normalRun"] = this.pageRuns.filter((p) => !p.isGuardRun);
        rlt["watchdogRun"] = this.pageRuns.filter((p) => p.isGuardRun);
      } else if (this.currentToolTab == "ai-writer") {
        rlt["normalRun"] = this.$store.state.aiRuns;
      }
      return rlt;
    },
    isScrollNeeded() {
      return this.$route.query.scroll_to_wd === 'true'
    }
  },
  methods: {
    createAiWriterForRecentReport() {
      if (this.pageRuns.length == 0) {
        this.$notify({
          group: "info",
          type: "warning",
          text: "Can not find any report",
        });
        return;
      }
      this.openAIWriter(this.pageRuns[0]);
    },
    isPageRunCreated() {
      return !!sessionStorage.getItem(DEMO_SESSION_KEYS.runCreated);
    },
    isPageRunCreating() {
      return !!sessionStorage.getItem(DEMO_SESSION_KEYS.runCreating);
    },
    getPageRun(aiRunOrPageRun) {
      const resp = this.isAiRun(aiRunOrPageRun)
        ? this.pageRunFromAiRun[aiRunOrPageRun.pageRunId] || {}
        : aiRunOrPageRun;
      return resp;
    },
    isAiRun(pageRun) {
      return pageRun.isAIRun == true;
    },
    toolChangeAction(toolKey) {
      this.currentToolTab = toolKey;
      this.selectedPageRunIds = [];
    },
    openAIWriter(pageRun) {
      let queryParams = {};
      queryParams = { pageRunId: pageRun.id };
      queryParams["editorMode"] = "AI_MODE";

      if (this.selectedSubAccountRelation) {
        queryParams["relId"] = this.selectedSubAccountRelation.id;
      }
      let routeData = this.$router.resolve({
        name: "FullPageSourceEditor",
        query: queryParams,
      });
      window.open(routeData.href, "_blank");
    },
    hasDroppedOutOfTop10Url(pageRun) {
      const guardDiffData = pageRun.guardDiffData;
      if (!guardDiffData) {
        return false;
      }
      const competitorsChange = guardDiffData.competitors_change || [];
      const removedCompetitors = competitorsChange.filter(
        (item) => item.type == "removed_url"
      );
      return removedCompetitors.some((item) => {
        return isSameUrl(item.item.url, pageRun.url);
      });
    },
    shortenUrl(url, length = null) {
      return shortenUrl(url, length);
    },
    enterPageSetting(pageRun, enablePopup = false) {
      this.$refs.pageSettingModal.show(this.page, null, "", pageRun, enablePopup);
    },
    makeRerunAnalysis(pageRun) {
      if (this.canCreateRerun) {
        this.$router.push(
          `/sites/${this.site.id}/pages/${pageRun.pageId}/pageRun/${pageRun.id}/rerun`
        );
      } else {
        this.$refs.insufficientFundModal.show();
      }
    },
    canInputNote(pageRun) {
      return true;
      // if (!pageRun.isEatCalculationUsed) {
      //   return true
      // } else {
      //   if(pageRun.eatStatus == 'FAILURE' || pageRun.eatStatus == 'SUCCESS') {
      //     return true
      //   }
      // }
      // return false
    },
    getJoke(progress, competitorsCount) {
      let step = (0.5 * 100) / competitorsCount;
      let idx = Math.round((progress - 50) / step) - 1;
      if (idx >= this.processingJokes.length) {
        idx = this.processingJokes.length - 1;
      }
      return this.processingJokes[idx];
    },
    isSelectedPageRun(pageRunId) {
      return this.selectedPageRunIds.includes(pageRunId);
    },
    selectPageRun(pageRunId) {
      if (this.selectedPageRunIds.indexOf(pageRunId) === -1) {
        this.selectedPageRunIds.push(pageRunId);
      }
    },
    unSelectPageRun(pageRunId) {
      let idx = this.selectedPageRunIds.indexOf(pageRunId);
      if (idx >= 0) {
        this.selectedPageRunIds.splice(idx, 1);
      }
    },
    cancelAllSelection() {
      this.selectedPageRunIds = [];
    },
    toggleEditMode() {
      this.isEditMode = !this.isEditMode;
    },
    changePageName() {
      if (this.page.name == this.pageName || this.pageName == "") {
        this.pageName = this.page.name;
        this.toggleEditMode();
        return;
      }

      this.$store.commit("showLoading");
      this.$store
        .dispatch("changePageName", {
          id: this.pageId,
          pageName: this.pageName,
        })
        .then((response) => {
          this.toggleEditMode();
          return this.loadSites();
          // return this.loadPages();
        })
        .catch(() => {
          this.$store.commit("hideLoading");
          this.toggleEditMode();
        });
    },
    addPage() {
      if (this.canCreateNewPage) {
        this.errMsg = "";
        this.$router.push(`/sites/${this.site.id}/newpage`);
      } else {
        this.errMsg =
          "Insufficient report balance to create a page.  Please purchase more reports in your user profile dashboard.";
      }
    },
    showBestPractices() {
      this.$refs.bestPractices.show();
    },
    displayDeletePageRunConfirmModal(pageRunId) {
      this.selectedPageRunId = pageRunId;
      this.showDeletePageRunConfirmModal = true;
    },
    deleteAction() {
      if (this.isAiTab) {
        this.deleteAIRun();
      } else {
        this.deletePageRun();
      }
    },
    deletePageRun() {
      if (this.selectedPageRunId > 0) {
        const { siteId, pageId } = this.$route.params;
        this.$store.commit("showLoading");
        return this.$store
          .dispatch("removePageRun", {
            pageRunId: this.selectedPageRunId,
            siteId,
            pageId,
          })
          .then((response) =>
            this.$store.dispatch("loadPageRuns", this.$route.params)
          )
          .then((response) => {
            if (response.data && response.data.status === "SUCCESS") {
              this.unSelectPageRun(this.selectedPageRunId);
              this.selectedPageRunId = 0;
              this.$store.commit("setPageRuns", response.data.pageRuns);
              this.$store.commit("setAiRuns", response.data.aiRuns);
              this.refreshKey++;
            }
          })
          .finally(() => {
            this.$store.commit("hideLoading");
            this.showDeletePageRunConfirmModal = false;
          });
      }
    },
    deleteAIRun() {
      if (this.selectedPageRunId > 0) {
        const { siteId, pageId } = this.$route.params;
        this.$store.commit("showLoading");
        return this.$store
          .dispatch("deleteAIRun", {
            runId: this.selectedPageRunId,
            siteId,
            pageId,
          })
          .then((response) =>
            this.$store.dispatch("loadPageRuns", this.$route.params)
          )
          .then((response) => {
            if (response.data && response.data.status === "SUCCESS") {
              this.unSelectPageRun(this.selectedPageRunId);
              this.selectedPageRunId = 0;
              this.$store.commit("setPageRuns", response.data.pageRuns);
              this.$store.commit("setAiRuns", response.data.aiRuns);
              this.refreshKey++;
            }
          })
          .finally(() => {
            this.$store.commit("hideLoading");
            this.showDeletePageRunConfirmModal = false;
          });
      }
    },
    deletePageRuns() {
      if (this.selectedPageRunIds.length > 0) {
        this.$store.commit("showLoading");

        const { siteId, pageId } = this.$route.params;
        this.$store
          .dispatch("removePageRuns", {
            siteId,
            pageId,
            pageRunIds: this.selectedPageRunIds,
          })
          .then((response) => {
            this.selectedPageRunIds = [];
            this.loadPageRuns();
          })
          .finally(() => {
            this.showDeletePageRunssConfirmModal = false;
            this.$store.commit("hideLoading");
          });
      }
    },
    pageScoreBGClass(pageRun) {
      let value = pageRun.keywordCounts.pageScore.pageScore;
      return {
        "page-score-poor": value >= 0 && value <= 69,
        "page-score-fair": value > 69 && value <= 74,
        "page-score-good": value > 74 && value <= 80,
        "page-score-verygood1": value > 80 && value <= 83,
        "page-score-verygood2": value > 83 && value <= 85,
        "page-score-verygood3": value > 85 && value <= 88,
        "page-score-verygood4": value > 88 && value <= 90,
        "page-score-verygood5": value > 90 && value <= 93,
        "page-score-verygood6": value > 93 && value <= 95,
        "page-score-verygood7": value > 95,
      };
    },
    loadSites() {
      this.$store
        .dispatch("loadSites")
        .then((response) => {
          if (response.data && response.data.status === "SUCCESS") {
            this.$store.commit("setSites", response.data.sites);
            this.$store.commit("hideLoading");
          }
        })
        .finally(() => this.$store.commit("hideLoading"));
    },
    loadPageRuns() {
      this.$store.commit("showLoading");
      return this.$store
        .dispatch("loadPageRuns", this.$route.params)
        .then((response) => {
          if (response.data && response.data.status === "SUCCESS") {
            this.$store.commit("setPageRuns", response.data.pageRuns);
            this.$store.commit("setAiRuns", response.data.aiRuns);
            this.refreshKey++;
            if (this.$route.query.pageRunId) {
              let pageRunId = this.$route.query.pageRunId;
              this.$router.push(
                `/sites/${this.$route.params.siteId}/pages/${this.$route.params.pageId}/pageruns/${pageRunId}`
              );
            } else {
              // setTimeout(() => {
              //   this.registerResizeObserver()
              // }, 100);
            }
          }
          if (this.isScrollNeeded) {
            const pageRun = response.data.pageRuns.find(pageRun => this.page.guardRunId == pageRun.id)
            if (pageRun) {
              document.getElementById(`_page_run_${pageRun.id}_`)?.scrollIntoView?.()
              setTimeout(() => this.$router.replace({ query: {} }), 200)
            }
          }
          this.$store.commit("hideLoading");
          return this.$store.dispatch("checkPageRunsQueue", this.$route.params);
        });
    },
    uniqueUrlForEditor(pageRun) {
      let routeData = this.$router.resolve({
        name: "FullPageSourceEditor",
        query: { secretKey: pageRun.secretKey },
      });
      return window.location.origin + "/" + routeData.href;
    },

    showNoteModalAction(pageRun) {
      this.currentPageRun = pageRun;
      this.showNoteModal = true;
    },
    hideNoteModalAction() {
      this.currentPageRun = {};
      this.showNoteModal = false;
    },

    getStrategyScoreForPageRun(pageRun, getScoreTypeLabel = false) {
      let strategySetting = this.page.strategySetting;
      let strategy = strategySetting ? strategySetting.strategy : "target";
      let approach = strategySetting ? strategySetting.approach : "regular";
      if (!this.$store.state.strategies.includes(strategy)) {
        strategy = "target";
      }
      if (!this.$store.state.approachies.includes(approach)) {
        approach = "regular";
      }

      let dashboardType = "contentBrief";
      if (
        strategySetting &&
        this.$store.state.dashboardTypes.includes(strategySetting.dashboardType)
      ) {
        dashboardType = strategySetting.dashboardType;
      }

      if (strategy == "custom") {
        if (getScoreTypeLabel) {
          return "Optimization score";
        } else {
          return 0;
        }
      }

      if (strategySetting || dashboardType == "contentBrief") {
        let found = pageRun.runStrategies.find((item) => {
          if (dashboardType == "contentBrief") {
            return item.dashboardType == dashboardType;
          } else if (dashboardType == "signals") {
            return item.approach == approach && item.strategy == strategy;
          }
          return false;
        });
        if (found) {
          if (getScoreTypeLabel) {
            return this.getScoreTypeLabelByStrategy(
              this.isAiTab ? "aiWriter" : dashboardType,
              strategy,
              approach
            );
          } else {
            return found.pageScore.pageScore;
          }
        }
      }

      if (getScoreTypeLabel) {
        return "Optimization score";
      } else {
        return pageRun.keywordCounts.pageScore.pageScore;
      }
    },
    getClassesForGuardRun(pageRun) {
      return {
        "change-needed":
          pageRun.isGuardRun && this.isGuardRunNeedsChange(pageRun),
        "all-good": pageRun.isGuardRun && !this.isGuardRunNeedsChange(pageRun),
        // 'is-tooltip-bottom tooltip': pageRun.isGuardRun,
      };
    },
    isGuardRunNeedsChange(pageRun) {
      if (!pageRun || !pageRun.isGuardRun) {
        return false;
      }
      let found = pageRun.runStrategies.find(
        (item) => item.dashboardType == "contentBrief"
      );
      if (!found) {
        return false;
      }
      let sections = [
        "titleTotal",
        "pageTitleTotal",
        "subHeadingsTotal",
        "pTotal",
      ];
      for (const section of sections) {
        const sectionData = found[section];
        const current = sectionData.current;
        const minTarget = sectionData.min;
        const maxTarget = sectionData.max;
        if (current < minTarget || current > maxTarget) {
          return true;
        }
      }
      return false;
    },
    pinPageRunSettingAction(pageRunId, isPinned) {
      this.$store.commit("showLoading");
      this.$store
        .dispatch("updatePinPageRun", {
          pageRunId,
          isPinned: isPinned ? 1 : 0,
        })
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.$store.commit("updatePageRun", response.data.pageRun);
            this.$notify({
              group: "info",
              type: "success",
              text: `Successfully saved.`,
            });
          } else {
            this.$notify({
              group: "info",
              type: "error",
              text: `Can not save.`,
            });
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },

    // onResize () { // change grid
    //   const cardWidth = 336;
    //   if (this.$refs.observer) {
    //     const width = this.$refs.observer.clientWidth;
    //     let style = `repeat(4, ${cardWidth}px)`
    //     console.log('width is', width);

    //     if ( width < 1520) { //$run-card-width * 4 + 10 (gap) * 3  = 1470 + (50px for extra)
    //       style = `repeat(3, ${cardWidth}px)`
    //     }
    //     if ( width < 1150) { // $run-card-width * 3 + 10 (gap) * 2  = 1100 + (50px for extra)
    //       style = `repeat(2, ${cardWidth}px)`
    //     }
    //     if ( width < 780) { // $run-card-width * 2 + 10 (gap) * 1  = 730 + (50px for extra)
    //       style = `repeat(1, ${cardWidth}px)`
    //     }
    //     if ( width < 365) {
    //       style = `1fr`;
    //     }

    //     (this.$refs.pageRunsContainers || []).map(element => {
    //       element.style.gridTemplateColumns = style;
    //     });
    //   }
    // },
    // registerResizeObserver() {
    //   if (this.ro == null && this.$refs.observer) {
    //     this.ro = new ResizeObserver(this.onResize)
    //     this.ro.observe(this.$refs.observer);
    //   }
    // }
  },
  updated() {
    if (this.page.id != this.pageId) {
      this.pageName = this.page.name;
      this.pageId = this.page.id;
      this.isEditMode = false;
    }
  },
  beforeMount() {
    return this.loadPageRuns();
  },
  mounted() {
    this.$store.commit("setHideSideBar", this.$store.state.isUserClosedSideBar);
    // this.registerResizeObserver()

    let tabKey = this.$route.query.tab || this.toolTabs[0].key;
    this.toolChangeAction(tabKey);

    if (this.isPageRunCreated()) {
      this.progress = 100;
    } else if (this.$store.getters.isDemoUser) {
      this.timer = setInterval(() => {
        this.progress = this.progress + 10;
      }, 1000);
    } else {
      this.timer = setInterval(() => {
        this.progress = this.progress + this.progress * 0.75;
      }, 1500);
    }
  },
  beforeDestroy() {
    // if (this.ro && this.$refs.observer) {
    //   this.ro.unobserve(this.$refs.observer);
    // }

    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.signal-element {
  display: flex;
  font-weight: 700;
  padding: 2px 5px;
  border-radius: 5px;
  // background: rgb(126, 126, 126);
  background: var(--v-link9Color-base);
  color: var(--v-mainColor-base);
  font-size: 13px;
}

.hero-body {
  padding: 0rem 1.5rem 0rem 1.5rem !important;
}

.under-optimized {
  background-color: #f0c291;
}
.over-optimized {
  background-color: #cf8c8e;
}

/* .page-score-pass {
  background-color: lightgreen;
}
.page-score-fail {
  background-color: red;
} */

.notes-container {
  max-width: 350px;
}

$run-card-width: 336;
.page-runs-container {
  margin: auto;
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax($run-card-width * 1px, $run-card-width * 1px)); */
  // grid-template-columns: repeat(4, $run-card-width * 1px);
  grid-template-columns: repeat(auto-fill, $run-card-width * 1px);
  /* grid-template-columns: repeat(auto-fill, minmax($run-card-width * 1px, 1fr)); */
  // justify-content: center;
  gap: 10px;

  .top-part {
    padding: 13px 30px 13px 30px;
  }
  .border-bottom {
    border-bottom: 1.5px solid var(--v-grayColor-base) !important;
  }
  .main-part {
    flex-grow: 1;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 17px;
    display: flex;
    flex-direction: column;

    .keyword {
      font-size: 1.1rem;
      font-weight: 800;
      color: var(--v-mainColor-base);
      word-break: break-word;
    }

    .item-label {
      font-size: 14px;
      color: var(--v-mainColor-base);
    }

    footer {
      .icon-buttons-part {
        display: flex;
        align-items: center;
      }
    }
  }
}

/* // $run-card-width * 4 + 10 (gap) * 3  = 1470 + (50px for extra)
@media screen and (max-width: 1520px) {
  .page-runs-container {
    grid-template-columns: repeat(3, $run-card-width * 1px);
  }
}

// $run-card-width * 3 + 10 (gap) * 2  = 1100 + (50px for extra)
@media screen and (max-width: 1150px) {
  .page-runs-container {
    grid-template-columns: repeat(2, $run-card-width * 1px);
  }
}

// $run-card-width * 2 + 10 (gap) * 1  = 730 + (50px for extra)
@media screen and (max-width: 780px) {
  .page-runs-container {
    grid-template-columns: repeat(1, $run-card-width * 1px);
  }
}

// $run-card-width + (5 px extra)
@media screen and (max-width: 365px) {
  .page-runs-container {
    grid-template-columns: 1fr;
  }
} */

.selected-pagerun {
  border: 2px solid #ff9b00;
}

.pagerun-card {
  position: relative;
  padding-bottom: 10px;
  min-height: 150px;
  background-color: var(--v-cardBg-base) !important;
  color: black;
  border-radius: 10px;
  border: 1.5px solid var(--v-grayColor-base);

  .open-button {
    padding: 10px 13px !important;
    & span {
      font-size: 0.78rem;
      font-weight: bold;
    }
    & i {
      font-size: 0.8rem;
    }
    img {
      width: 14px;
      height: auto;
    }
  }

  .guard-card-dot {
    cursor: pointer;
  }

  .checked-box {
    font-size: 1.3rem;
    cursor: pointer;
    color: var(--v-grayColor-base);
    z-index: 1;
  }
  .pagerun-type {
    font-size: 0.8rem;
    font-weight: 600;
    padding: 5px 15px;
    border-radius: 5px;
    color: var(--v-mainColor-base);
    background: var(--v-gray15Color-base);
  }

  .note-button {
    color: #209cee;
    cursor: pointer;
  }

  .note-icon {
    font-size: 1rem;
    cursor: pointer;
    color: #209cee;
  }
  .pagerun-note {
    color: #209cee;
  }

  .guard-status-box {
    border-radius: 5px;
    padding: 25px 0px 25px 35px;
    overflow: hidden;

    i {
      font-size: 20px;
    }
    .texts {
      margin-left: 15px;
      .title-label {
        font-size: 15px;
        font-weight: 800;
      }
      .explain {
        color: var(--v-gray2Color-base);
        font-size: 13px;
        margin-top: 5px;
      }
    }
  }
  .change-needed {
    position: relative;
    background: var(--v-red1Color-base);
    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
      background: var(--v-red2Color-base);
    }

    i,
    .title-label {
      color: var(--v-red2Color-base);
    }
  }
  .all-good {
    background: var(--v-green4Color-base);
    &::before {
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      bottom: 0;
      width: 20px;
      background: var(--v-green5Color-base);
    }
    i,
    .title-label {
      color: var(--v-green5Color-base);
    }
  }

  footer {
    .pagerun-card-button {
      background: #fffaf2;
      border: 1px solid #ffebcc;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 5px 8px;
      span {
        margin-left: 5px;
        color: #62605d;
        font-weight: bold;
        font-size: 0.75rem;
      }
      i.material-icons {
        font-size: 0.8rem;
        color: #ff9b00;
      }
    }

    .very-bottom {
      display: flex;
      justify-content: space-between;
      .rerun-button {
        border: 0.8px solid var(--v-grayColor-base) !important;
        span {
          font-size: 10px;
        }
        text-transform: none;
        color: var(--v-gray2Color-base);
      }
    }
  }
  .calc-spinner {
    margin: 5px auto 0px auto;
  }
}

.no-run-placeholder {
  display: flex;
  align-items: center;
  gap: 20px;
  .img-wrapper {
    padding: 20px;
    background: #f3f4fa;
    img {
      width: 25vw;
    }
  }
  .title-part {
    font-size: 1.5rem;
    line-height: 110%;
    font-weight: 800;
    color: var(--v-mainColor-base);
  }
  .desc-part {
    max-width: 500px;
    margin-top: 20px;
    font-size: 1rem;
    line-height: 120%;
    color: var(--v-mainColor-base);
  }
}
</style>

import Vue from "vue";
import Router from "vue-router";
import Login from "@/components/Login.vue";
import PopDemo from "@/components/PopDemo.vue";
import StandardRegister from "@/components/Register/StandardRegister.vue";
import DynamicRegister from "@/components/Register/DynamicRegister.vue";
import RegisterNoCard from "@/components/Register/RegisterNoCard.vue";
import RegisterCheckEmail from "@/components/Register/RegisterCheckEmail.vue";
import ResetPasswordPage from "@/components/ResetPasswordPage.vue";
import EmailChangeResult from "@/components/IndependentPages/EmailChangeResult.vue";
import ManageCards from "@/components/ManageCards.vue";
import UserProfile from "@/components/UserProfile.vue";
import ServicePurchase from "@/components/ServicePurchase.vue";
import ForgotPassword from "@/components/ForgotPassword.vue";
import ManageSubAccounts from "@/components/ManageSubAccounts.vue";
import Sites from "@/components/Sites.vue";
import NewSite from "@/components/NewSite.vue";
import ListTools from "@/components/Tools/ListTools.vue";
import Pages from "@/components/pages/Pages.vue";
import NewPage from "@/components/NewPage.vue";
import PageRuns from "@/components/pageruns/PageRuns.vue";
import PageRun from "@/components/PageRun/Index.vue";
import RerunAnalysis from "@/components/PageRun/RerunAnalysis.vue";
import AdminIndex from "@/components/Admin/index.vue";
import NewBulkRun from "@/components/NewBulkRun.vue";
import GuardDashboard from "@/components/GuardDashboard.vue";
import AIWriterLogDashboard from "@/components/AIWriterLogDashboard.vue";
import ApiUsageDashboard from "@/components/ApiUsageDashboard.vue";
// import BulkRuns from '@/components/BulkRuns'
import CancelSubscriptionConfirm from "@/components/subscription-cancel/CancelSubscriptionConfirm.vue";
import TellUsYourExperience from "@/components/subscription-cancel/TellUsYourExperience.vue";
import SubscriptionCancelSucceed from "@/components/subscription-cancel/SubscriptionCancelSucceed.vue";


// Independent routes which doesn't need auth
import DemoPage from "@/components/IndependentPages/Demo.vue";
import APIDoc from "@/components/IndependentPages/APIDoc.vue";
import UsageStatsPage from "@/components/IndependentPages/UsageStats.vue";
import ExtChecklist from "@/components/IndependentPages/ExtChecklist.vue";
import FullPageSourceEditor from "@/components/IndependentPages/FullPageSourceEditor.vue";
import WriterWaitingPage from "@/components/IndependentPages/WriterWaitingPage.vue";
import OpenAITest from "@/components/IndependentPages/OpenAITest.vue";
import UnsubscribeWatchdog from "@/components/IndependentPages/UnsubscribeWatchdog.vue";
import PlaywithOpenai from "@/components/IndependentPages/PlaywithOpenai.vue";

import UIPreDesign from "@/components/Campaigns/UIPreDesign.vue"
import Campaign from '@/components/Campaigns/Index.vue'

// Campaign tabs
import KeywordResearch from "@/components/Campaigns/keyword-research/KeywordResearch.vue"
import KeywordWizard from "@/components/Campaigns/keyword-wizard/KeywordWizard.vue"
import SupportingKeywordResearch from "@/components/Campaigns/supporting-keyword-research/SupportingKeywordResearch.vue"
import KeywordGap from "@/components/Campaigns/keyword-gap/KeywordGap.vue"
import KeywordTracking from "@/components/Campaigns/keyword-tracking/KeywordTracking.vue"
import Silo from "@/components/Campaigns/silo/Silo.vue"

// Campaign > Keyword Wizard tabs
// import SiteOnlyVoltageComponent from '@/components/Campaigns/keyword-wizard/site-only/SiteOnlyVoltageComponent.vue';
import KeywordOnlyComponent from '@/components/Campaigns/keyword-wizard/keyword-only/KeywordOnlyComponent.vue';
import PageVoltageComponent from '@/components/Campaigns/keyword-wizard/page-voltage/PageVoltageComponent.vue';
import PageOpportunityComponent from '@/components/Campaigns/keyword-wizard/page-opportunity/PageOpportunityComponent.vue';

import ProjectLevelWorkStation from '@/components/Workstation/ProjectLevelWorkStation.vue'
import PageLevelWorkStation from '@/components/Workstation/PageLevelWorkStation.vue'
import ProgressNotes from '@/components/Workstation/ProgressNotes/ProgressNotes.vue'

import store from "@/store";
import _ from "lodash";

Vue.use(Router);

const router = new Router({
  linkExactActiveClass: "is-active",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Login,
      beforeEnter(to, from, next) {
        if (store.getters.isAuthenticated) {
          next("/sites");
        } else {
          store.commit("clearStore");
          if (from.name) {
            window.location = "https://pageoptimizer.pro";
          } else {
            next("/login");
          }
        }
      }
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/pop-demo",
      name: "PopDemo",
      component: PopDemo
    },
    {
      path: "/register/:service",
      name: "DynamicRegister",
      component: DynamicRegister
    },
    {
      path: "/register",
      name: "StandardRegister",
      component: StandardRegister
    },
    {
      path: "/register-no-card",
      name: "RegisterNoCard",
      component: RegisterNoCard
    },
    {
      path: "/register-check-email",
      name: "RegisterCheckEmail",
      component: RegisterCheckEmail
    },
    {
      path: "/changepassword",
      name: "UpdatePassword",
      component: ResetPasswordPage
    },
    {
      path: "/forgotpassword",
      name: "ForgotPassword",
      component: ForgotPassword
    },
    {
      path: "/email-change-result",
      name: "EmailChangeResult",
      component: EmailChangeResult
    },
    {
      path: "/ui-pre-design",
      name: "UIPreDesign",
      component: UIPreDesign,
    },
    {
      path: "/manage-subaccounts",
      name: "ManageSubaccounts",
      component: ManageSubAccounts,
      beforeEnter(to, from, next) {
        if (store.getters.isAgencyUnlimitedUser) {
          next();
        } else {
          next("/");
        }
      }
    },
    {
      path: "/useraccount/:userId/payment-options",
      name: "ManageCards",
      component: ManageCards
    },
    {
      path: "/useraccount/:userId",
      name: "UserProfile",
      component: UserProfile
    },
    {
      path: "/useraccount/:userId/purchase",
      name: "ServicePurchase",
      component: ServicePurchase
    },
    {
      path: "/sites",
      name: "Sites",
      component: Sites,
      beforeEnter(to, from, next) {
        store.commit("setCurrentSiteById", { siteId: -1 });
        next();
      }
    },
    {
      path: "/newsite",
      name: "NewSite",
      component: NewSite
    },
    {
      path: "/sites/:siteId/tools",
      name: "ListTools",
      component: ListTools,
    },
    {
      path: "/sites/:siteId/pages",
      name: "Pages",
      component: Pages
    },
    {
      path: "/sites/:siteId/pages/:pageId",
      name: "PageRuns",
      component: PageRuns
    },
    {
      path: "/sites/:siteId/newpage",
      name: "NewPage",
      component: NewPage
    },
    {
      path: "/sites/:siteId/pages/:pageId/pageruns/:pageRunId",
      name: "PageRun",
      component: PageRun,
      beforeEnter(to, from, next) {
        if (from.params["pageRunId"] !== to.params["pageRunId"]) {
          store.commit("setSeoSourceCode", "");
        }
        next();
      }
    },
    {
      path: '/sites/:siteId/campaign/:campaignId',
      name: 'Campaign',
      component: Campaign,
      redirect: {name: 'keyword-insight'},  // default tab
      children: [
        {
          path: 'keyword-insight',
          name: 'keyword-insight',
          component: KeywordResearch,
        },
        {
          path: 'keyword-wizard',
          name: 'keyword-wizard',
          component: KeywordWizard,
          children: [
            {
              path: 'page-opportunity',
              name: 'page-opportunity',
              component: PageOpportunityComponent,
            },
            {
              path: 'keyword-only',
              name: 'keyword-only',
              component: KeywordOnlyComponent,
            },
            {
              path: 'site-only',
              name: 'site-only',
              component: PageVoltageComponent,
            },
            {
              path: 'site-url',
              name: 'site-url',
              component: PageVoltageComponent,
            },
            // {
            //   path: 'site-only',
            //   name: 'site-only',
            //   component: SiteOnlyVoltageComponent,
            // },
          ]
        },
        {
          path: 'silo',
          name: 'silo',
          component: Silo,
        },
        {
          path: 'supporting-keyword-research',
          name: 'supporting-keyword-research',
          component: SupportingKeywordResearch,
        },
        {
          path: 'keyword-gap',
          name: 'keyword-gap',
          component: KeywordGap,
        },
        {
          path: 'keyword-tracking',
          name: 'keyword-tracking',
          component: KeywordTracking,
        }
      ]
    },
    {
      path: "/content-editor",
      name: "FullPageSourceEditor",
      component: FullPageSourceEditor
    },
    {
      path: "/ai-writer-waiting/:siteId",
      name: "WriterWaitingPage",
      component: WriterWaitingPage
    },
    {
      path: "/sites/:siteId/pages/:pageId/pageRun/:pageRunId/rerun",
      name: "RerunAnalysis",
      component: RerunAnalysis
    },
    {
      path: "/admin/:userId",
      name: "AdminIndex",
      component: AdminIndex,
      beforeEnter(to, from, next) {
        if (store.getters.isAdmin || store.state.user.isAffiliate) {
          next();
        } else {
          next("/");
        }
      }
    },
    // {
    //   path: '/bulk-runs',
    //   name: 'BulkRuns',
    //   component: BulkRuns,
    //   beforeEnter(to, from, next) {
    //     if (store.getters.isAgencyUnlimitedUser) {
    //       next()
    //     } else {
    //       next('/')
    //     }
    //   }
    // },
    {
      path: "/new-bulk-run",
      name: "NewBulkRun",
      component: NewBulkRun,
      beforeEnter(to, from, next) {
        if (store.getters.isUnlimitedUser || store.state.selectedSubAccountRelation) {
          next();
        } else {
          next("/");
        }
      },
      beforeRouteLeave: function(to, from, next) {
        this.prepareToExit();
        next();
      }
    },
    {
      path: "/guard-dashboard",
      name: "GuardDashboard",
      component: GuardDashboard
    },
    {
      path: "/ai-writer-log",
      name: "AIWriterLogDashboard",
      component: AIWriterLogDashboard
    },
    {
      path: "/api-usage-dashboard",
      name: "ApiUsageDashboard",
      component: ApiUsageDashboard,
      beforeEnter(to, from, next) {
        if (store.getters.hasApiDashboardAccess) {
          next();
        } else {
          next("/");
        }
      }
    },
    {
      path: "/cancel-subscription-confirm",
      name: "CancelSubscriptionConfirm",
      component: CancelSubscriptionConfirm
    },
    {
      path: "/tell-us-your-experience",
      name: "TellUsYourExperience",
      component: TellUsYourExperience
    },
    {
      path: '/subscription-cancel-succeed',
      name: "SubscriptionCancelSucceed",
      component: SubscriptionCancelSucceed
    },
    {
      path: "/demo",
      name: "DemoPage",
      component: DemoPage
    },
    {
      path: "/demo-for-contentbrief",
      name: "DemoContentBriefPage",
      component: DemoPage
    },
    {
      path: "/api-doc",
      name: "APIDoc",
      component: APIDoc
    },
    {
      path: "/usage-stats",
      name: "UsageStatsPage",
      component: UsageStatsPage
    },
    {
      path: "/ext-checklist",
      name: "ExtChecklist",
      component: ExtChecklist
    },
    {
      path: "/openai-test",
      name: "OpenAITest",
      component: OpenAITest
    },
    {
      path: "/unsubscribe-watchdog",
      name: "UnsubscribeWatchdog",
      component: UnsubscribeWatchdog
    },
    {
      path: "/workstation",
      name: "ProjectLevelWorkStation",
      component: ProjectLevelWorkStation
    },
    {
      path: "/workstation/:siteId/pages/",
      name: "PageLevelWorkStation",
      component: PageLevelWorkStation
    },
    {
      path: "/workstation/:siteId/pages/:pageId/",
      name: "ProgressNotes",
      component: ProgressNotes
      
    },
    {
      path: '/play-with-ai',
      name: 'PlaywithOpenai',
      component: PlaywithOpenai,
    }
    
  ]
});

const demoAllowedRoutes = [
  "Sites",
  "ListTools",
  "Pages",
  "NewPage",
  "PageRuns",
  "PageRun",
  "RerunAnalysis",
  "FullPageSourceEditor",
];

router.beforeEach((to, from, next) => {
  if (
    [
      "Home",
      "Login",
      "PopDemo",
      "StandardRegister",
      "DynamicRegister",
      "RegisterNoCard",
      "RegisterCheckEmail",
      "EmailChangeResult",
      "UpdatePassword",
      "RegisterService",
      "ForgotPassword",
      "DemoPage",
      "DemoContentBriefPage",
      "UsageStatsPage",
      "ExtChecklist",
      "FullPageSourceEditor",
      "APIDoc",
      "OpenAITest",
      "UnsubscribeWatchdog",
      "UIPreDesign",
      "PlaywithOpenai",
    ].indexOf(to.name) > -1
  ) {
    next();
  } else if (!store.getters.isAuthenticated) {
    store.commit("clearStore");
    console.log("goto login page blabla");
    let redirectPath = window.location.hash;
    if (redirectPath.startsWith("#")) {
      redirectPath = redirectPath.substring(1);
    } else {
      redirectPath = "";
    }
    next({
      path: "/login",
      query: {
        redirect: redirectPath
      }
    });
  } else {
    if (_.isEmpty(store.state.user)) {
      store.commit("showLoading");
      store
        .dispatch("loadUser")
        .then(response => {
          store.commit("setUser", response.data.user);
          store.commit("hideLoading");
          next();
        })
        .catch(() => {
          store.commit("clearStore");
          next("/login");
        });
    } else {
      if (!store.getters.isDemoUser || demoAllowedRoutes.indexOf(to.name) > -1) {
        return next();
      } else next("/");
    }
  }
});

export default router;

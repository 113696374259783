<template>
<div class="predesign-root">
  <div class="container pt-5">
    <template v-if="['all', 'keyword-research'].includes(currentTab)">
      <keyword-research></keyword-research>
    </template>
    <template v-if="['all', 'supporting-keyword'].includes(currentTab)">
      <supporting-keyword-research></supporting-keyword-research>
    </template>
    <template v-if="['all', 'keyword-wizard'].includes(currentTab)">
      <keyword-wizard></keyword-wizard>
    </template>
    
  </div>
</div>
</template>

<script>
import KeywordResearch from "@/components/Campaigns/keyword-research/KeywordResearch.vue"
import SupportingKeywordResearch from "@/components/Campaigns/supporting-keyword-research/SupportingKeywordResearch.vue"
import KeywordWizard from "@/components/Campaigns/keyword-wizard/KeywordWizard.vue"
export default {
  components: {
    KeywordResearch,
    SupportingKeywordResearch,
    KeywordWizard,
  },
  data () {
    return {
      currentTab: '',
    }
  },
  mounted () {
    this.currentTab = this.$route.query.type || 'all'
  }
}
</script>

<style scoped lang="scss">
.predesign-root {
  /* background: var(--v-mainBgColor-base); */
  min-height: 100vh;
}

</style>
export const TARGET_MIN_PERCENTAGE = {
  'title': 0.5,
  'pageTitle': 0.5,
  'subHeadings': 0.55,
  'p': 0.65
}

export const SCHEMA_TYPE_MAPPER = {
  'attorney': 'Attorney',
  'autorepair': 'AutoRepair',
  'brand': 'Brand',
  'breadcrumblist': 'BreadcrumbList',
  'collectionpage': 'CollectionPage',
  'computerstore': 'ComputerStore',
  'contactpoint': 'ContactPoint',
  'educationalorganization': 'EducationalOrganization',
  'event': 'Event',
  'faqpage': 'FAQPage',
  'faq': 'FAQPage',
  'homeandconstructionbusiness': 'HomeAndConstructionBusiness',
  'imageobject': 'ImageObject',
  'legalservice': 'LegalService',
  'listitem': 'ListItem',
  'localbusiness': 'LocalBusiness',
  'medicalbusiness': 'MedicalBusiness',
  'offercatalog': 'OfferCatalog',
  'offershippingdetails': 'ShippingDeliveryTime',
  'physician': 'Physician',
  'postaladdress': 'PostalAddress',
  'qapage': 'QAPage',
  'review': 'Review',
  'searchaction': 'SearchAction',
  'selfstorage': 'SelfStorage',
  'singlefamilyresidence': 'SingleFamilyResidence',
  'sitenavigationelement': 'SiteNavigationElement',
  'softwareapplication': 'SoftwareApplication',
  'shippingdeliverytime': 'ShippingDeliveryTime',
  'videoobject': 'VideoObject',
  'watchaction': 'WatchAction',
  'webpage': 'WebPage',
  'website': 'WebSite',
}

export const KEYWORDS_INSIGHT_CONFIG = {
  // 0. Shared state
  campaign: null,  // used in "Keyword Research" and "Keyword Wizard > KEYWORD_ONLY_EXIST"

  // 1. Keyword Research state
  keywords: [],
  researchHistory: [],
  selectedResearchReportId: null,
  isCalculating: false,
  researchReport: null,
  progressObj: {},
  taskId: null,
  taskInterval: null,
  selectedLocation: '',
  selectedLanguage: '',

  // 2. Keyword Wizard shared stated
  selectedScenario: '',

  // 2.1. Keyword Wizard > KEYWORD_ONLY_EXIST scenario state
  wizardLocation2: '',
  wizardLanguage2: '',
  wizardTopics: [],
  wizardCompTexts: '',

  // 2.2. Keyword Wizard > SITE_URL_EXIST and SITE_ONLY_EXIST scenarios state (implemented together in keyword-wizard/page-voltage)
  wizardDomain: '',
  wizardPageUrls: [],
  wizardSelectedPageUrls: [],
  wizardLanguage: '',
  wizardLocation: '',
  wizardPeriod: '',
  wizardIsCountryEnabled: true,
  wizardIsBrandedEnabled: false,
  wizardBrandedText: '',
  wizardProgressObj: {},
  wizardInterval: null,
  wizardResults: [],
  wizardResultsHistoryMetadata: null,
  wizardIsCalculating: false,
  wizardMonthlySearchInterval: null,
  wizardLabelsTaskInterval: null,

  // 2.3. Keyword Wizard > PAGE_OPPORTUNITY scenario state
  wizardPODomain: '',
  wizardPOPageUrls: [],
  wizardPOSelectedPageUrls: [],
  wizardPOLanguage: '',
  wizardPOLocation: '',
  wizardPOPeriod: '',
  wizardPOIsCountryEnabled: true,
  wizardPOIsBrandedEnabled: false,
  wizardPOBrandedText: '',
  wizardPOProgressObj: {},
  wizardPOInterval: null,
  wizardPOResults: [],
  wizardPOResultsHistoryMetadata: null,
  wizardPOIsCalculating: false,
}

export const DEMO_SESSION_KEYS = {
  pageCreated: 'DEMO_PAGE_CREATED',
  runCreating: 'DEMO_PAGE_RUN_CREATING',
  runCreated: 'DEMO_PAGE_RUN_CREATED',
  runUrl: 'DEMO_PAGE_RUN_URL'
}

export const DEMO_PAGE_RUN_VALUES = {
  PAGE_URL:
    "blogs/adventure-together/dog-care-natural-home-remedies-for-fleas",
};

export const DEMO_PRICES = [
  {
    price: 34,
    title: "Basic",
    link: "/?_ga=2.204641753.899144478.1715754233-1030103954.1708966427#/register?product=basic&utm_source=undefined&utm_medium=null&affiliateCode=null",
    description: [
      "20 POP Credits per month to split between POP reports, EEAT, NLP, Watchdog and AI",
      "Unlimited domains",
      "POP AI Writer",
      "For those getting started",
    ],
  },
  {
    price: 47.5,
    title: "Premium",
    link: "/?_ga=2.28065653.899144478.1715754233-1030103954.1708966427#/register?product=premium&utm_source=undefined&utm_medium=null&affiliateCode=null",
    description: [
      "40 POP Credits per month to split between POP reports, EEAT, NLP, Watchdog and AI",
      "Unlimited domains",
      "POP AI Writer",
      "Best for a simple site",
    ],
  },
  {
    price: 61,
    title: "Unlimited",
    link: "/?_ga=2.28065653.899144478.1715754233-1030103954.1708966427#/register?product=unlimited&utm_source=undefined&utm_medium=null&affiliateCode=null",
    description: [
      "Unlimited POP reports and Watchdog reports per month*",
      "50 POP EEAT, NLP and AI credits",
      "Unlimited domains",
      "POP AI Writer",
      "Best for high traffic sites",
    ],
  },
]

export const ReportTypes = {
  AI_DRAFT: 1,
  HUMAN_DRAFT: 2,
  OUTLINE: 3
}
<template>
  <div class="supporting-keyword-root">
    <div>
      <div class="mt-10 location-lang-domain-area">
        <div>
          <div class="card-small-title">
            Select Location
          </div>
          <v-autocomplete outlined dense hide-details
            :items="availableLocations"
            item-text="location_name"
            item-value="location_code"
            :value="config.wizardLocation2"
            @input="value => setConfig('wizardLocation2', value)"
            color="grayColor"
            class="mt-5">
          </v-autocomplete>
        </div>
        <div>
          <div class="card-small-title">
            Select Language
          </div>
          <v-autocomplete outlined dense hide-details
            :items="targetLanguages"
            :value="config.wizardLanguage2"
            @input="value => setConfig('wizardLanguage2', value)"
            item-text="language_name"
            item-value="language_code"
            color="grayColor"
            class="mt-5">
          </v-autocomplete>
        </div>
        <div>
          <div class="card-small-title">
            Domain name
          </div>
          <v-text-field outlined dense hide-details
            class="mt-5"
            color="grayColor"
            readonly
            v-model="domain"
          />
        </div>
      </div>
      <div class="mt-10" style="max-width: 680px;">
        <div class="d-flex align-center gap10">
          <div class="card-small-title">
            Enter your target keywords or topics
          </div>
          <span class="input-count-info">{{ config?.wizardTopics?.length || 0 }} / 10</span>
          <div class="flex-grow-1"></div>
        </div>
        <div class="customized-multiselect1">
          <multiselect class="mt-5"
            v-model="topics"
            :options="[]"
            :multiple="true"
            :taggable="true"
            @tag="addTopicAction">
          </multiselect>
        </div>
        <div class="mainColor--text mt-3">
          If you have your own list of keywords, you can import it and we will cluster your list. Please note that the list can’t have more than 1000 keywords.
          <a class="link15Color--text text-decoration-underline">Import it here.</a>
        </div>
        <div class="mt-3 d-flex">
          <div v-if="selectedFile && selectedFile.name" class="selected-file">
            <span class="font-weight-bold">{{ selectedFile.name }}</span>
            <span class="material-icons" @click="resetFileSelected">cancel</span>
          </div>
        </div>
      </div>
      <div class="mt-10" style="max-width: 680px;">
        <div class="d-flex align-center gap10">
          <div class="card-small-title">
            Enter your competitor URLs
          </div>
          <span class="input-count-info">{{ competitorUrlsCountInputed }} / 20</span>
          <div class="flex-grow-1"></div>
          <!-- <v-btn outlined
            color="mainColor"
            class="text-none font-weight-bold">
            <span>Import from</span>
          </v-btn> -->
        </div>
        <div class="mt-3">
          <v-textarea
            outlined
            hide-details
            color="greenColor"
            :value="config.wizardCompTexts"
            @input="value => setConfig('wizardCompTexts', value)"
          />
        </div>
        <div class="mt-3 d-flex align-center gap10">
          <vue-toggles
            v-model="checkCompetitorsOnly"
            width="40" height="20"
            :checkedBg="$vuetify.theme.currentTheme['green7Color']"
            :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
          ></vue-toggles>
          <span class="mainColor--text">I want to analyze competitors only</span>
        </div>
      </div>
      <div class="mt-10 d-flex align-center gap10">
        <v-btn rounded
          color="profileAnchorColor"
          class="button-with-icon text-none whiteColor--text"
          :disabled="isCalculating || !canStartSearchKeywords"
          @click="startSearchKeywords">
          <span>Search keywords</span>
        </v-btn>
        <div v-if="isCalculating" class="flex-grow-1">
          <v-progress-linear rounded height="8"
            color="profileAnchorColor"
            :value="progressObj.value || 0">
          </v-progress-linear>
          <div class="mt-2 mainColor--text">
            <b-icon
              pack="fa"
              icon="spinner"
              custom-class="fa-spin">
            </b-icon>
            <span class="ml-1">{{ progressObj.msg }}</span>
          </div>
        </div>
      </div>
    </div>
  
    <div class="result-area mt-10">
      <template v-if="currentStep == STEPS.LOADING_KEYWORD && extractedKeywords.length > 0">
        <div class="white-common-box pa-5">
          <!-- cluster org chart -->
          <div class="d-flex align-center">
            <div class="general-info flex-grow-1">{{ finalKeywords.length }} keywords in {{ finalClusterNames.length }} topic clusters</div>
            <div class="d-flex gap10">
              <div class="keyword-filter-area" @click="() => { showKeywordIntentFilterPopup = true; }">
                <div>KEYWORD INTENT</div>
                <div class="filter-btn">
                  {{ keywordIntentsFilter.length }} filters applied
                
                  <div v-if="showKeywordIntentFilterPopup" v-click-outside="() => { showKeywordIntentFilterPopup = false; }"
                    class="filter-popup" style="position: absolute; right: 0; top: 50px; z-index: 12;">
                    <v-checkbox dense hide-details="auto"
                      v-for="k_intent in allKeywordIntents" :key="k_intent"
                      v-model="keywordIntentsFilter" :value="k_intent" :true-value="[]" :label="k_intent">
                    </v-checkbox>
                  </div>
                </div>
              </div>
              <div class="keyword-filter-area" @click="() => { showKeywordTypeFilterPopup = true; }">
                <div>KEYWORD TYPE</div>
                <div class="filter-btn">
                  {{ keywordTypesFilter.length }} filters applied
                
                  <div v-if="showKeywordTypeFilterPopup" v-click-outside="() => { showKeywordTypeFilterPopup = false; }"
                    class="filter-popup" style="position: absolute; right: 0; top: 50px; z-index: 12;">
                    <v-checkbox dense hide-details="auto"
                      v-for="kType in keywordTypeOptions" :key="kType"
                      v-model="keywordTypesFilter" :value="kType" :true-value="[]" :label="kType">
                    </v-checkbox>
                    <div class="d-flex align-center mt-5">
                      <vue-toggles
                        :value="includeQuestionKeyword"
                        width="40" height="20"
                        :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                        :uncheckedBg="$vuetify.theme.currentTheme['gray35Color']"
                        @click="() => {
                          includeQuestionKeyword = !includeQuestionKeyword
                        }">
                      </vue-toggles>
                      <div class="ml-2 mainColor--text">
                        Question Keywords
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="keyword-filter-area" @click="() => { showIncludeExcludeFilterPopup = true; }">
                <div>FILTERS</div>
                <div class="filter-btn">
                  {{ filterTermFilerCount }} filters applied
                
                  <div v-if="showIncludeExcludeFilterPopup" v-click-outside="() => { showIncludeExcludeFilterPopup = false; }"
                    class="filter-popup" style="position: absolute; right: 0; top: 50px; z-index: 12;">
                    <div class="d-flex align-center">
                      <div class="mainColor--text font-weight-bold flex-grow-1">Include words</div>
                      <div class="clear-btn" :class="{ 'disabled': includeFilterTermText == '' }" @click="() => { includeFilterTermText = '' }">
                        <i class="material-icons">close</i>
                        <span>Clear</span>
                      </div>
                    </div>
                    <v-textarea outlined rows="4" v-model="includeFilterTermText"></v-textarea>
                    <div class="d-flex align-center">
                      <div class="mainColor--text font-weight-bold flex-grow-1">Exclude words</div>
                      <div class="clear-btn" :class="{ 'disabled': excludeFilterTermText == '' }" @click="() => { excludeFilterTermText = '' }">
                        <i class="material-icons">close</i>
                        <span>Clear</span>
                      </div>
                    </div>
                    <v-textarea outlined hide-details rows="4" v-model="excludeFilterTermText"></v-textarea>
                  </div>
                </div>
              </div>
              <v-btn outlined color="mainColor"
                class="button-with-icon text-none"
                @click="downloadClustersAndKeywords">
                Download
              </v-btn>
            </div>
          </div>
          <clusters-chart
            :topic="topicsUsedInExtrackingKeyword.join('\n\n')"
            :clusterNames="clusterNames"
            :showClustersCount="showClustersCount"
            @showMoreClusters="() => {
              showMoreClusters()
            }"
            @showClusterDetail="(cName) => showClusterDetail(cName)"
          />
        </div>
        <div class="mt-10">
          <div class="d-flex align-center">
            <div class="flex-grow-1 card-small-title">
              Clusters list
            </div>
            <div>
              <v-tooltip top color="transparent" :disabled="!(finalKeywords.length === 0 || finalKeywords.length > 100)">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-btn rounded
                      color="profileAnchorColor"
                      class="button-with-icon text-none whiteColor--text"
                      :disabled="finalKeywords.length === 0 || finalKeywords.length > 100"
                      @click="() => {
                        showGetKeywordDataCostWarnModal = true;
                      }">
                      <i class="material-icons-outlined">query_stats</i>
                      <span>Get Keyword Data</span>
                    </v-btn>
                  </span>
                </template>
                <div class="white-bg-tooltip small-tooltip">
                  {{
                    finalKeywords.length === 0
                    ?'Please select at least one cluster first'
                    :finalKeywords.length > 100
                      ?'Number of selected keywords must be less than 100. Please, deselect some keywords or clusters.'
                      :''
                  }}
                </div>
              </v-tooltip>
            </div>
          </div>
          <v-card v-for="clusterName in clusterNamesToDisp" :id="`clusterId_${clusterNameToId(clusterName)}`" :key="clusterName" class="white-common-box pa-5 mt-3 cluster-box">
            <div class="cluster-header">
              <i class="material-icons expand-icon" v-if="!expandedClusterNames.includes(clusterName)"
                @click="() => { expandedClusterNames.push(clusterName) }">keyboard_arrow_right</i>
              <i class="material-icons expand-icon" v-else
                @click="() => { expandedClusterNames = expandedClusterNames.filter(item => item != clusterName) }">keyboard_arrow_down</i>
              <v-checkbox hide-details="auto" class="mt-0 pt-0" v-model="selectedClusterNames" :value="clusterName"></v-checkbox>
              <span class="cluster-name">{{ clusterName }}</span>
              <span class="keywords-count">
                {{ keywordsByCluster[clusterName]?.length }}
                {{ keywordsByCluster[clusterName]?.length > 1 ? 'keywords' : 'keyword' }}
              </span>
              <div class="flex-grow-1"></div>
              <span class="material-icons remove-cluster-icon" @click="() => {
                extractedKeywords = extractedKeywords.filter(arg => arg.clusterName != clusterName)
              }">close</span>
            </div>
            <div class="cluster-body mt-5" v-if="expandedClusterNames.includes(clusterName)">
              <div class="keywords-wrapper">
                <div v-for="item in keywordsByCluster[clusterName]" :key="item.keyword">
                  <span class="material-icons close" @click="() => {
                    extractedKeywords = extractedKeywords.filter(arg => arg.keyword != item.keyword)
                  }">close</span>
                  <span class="font-weight-bold">{{ item.keyword }}</span>
                  <span v-if="item.keyword_intent">[ {{ item.keyword_intent }} ]</span>
                  <span>( {{ item.source }} )</span>
                </div>
              </div>
            </div>
          </v-card>
          <!-- {{ keywordsByCluster }} -->
          <div class="text-center mt-3">
            <v-btn outlined rounded color="mainColor"
              v-if="showClustersCount < clusterNames.length"
              @click="() => {
                showClustersCount += 10;
              }"
              class="text-none">
              <span>Load more</span>
            </v-btn>
          </div>
        </div>
      </template>
      <div class="mt-10" v-if="researchReport && currentStep != STEPS.LOADING_KEYWORD && !isCalculating">
        <keyword-research-result
          :report="researchReport"
          reportType="TopLevelSupportKeywordResearch"
          :showSiloExtraActions="false"
          :researchHistory="researchHistory"
          @onChangeResearchId="(id) => { selectedResearchReportId = id }"
          @onKeywordLabelUpdateSuccess="onKeywordLabelUpdateSuccess"
          @onAIResultReady="() => loadResearchReport()"
          @removeKeywordFromReport="removeKeywordFromReport">
        </keyword-research-result>
      </div>
    </div>
  
  
    <div class="modal" :class="{'is-active': showGetKeywordDataCostWarnModal}">
      <div class="modal-background" @click.stop="showGetKeywordDataCostWarnModal = false"></div>
      <div class="modal-content">
        <div class="box modal-container">
          <div class="modal-content-root">
            <p class="title-font is-size-4 mainColor--text">Get cluster keyword data</p>
            <div class="mainColor--text" style="font-size: 1rem; line-height: 150%;">
              You have selected {{ finalClusterNames.length }} clusters with {{ finalKeywords.length }} keywords.<br />
              POP will fetch all the keyword data for the selected clusters. <br />
              <!-- This will cost XX POP Credits. -->
            </div>
            <div class="d-flex justify-end gap10 mt-5">
              <v-btn rounded outlined color="mainColor"
                class="text-none"
                @click.stop="() => {
                  showGetKeywordDataCostWarnModal = false;
                }">
                <span>Cancel</span>
              </v-btn>
              <v-btn rounded color="profileAnchorColor"
                class="whiteColor--text text-none"
                @click.stop="() => createSupportKeywordResearchAction()">
                <span>Get data</span>
              </v-btn>
              
            </div>
          </div>
        </div>
        <button
          class="custom-modal-close-btn"
          aria-label="close"
          @click="() => (showGetKeywordDataCostWarnModal = false)">
          <i class="material-icons">close</i>
        </button>
      </div>
    </div>
  
    
  </div>
  </template>
  
  <script>
  import moment from 'moment';
  import Multiselect from 'vue-multiselect'
  
  import dataForSeoLocations from '@/consts/dataforseo_locations.json';
  import languages from '@/consts/dataforseo_languages.json';
  import { getHostName, setIntervalAndRunImmediately } from '@/utils'
  import ClustersChart from '../../supporting-keyword-research/ClustersChart.vue';
  import { getSplittedKeywords, getValidKeywords } from '@/components/Campaigns/CommonFunctions.js'
  import KeywordResearchResult from '@/components/Campaigns/KeywordResearchResult.vue';
  import { mixin as export_mixin } from '@/components/export_mixin'
  import * as d3 from 'd3';
  
  const ALLOWED_KEYWORD_COUNT = 10
  const KeywordTypeOptionsFull = [
    "Related Keywords",
    "Keyword Suggestions",
    "Competitor's Keyword",
    "People Also Ask",
    "Google Suggest",
    "AI Generated",
  ]
  const question_prefixs = 'who|what|where|when|why|how|was|were|did|do|does|is|are'.split('|')
  
  export default {
    mixins: [ export_mixin ],
    components:  {
      Multiselect,
      ClustersChart,
      KeywordResearchResult,
    },
    data () {
      return {
  
        selectedLocation: '',
        selectedLanguage: '',
        domain: '',
        topics: [],
        competitorUrls: '',
        checkOnlyCompetitors: false,
        selectedFile: null,
        // selectedFile: {
        //   name: 'test.xlsx'
        // },
        checkCompetitorsOnly: false,
        STEPS: {
          'LOADING_KEYWORD': 'LOADING_KEYWORD',
          'LOADING_RESULT_FOR_KEYWORD': 'LOADING_RESULT_FOR_KEYWORD',
        },
        currentStep: '',
        isCalculating: false,
        keywordExtractTaskId: '',
        taskInterval: null,
        progressObj: {},
  
        topicsUsedInExtrackingKeyword: [],
        extractedKeywords: [],
        showClustersCount: 10,
        expandedClusterNames: [],
        selectedClusterNames: [],
  
        KeywordTypeOptionsFull: KeywordTypeOptionsFull,
        keywordTypeOptions: KeywordTypeOptionsFull,
        keywordTypesFilter: [],
        showKeywordTypeFilterPopup: false,
        includeQuestionKeyword: false,

        showKeywordIntentFilterPopup: false,
        keywordIntentsFilter: [],
  
        includeFilterTermText: '',
        includeAllFilter: false,
        excludeFilterTermText: '',
        excludeAllFilter: false,
        showIncludeExcludeFilterPopup: false,
  
        showGetKeywordDataCostWarnModal: false,
  
        campaign: null,
        researchHistory: [],
        selectedResearchReportId: null,
        researchReport: null,
  
        researchTaskId: '',
  
  
        
      }
    },
    computed: {
      config() {
        return this.$store.getters.insightKeywordsConfig;
      },
      campaignId () {
        return parseInt(this.$route.params.campaignId || 0)
      },
      competitorUrlsCountInputed () {
        return this.config.wizardCompTexts.split('\n').filter(item => item.trim()).length
      },
      competitorsListInputed() {
        return this.config.wizardCompTexts.split('\n').filter(item => item.trim())
      },
      // googleSearchLocations() {
      //   if (this.$store.getters.getGoogleLocations) {
      //     return this.$store.getters.getGoogleLocations.filter(item => !item.includes('Extension'))
      //   }
      //   return []
      // },
      // targetLanguages () {
      //   return this.$store.state.targetLanguages.map(item => {
      //     return {
      //       text: capitalize(item),
      //       value: item
      //     }
      //   })
      // },
      availableLocations () {
        return dataForSeoLocations.locations;
      },
      targetLanguages () {
        return languages;
      },
      canStartSearchKeywords () {
        return (this.config?.wizardTopics?.length || 0) > 0 || ( this.checkOnlyCompetitors && this.competitorUrlsCountInputed > 0 )
      },
      clusterNames () {
        let rlt = [];
        this.filteredKeywords.map(item => {
          if (!rlt.includes(item.clusterName)) {
            rlt.push(item.clusterName);
          }
        })
        rlt.sort();
        return rlt;
      },
      clusterNamesToDisp () {
        return this.clusterNames.slice(0, this.showClustersCount)
      },
      keywordsByCluster () {
        let rlt = {}
        for ( const item of this.filteredKeywords) {
          if (!(item.clusterName in rlt)) {
            rlt[item.clusterName] = []
          }
          rlt[item.clusterName].push({
            ...item
          })
        }
        return rlt
      },
      filterTermFilerCount () {
        let includeFilters = getSplittedKeywords(this.includeFilterTermText)
        let exlcudeFilters = getSplittedKeywords(this.excludeFilterTermText)
        return includeFilters.length + exlcudeFilters.length;
      },
      filteredKeywords () {
        return this.extractedKeywords.filter(item => { // Apply include filters
          const filterTerms = this.includeFilterTermText.split(',').map(term => term.trim()).filter(term => term !== '');
          if (filterTerms.length == 0) return true;
  
          if (!this.includeAllFilter) {
            for (const term of filterTerms) {
              if (item['keyword'].includes(term)) {
                return true;
              }
            }
            return false;
          } else {
            for (const term of filterTerms) {
              if (!item['keyword'].includes(term)) {
                return false;
              }
            }
            return true;
          }
        })
        .filter(item => {  // Apply exclude filters
          const filterTerms = this.excludeFilterTermText.split(',').map(term => term.trim()).filter(term => term !== '');
          if (filterTerms.length == 0) return true;
  
          if (!this.excludeAllFilter) {
            for (const term of filterTerms) {
              if (item['keyword'].includes(term)) {
                return false;
              }
            }
            return true;
          } else {
            for (const term of filterTerms) {
              if (!item['keyword'].includes(term)) {
                return true;
              }
            }
            return false;
          }
        })
        .filter(item => {
          if (this.includeQuestionKeyword) {
            for (let index = 0; index < question_prefixs.length; index++) {
              const element = question_prefixs[index];
              if (item['keyword'].startsWith(element)) {
                return true
              }
            }
            return false
          } else {
            return true
          }
        })
        .filter(item => { // Keyword Type filter
          if (this.keywordTypesFilter.length > 0) {
            for (let index = 0; index < this.keywordTypesFilter.length; index++) {
              const keywordType_ = this.keywordTypesFilter[index];
              if (item['source'].includes(keywordType_)) {
                return true
              }
            }
            return false
          }
          return true
        })
        .filter(item => { // keyword intent filter
          if (this.keywordIntentsFilter.length > 0) {
            return this.keywordIntentsFilter.includes(item?.['keyword_intent'])
          }
          return true;
        })
      },
      finalKeywords () {
        return this.filteredKeywords.filter(item => {
          return this.selectedClusterNames.includes(item.clusterName)
        })
      },
      finalClusterNames () {
        let rlt = [];
        this.finalKeywords.map(item => {
          if (!rlt.includes(item.clusterName)) {
            rlt.push(item.clusterName);
          }
        })
        return rlt;
      },
      allKeywordIntents () {
        let rlt = [];
        this.extractedKeywords.map(item => {
          if (item.keyword_intent && !rlt.includes(item.keyword_intent)) {
            rlt.push(item.keyword_intent);
          }
        })
        return rlt;
      },
  
    },
    watch: {
      topics: function (newVal) {
        if (this.config.wizardTopics !== newVal) {
          this.setConfig("wizardTopics", newVal)
        }
      },
      campaignId: function(newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          this.getCampaignById()
          .then(() => this.loadSupportKeywordResearchHistoryForCampaign())
        }
      },
      selectedResearchReportId: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal && newVal !== this?.researchReport?.id) {
          this.loadResearchReport()
        }
  
      },
      keywordExtractTaskId: function(newVal, oldVal) {
        if (!newVal) {
          this.clearCalculateStatus();
          return;
        } else {
          if (newVal != oldVal) {
            this.createKeywordExtractTaskMonitor();
          }
        }
      },
      researchTaskId: function (newVal, oldVal) {
        if(!newVal) {
          this.clearCalculateStatus();
          return;
        } else {
          if (newVal != oldVal) {
            this.createResearchTaskMonitor();
          }
        }
      },
      checkOnlyCompetitors (newVal, oldVal) {
        if (this.competitorUrlsCountInputed > 0 || newVal) {
          this.keywordTypeOptions = KeywordTypeOptionsFull
        } else {
          this.keywordTypeOptions = KeywordTypeOptionsFull.filter(item => item != "Competitor's Keyword")
        }
      },
      isCalculating (newVal, oldVal) {
        if (newVal) {
          this.progressObj = {
            value: 0,
            msg: ''
          }
        }
      }
    },
    methods: {
      downloadClustersAndKeywords () {
        let data = []
        data.push(['Cluster', 'Keyword'])
        this.finalKeywords.map(item => {
          data.push([item.clusterName, item.keyword])
        })
        let csvData = data.map(item => {
          return item.join(',')
        }).join('\n')
        const timestamp = moment().format('YYYY_M_D_h_mm_ss')
        this.exportToCSV(csvData, `clusters_and_keywords_${timestamp}.csv`)
      },
      clusterNameToId (name) {
        return name.replaceAll(' ', '_')
      },
      showClusterDetail (cName) {
        if (!this.expandedClusterNames.includes(cName)) {
          this.expandedClusterNames.push(cName)
        }
        this.$nextTick(() => {
          document.getElementById(`clusterId_${this.clusterNameToId(cName)}`).scrollIntoView({
            behavior: 'smooth'
          });
        })
      },
      showMoreClusters () {
        this.showClustersCount += 10;
        this.$nextTick(() => {
          setTimeout(() => {
            let divObj = document.getElementById("mainBodyScrollArea");
            divObj.scrollTop = divObj.scrollHeight;
          }, 500);
        })
      },
      setConfig(key, value) {
        this.$store.commit("setInsightKeywordsConfig", { key, value });
      },
      // clusterChartPaddingStyle (idx) {
      //   let gap = 0;
      //   let countInOneSide = this.clusterNames / 2
      //   if ( idx % 2 == 0) { // left side
      //     let sideIndex = Math.int(idx / 2);
      //   } else {
  
      //   }
      // },
      addTopicAction (newTopic) {
        if (newTopic) {
          const topics = getSplittedKeywords(newTopic)
          const result = [...this.config.wizardTopics, ...topics]
          this.topics = result.filter((k, pos) => result.indexOf(k) == pos)
        }
      },
      loadResearchReport () {
        this.$store.dispatch('topLevel_loadSupportKeywordResearchData', {
          researchId: this.selectedResearchReportId,
        })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.researchReport = response.data.research;
            this.setConfig("wizardLanguage2", this.researchReport.languageCode)
            this.setConfig("wizardLocation2", parseInt(this.researchReport.locationCode || 0))
            this.topics = this.researchReport.targetTopics
            this.topicsUsedInExtrackingKeyword = this.researchReport.targetTopics
            this.setConfig("wizardCompTexts", this.researchReport.competitorUrls.join('\n'))

            if (this.researchReport.currentStep == 0) {
              this.currentStep = this.STEPS.LOADING_KEYWORD
              if ((this.researchReport?.allClusteredKeywords?.length || 0) > 0) {
                this.setExtractedKeywordsAction(this.researchReport.allClusteredKeywords)
              } else if(this.researchReport.taskId) {
                this.isCalculating = true;
                this.keywordExtractTaskId = this.researchReport.taskId
              }
            } else if (this.researchReport.currentStep == 1) {
              if (this.researchReport.taskId) {
                this.isCalculating = true;
                this.researchTaskId = this.researchReport.taskId
              }
            }
          }
        })
      },
      getCampaignById () {
        return this.$store.dispatch('getCampaignById', this.campaignId)
          .then(response => {
            if (response.data && response.data.status == 'SUCCESS') {
              const respData = response.data.campaign
              this.setConfig("campaign", respData)
              
              this.domain = getHostName(respData.targetUrl)
              this.setConfig("wizardLanguage2", respData.languageCode || 'en')
              if (respData.locationCode) {
                this.setConfig("wizardLocation2", parseInt(respData.locationCode || 0))
              }
              return Promise.resolve()
            }
            return Promise.reject()
          })
      },
      createKeywordExtractTaskMonitor () {
        if (this.taskInterval) {
          try {
            clearInterval(this.taskInterval)
          } catch (e) {
            console.log(e)
          }
        }
        this.taskInterval = setIntervalAndRunImmediately(() => {
          this.$store.dispatch('getTaskStatus', this.keywordExtractTaskId)
          .then(response => {
            if (response.data) {
              if (response.data.status === 'PENDING') {
                // ignore
                // Celery's PENDING state may as well mean that the task is simply unknwon
                // (i.e. could be a really pending task, but could also be an invalid/non-existing task id or could be a completed but forgotten task)
              } else if (response.data.status === "PROGRESS") {
                this.isCalculating = true;
                this.progressObj = {
                  value: response.data.value,
                  msg: `${response.data?.msg}`
                };
              } else if (response.data.status === "SUCCESS") {
                this.clearCalculateStatus();
                this.setExtractedKeywordsAction(response.data.results.keywords || []);
                this.$notify({
                  group: 'info', type: 'success',
                  text: 'Completed keyword research!!'
                })
              } else if (response.data.status === "FAILURE") {
                this.$notify({
                  group: 'info', type: 'error',
                  text: 'Failed!!'
                })
                this.clearCalculateStatus();
              }
            }
          })
        }, 5000)
      },
      createResearchTaskMonitor () {
        if (this.taskInterval) {
          try {
            clearInterval(this.taskInterval)
          } catch (e) {
            console.log(e)
          }
        }
        this.taskInterval = setIntervalAndRunImmediately(() => {
          this.$store.dispatch('getTaskStatus', this.researchTaskId)
          .then(response => {
            if (response.data) {
              if (response.data.status === 'PENDING') {
                // ignore
                // Celery's PENDING state may as well mean that the task is simply unknwon
                // (i.e. could be a really pending task, but could also be an invalid/non-existing task id or could be a completed but forgotten task)
              } else if (response.data.status === "PROGRESS") {
                this.isCalculating = true;
                this.progressObj = {
                  value: response.data.value,
                  msg: `${response.data?.msg}`
                };
              } else if (response.data.status === "SUCCESS") {
                this.clearCalculateStatus();
                this.loadResearchReport()
                this.$notify({
                  group: 'info', type: 'success',
                  text: 'Completed keywords analyzing!!'
                })
  
              } else if (response.data.status === "FAILURE") {
                this.$notify({
                  group: 'info', type: 'error',
                  text: 'Failed!!'
                })
                this.clearCalculateStatus();
              }
            }
          })
        }, 5000)
      },
      loadSupportKeywordResearchHistoryForCampaign( scrollBottom = false ) {
        return this.$store.dispatch('topLevel_getSupportKeywordResearchHistoryForCampaign', {
          campaignId: this.campaignId
        })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.researchHistory = response.data.researches
            if (this.researchHistory.length > 0 && (!this.researchReport || this.selectedResearchReportId < this.researchHistory[0].id )) {
              this.selectedResearchReportId = this.researchHistory[0].id
            }
            if (scrollBottom) {
              setTimeout(() => {
                let divObj = document.getElementById("mainBodyScrollArea");
                divObj.scrollTop = divObj.scrollHeight;
              }, 500);
            }
            return Promise.resolve()
          }
          return Promise.reject()
        })
      },
      
      resetFileSelected () {
        this.selectedFile = null;
      },
      startSearchKeywords () {
        if (this.config.wizardTopics.length > ALLOWED_KEYWORD_COUNT) {
          return this.$notify({
            group: 'info', type: 'error',
            text: `${ ALLOWED_KEYWORD_COUNT } keywords are allowed at max`
          })
        }
        this.extractedKeywords = []
  
        const topics = this.config.wizardTopics.slice(0, 10)
        this.topicsUsedInExtrackingKeyword = topics
          
        const formData = {
          campaignId: this.config.campaign?.id || 0,
          topics,
          domain: this.domain,
          locationCode: this.config.wizardLocation2,
          languageCode: this.config.wizardLanguage2,
          checkOnlyCompetitors: this.checkOnlyCompetitors,
          competitors: this.competitorsListInputed,
          minWordsLimit: 1,
          maxWordsLimit: 100,
        }

        this.currentStep = this.STEPS.LOADING_KEYWORD
        this.isCalculating = true;
        this.progressObj = {
          value: 0,
          msg: ''
        }
        this.$store.dispatch('topLevel_getKeywordsFromTopic', formData)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.keywordExtractTaskId = response.data.taskId
            this.researchReport = response.data.report
            this.selectedResearchReportId = response.data.report.id
            this.$notify({
              group: 'info', type: 'success',
              text: 'Keyword research task is started!'
            })
          } else {
            this.isCalculating = false;
            this.$notify({
              group: 'info', type: 'error',
              text: 'Failed to create keyword research task'
            })
          }
        })
  
  
      },
      setExtractedKeywordsAction (arg_extractedKeywords) {
        this.extractedKeywords = arg_extractedKeywords
        let clusterNames = [];
        this.extractedKeywords.map(item => {
          if (!clusterNames.includes(item.clusterName)) {
            clusterNames.push(item.clusterName);
          }
        })
        this.selectedClusterNames = clusterNames;
      },
      clearCalculateStatus () {
        this.keywordExtractTaskId = '';
        this.researchTaskId = '';
        if (this.taskInterval) {
          clearInterval(this.taskInterval);
          this.taskInterval = null;
        }
        this.isCalculating = false;
      },
      createSupportKeywordResearchAction () {
        this.showGetKeywordDataCostWarnModal = false;
  
        const keywords = getValidKeywords(this.finalKeywords.map(f => f.keyword))
        const postData = {
          campaignId: this.campaignId,
          reportId: this.researchReport ? this.researchReport.id : 0,
          keywords,
          locationCode: this.config.wizardLocation2,
          languageCode: this.config.wizardLanguage2,
          domain: this.domain,
          targetTopics: this.topicsUsedInExtrackingKeyword,
          competitorUrls: this.competitorsListInputed
        }

        this.currentStep = this.STEPS.LOADING_RESULT_FOR_KEYWORD
        this.isCalculating = true;
        this.progressObj = {
          value: 0,
          msg: ''
        }
        this.$store.dispatch('topLevel_createSupportKeywordResearch', postData)
        .then(response => {
          if (response.data) {
            if (response.data.status == 'SUCCESS') {
              this.researchTaskId = response.data.taskId;
            } else if (response.data.status == 'FAILURE') {
              this.$notify({
                group: 'info', type: 'error',
                text: 'Failed to analalyzing keyword research data'
              })
              this.isCalculating = false;
              this.researchTaskId = null;
            }
          }
        })
      },
      removeKeywordFromReport (item) {
        let data = {
          keyword: item.keyword,
          researchId: this.researchReport.id
        }
        this.$store.commit('showLoading')
        this.$store.dispatch('topLevel_removeKeywordFromSupportKeywordResearch', data)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.loadResearchReport()
            this.$notify({
              group: 'info', type: 'success',
              text: 'Successfully removed the keyword'
            })
          } else {
            this.$notify({
              group: 'info', type: 'error',
              text: 'Failed to remove keyword.'
            })
          }
        })
        .finally(() => {
          this.$store.commit('hideLoading');
        })
  
      },
      d3TestChart () {
        let data = {
  
        }
        const root = d3.hierarchy(data);
      },
      onKeywordLabelUpdateSuccess(keyword, label) {
        this.researchReport = {
          ...this.researchReport,
          result: this.researchReport.result.map((row) => {
            if (label === 'T' && row.isTopLevelKeyword) {
              return {
                ...row,
                isTopLevelKeyword: false
              }
            }
            if (row.keyword === keyword) {
              return {
                ...row,
                isTopLevelKeyword: label === 'T',
                isSupportingKeyword: label === 'S',
              }
            }
            return row
          })
        }
      }
    },
  
    mounted () {
      Promise.resolve()
      .then(() => {
        if (!this.config.campaign) {
          return this.getCampaignById()
        }
        this.domain = getHostName(this.config.campaign.targetUrl)
        const lang = this.config.campaign.languageCode || 'en'
        const loc = parseInt(this.config.campaign.locationCode || 0)
        if (lang && !this.config.wizardLanguage2) {
          this.setConfig("wizardLanguage2", lang)
        }
        if (loc && !this.config.wizardLocation2) {
          this.setConfig("wizardLocation2", loc)
        }
        if (this.config?.wizardTopics?.length) {
          this.topics = this.config.wizardTopics
        }
        return Promise.resolve()
      })
      .then(() => {
        return this.loadSupportKeywordResearchHistoryForCampaign()
      })
    }
  }
  </script>
  
  
  
  <style lang="scss" scoped>
  @import "../../keyword-research.scss";
  
  .general-info {
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--v-mainColor-base);
  }
  .cluster-chart {
    position: relative;
    min-height: 300px;
  
    .clusters-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 600px;
      row-gap: 5px;
      & > div {
        color: var(--v-mainColor-base);
      }
    }
    .cluster-name-in-chart {
      cursor: pointer;
    }
  
    .topics-inputed {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 20px 30px;
      border: 1px solid var(--v-grayColor-base);
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      & > div {
        text-align: center;
        font-weight: 700;
        font-size: 1.5rem;
        color: var(--v-mainColor-base);
      }
    }
  }
  
  
  .cluster-box {
    .cluster-header {
      display: flex;
      align-items: center;
      gap: 15px;
  
      .expand-icon {
        cursor: pointer;
      }
      .cluster-name {
        font-weight: 700;
        font-size: 1.1rem;
        color: var(--v-mainColor-base);
      }
      .keywords-count {
        padding: 5px 10px;
        border-radius: 5px;
        background: var(--v-lightYellowColor-base);
        color: var(--v-profileAnchorColor-base);
        font-weight: 700;
      }
    }
    .cluster-body {
      .keywords-wrapper {
        & > div {
          margin-top: 10px;
          background: var(--v-gray7Color-base);
          color: var(--v-mainColor-base);
          padding: 5px 10px;
          border-radius: 50vh;
          display: flex;
          align-items: center;
          gap: 5px;
          span.close {
            color: var(--v-profileAnchorColor-base);
            cursor: pointer;
          }
        }
      }
    }
  }
  .remove-cluster-icon {
    color: var(--v-profileAnchorColor-base);
    cursor: pointer;
  }
  
  </style>
<template>
  <section class="section">
    <div class="px-2">
      <div class="columns is-desktop">
        <div class="column is-2">
          <div class="field">
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="filterField">
                  <option value="email">Email</option>
                  <option value="paypalEmail">PayPal Email</option>
                  <option value="stripeId">Stripe customer id</option>
                  <option value="accountType">Account Type</option>
                  <option value="companyName">Company Name</option>
                  <option value="vatID">VAT ID</option>
                  <option value="affiliateCode">Default Affiliate Code</option>
                  <option value="affiliateCustomCode">Custom Affiliate Code</option>
                  <option value="cancellationCode">Cancellation code</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <input type="text" v-on:keyup.enter="onFilter" class="input" placeholder="Search" v-model="searchText">
          </div>
        </div>
        <div class="column is-6">
          <v-btn rounded small
            color="profileAnchorColor"
            class="button-with-icon text-none whiteColor--text mr-1"
            @click="onFilter">Search</v-btn>
          <v-btn rounded small
            color="profileAnchorColor"
            class="button-with-icon text-none whiteColor--text mr-1"
            @click="exportCsv">Export to CSV</v-btn>
          <v-btn rounded small
            color="profileAnchorColor"
            class="button-with-icon text-none whiteColor--text mr-1"
            @click="() => { showDownloadByCancellationDateModal = true; }">Export by cancellation date</v-btn>
          
          <v-menu offset-y bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn rounded small
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none"
                v-bind="attrs"
                v-on="on"
              >
                <span>Export by plan</span>
                <i class="material-icons">expand_more</i>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link @click="() => exportAllUserToExcel('all')">
                <v-list-item-title>All users</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="() => exportAllUserToExcel('basic')">
                <v-list-item-title>Basic users</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="() => exportAllUserToExcel('premium')">
                <v-list-item-title>Premium users</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="() => exportAllUserToExcel('unlimited_monthly')">
                <v-list-item-title>Unlimited (monthly) users</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="() => exportAllUserToExcel('unlimited_yearly')">
                <v-list-item-title>Unlimited (yearly) users</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="() => exportAllUserToExcel('team_monthly')">
                <v-list-item-title>Team plan (monthly) users</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="() => exportAllUserToExcel('team_yearly')">
                <v-list-item-title>Team plan (yearly) users</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="() => exportAllUserToExcel('api_add_on_users')">
                <v-list-item-title>API Add on users</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="() => exportAllUserToExcel('api_users_only')">
                <v-list-item-title>API users</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="column">
          <div class="field">
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="perPage">
                  <option value="10">10 per page</option>
                  <option value="15">15 per page</option>
                  <option value="25">25 per page</option>
                  <option value="50">50 per page</option>
                  <option value="100">100 per page</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-table
        :data="users"
        ref="userTable"
        paginated
        backend-pagination
        :total="total"
        :per-page="perPage"
        @page-change="onPageChange"
        :current-page="currentPage"
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
        detailed
        detail-key="id"
        @details-open="startEditting"
        @details-close="cancelEditting">
        <template slot-scope="props">
          <b-table-column field="id" label="ID" sortable numeric>{{ props.row.id }}</b-table-column>
          <b-table-column field="email" label="Email" sortable width="300">{{ props.row.email }}</b-table-column>
          <b-table-column field="createdAt" label="Joined Date" sortable width="300">{{ toLocalDateString(props.row.createdAt) }}</b-table-column>
          <b-table-column v-if="!currentUser.isAffiliate" field="paypalEmail" label="PayPal Email" sortable width="300">{{ props.row.paypalEmail }}</b-table-column>
          <b-table-column field="accountType" label="Type" width="150">{{ serviceLabel(props.row.accountType) }}</b-table-column>
          <b-table-column field="trialEndingDate" label="Trial Ends" width="300">{{ toLocalDateString(props.row.trialEndingDate) }}</b-table-column>
          <b-table-column label="Cancelled on" width="300">
            {{ userLastCancelledOn(props.row) }}
          </b-table-column>
          <b-table-column field="activeSubscription" label="Active">
            {{ isFreeUser(props.row) ? 'Free user' : ( props.row.activeSubscriber || props.row.activeSubscription ? 'Yes' : 'No' ) }}
          </b-table-column>
          <!-- <b-table-column field="runsBalance" label="Balance">{{ props.row.runsBalance }}</b-table-column> -->
          <b-table-column field="monthlyCredits" label="Monthly balance">{{ props.row.monthlyCredits }}</b-table-column>
          <b-table-column field="remainedUniversalCredits" label="Purchased balance">{{ props.row.remainedUniversalCredits }}</b-table-column>
          <b-table-column v-if="!currentUser.isAffiliate" field="companyName" label="Company Name" sortable>{{ props.row.companyName }}</b-table-column>
          <b-table-column v-if="!currentUser.isAffiliate" field="vatId" label="VAT ID" sortable>{{ props.row.vatId }}</b-table-column>
          <b-table-column v-if="!currentUser.isAffiliate" field="affiliateCustomCode" label="Custom Affiliate Code" sortable>{{ props.row.affiliateCustomCode }}</b-table-column>
          <b-table-column field="lastPageRunTime" label="Last Page Run" sortable>{{ toLocalDateString(props.row.lastPageRunTime) }}</b-table-column>
        </template>
        <template slot="detail" slot-scope="props">
          <div>
            <v-card v-if="!currentUser.isAffiliate" class="white-common-box pa-5">
              <div class="box has-background-warning flex" v-if="!props.row.isValidated">
                <span class="is-size-5 flex-grow-1">This account is not verified yet.</span>
                <div>
                  <button class="button is-danger" @click="resendRegistrationEmail(props.row)">Resend registration Confirmation Email</button>
                  <button class="button is-info ml10" @click="makeEmailAsValidated(props.row)">Mark as verified</button>
                </div>
              </div>

              <div class="d-flex flex-wrap align-center mb-3">
                <h3 class="font-weight-bold text-h6 mainColor--text">
                  Update To Free Account
                </h3>
                <div class="flex-grow-1 ml-2 mainColor--text">
                  Changing the account to free will end any existing subscriptions
                </div>
                <div>
                  <v-btn rounded x-small
                    color="redColor"
                    class="text-none"
                    @click="() => resetUsageLimitForUser(props.row)">
                    <span>Reset Usage Limit</span>
                  </v-btn>
                </div>
                &nbsp;&nbsp;&nbsp;
                <div>
                  <v-btn rounded x-small
                    color="profileAnchorColor"
                    class="text-none"
                    @click="() => manageSubscriptionForUser(props.row)">
                    <span>Manage subscription</span>
                  </v-btn>
                </div>
              </div>
              <div class="white-common-box pa-3 mb-3 d-flex align-center gap10">
                <div class="field">
                  <div class="control">
                    <label class="checkbox mainColor--text">
                      <input type="checkbox" @change="() => {
                        currentIsBlockedByAdmin = !currentIsBlockedByAdmin
                      }" :checked="currentIsBlockedByAdmin">
                      Check this box if you want to block this user.
                    </label>
                  </div>
                </div>
              </div>

              <div class="field mb-3">
                <label class="label">Free Account Type</label>
                <div class="control">
                  <div class="select">
                    <select v-model="currentAccountType" @change="setCurrentAccountType">
                      <option value="gold">None</option>
                      <option value="gold_free">Pay Per Credit FREE</option>
                      <option value="platinum_free">Unlimited FREE</option>
                      <option value="UNLIMITED_AGENCY5_FREE">Agency 5 Accts FREE</option>
                      <option value="UNLIMITED_AGENCY20_FREE">Agency 20 Accts FREE</option>
                      <option value="UNLIMITED_AGENCY_FREE_EDUCATION">Agency 100 Accts FREE ( EDUCATION )</option>
                    </select>
                  </div>
                </div>
              </div>
                  
              <v-row v-if="isFreePlanSelected" class="mb-3">
                <v-col>
                  <div class="field">
                    <label class="label">Monthly credits free</label>
                    <div class="control">
                      <input type="number" class="input" placeholder="number of credits." v-model="currentMonthlyCreditsFree">
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <div class="field">
                    <label class="label">Free Credits for Months</label>
                    <div class="control">
                      <input type="number" class="input" placeholder="this is how many months will get credits." v-model="currentRemainedMonthlyCreditsMonths">
                    </div>
                  </div>
                </v-col>
              </v-row>

              <div class="field">
                <label class="label">Add Credits Coupon</label>
                <div class="control">
                  <input type="text" class="input" placeholder="coupon" v-model="currentAccountCoupon" @change="setCurrentAccountCoupon">
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <label class="checkbox">
                    <input type="checkbox" @change="setAffiliateStatus" v-model="currentIsAffiliate">
                    Is Affiliate
                  </label>
                </div>
              </div>

              <div style="width: 100%;" v-show="currentIsAffiliate || props.row.isAffiliate">
                <div class="field">
                  <label class="label">Affiliate Expiry Date</label>
                  <div class="control">
                    <datepicker @selected="onAffiliateExpirySelected" :value="currentAffiliateExpiry" input-class="input" placeholder="Select Date"></datepicker>
                  </div>
                </div>
              </div>

              <div v-show="currentIsAffiliate || props.row.isAffiliate">
                <div class="field">
                  <label class="label">Affiliate Percentage (0-1)</label>
                  <div class="control">
                  <input type="number" min=0 max="1" class="input" placeholder="percentage" v-model="currentAffiliatePercentage" @change="setCurrentAffiliatePercentage">
                  </div>
                </div>
              </div>

              <div v-show="currentIsAffiliate || props.row.isAffiliate">
                <div class="field">
                  <label class="label">Affiliate Credits<br><span class="is-italic has-text-weight-normal">Affiliate credits are in addition to the normal credits that a user receives on sign up.</span></label>
                  <div class="control">
                  <input type="text" class="input" placeholder="credits for new users" v-model="currentAffiliateCredits" @change="setCurrentAffiliateCredits">
                  </div>
                </div>
              </div>

              <div v-show="currentIsAffiliate || props.row.isAffiliate">
                <div class="field">
                  <label class="label">Affiliate PayPal Email</label>
                  <div class="control">
                  <input type="text" class="input" placeholder="PayPal email for payouts" v-model="currentPayPalEmail" @change="setCurrentAffiliatePayPalEmail">
                  </div>
                </div>
              </div>

              <div v-show="currentIsAffiliate || props.row.isAffiliate">
                <div class="field">
                  <label class="label">Affiliate Default Code</label>
                  <div class="control">
                  <input type="text" class="input" placeholder="Unique system generated default code" v-model="props.row.affiliateCode" readonly>
                  </div>
                </div>
              </div>

              <div v-show="currentIsAffiliate || props.row.isAffiliate">
                <div class="field">
                  <label class="label">Affiliate Custom Code</label>
                  <div class="control">
                  <input type="text" class="input" placeholder="Unique custom code" v-model="currentAffiliateCustomCode" @change="setCurrentAffiliateCustomCode">
                  </div>
                </div>
              </div>

              <div v-show="currentIsAffiliate || props.row.isAffiliate" class="field">
                <div class="field">
                  <label class="label">Affiliate Coupons</label>
                    <div style="z-index: 99">
                      <multiselect 
                        v-model="currentAffiliateCoupons"
                        track-by="code"
                        placeholder="Search for coupons"
                        label="code"
                        :options="coupons"
                        :multiple="true"
                        :taggable="true"
                        @tag="setCurrentAffiliateCoupon" />
                    </div>

                </div>
              </div>

              <div v-show="!props.row.isAffiliate" class="mt-3">
                <div class="field">
                  <label class="label">Affiliate Sponsor</label>
                  <div class="control">
                    <div class="select">
                      <select v-model="currentAffiliateId" @change="onAffiliateSelected">
                        <option v-for="user in affiliates" :key="user.id" :value="user.id">{{ user.email }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field mt-3">
                <label class="label">Email</label>
                <div class="control">
                  <input type="email" class="input" placeholder="User Email" v-model="currentEmail" />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <label class="checkbox">
                    <input type="checkbox" v-model="currentIsNoDomainRestrictionOnFreeTrial">
                    No Domain Restriction in Free Trial
                  </label>
                </div>
              </div>
              <div class="field mt-5">
                <div class="control">
                  <label class="checkbox">
                    <input type="checkbox" v-model="currentAllowWhiteGlovePurchase">
                    Enable White Glove package purchase button
                  </label>
                </div>
              </div>
              <div class="field mt-5">
                <div class="control">
                  <label class="checkbox">
                    <input type="checkbox" v-model="currentAllowMissionControlAsSpecialSubAccount">
                    Add missioncontrol@pageoptimizer.pro as subaccount
                  </label>
                </div>
              </div>

              <div class="white-common-box pa-3 mt-5">
                <div class="font-weight-bold mainColor--text mb-3">
                  Control Access for the dev mode tools
                  ( Special admins will see all tools no matter what tools are checked here. )
                </div>
                <div class="control mt-1"
                  v-for="item in KEYWORD_TOOL_TABS" :key="item.key">
                  <label class="checkbox mainColor--text">
                    <input type="checkbox" v-model="currentGrantedDevModeTools" :value="item.key" />
                    {{ item.label }}
                  </label>
                </div>
              </div>

              <div class="mt-5">
                <v-btn rounded color="profileAnchorColor" class="text-none whiteColor--text"
                  @click="saveUserAccount(props.row)">
                  <span>Update</span>
                </v-btn>
              </div>
              <div v-if="props.row.isAffiliate" class="mt-3">
                <hr>
                <h3 class="subtitle">Default Affiliate Link</h3>
                <p>{{ props.row.affiliateLink }}</p>
                <br>
                <h3 v-if="props.row.affiliateCustomLink" class="subtitle">Custom Affiliate Link</h3>
                <p v-if="props.row.affiliateCustomLink">{{ props.row.affiliateCustomLink }}</p>
              </div>
              <br>
              <hr>
            </v-card>

            <v-card class="white-common-box pa-5 mt-5">
              <h3 class="subtitle">Cancellation Code</h3>
              <div class="hscroll-container">
                <div> <b>Active Cancellation Code:</b> {{ props.row.cancellationCode ? props.row.cancellationCode : 'No code!!' }} </div>
                <div class="has-text-weight-bold">History</div>
                <table class="table is-striped is-fullwidth">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Code</th>
                      <th>Generated At</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, idx) in props.row.cancellationHistory" :key="idx">
                      <td></td>
                      <td>{{ item.cancellationCode }}</td>
                      <td>{{ toLocalDateString(item.cancellationGeneratedDate) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-card>

            <v-card class="white-common-box pa-5 mt-5">
              <h3 class="subtitle">Promotional Codes Applied</h3>
              <div class="hscroll-container">
                <table class="table is-fullwidth">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Promo Code</th>
                      <th>Applied On</th>
                      <th>Promo Type</th>
                      <th>Amount</th>
                      <th>Product Applied To</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(coupon, i) in getCouponsUsedInPurchase(props.row.purchases)" :key="i">
                      <td></td>
                      <td>{{  coupon.codeAlias ? coupon.codeAlias : coupon.code }}</td>
                      <td>{{ toLocalDateString(coupon.appliedOn) }}</td>
                      <td>{{ coupon.couponType }}</td>
                      <td>{{ coupon.amount }}</td>
                      <td>{{ coupon.product }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-card>

            <v-card class="white-common-box pa-5 mt-5">
              <h3 class="subtitle-font mb10">Purchases</h3>
              <p><i>Charges may take up to 24 hours to show.</i></p>
              <table class="mb20">
                <thead>
                  <tr>
                    <th></th>
                    <th>Purchase Date</th>
                    <th>Service Package</th>
                    <th>Payment Processor</th>
                    <th>Amount Paid ($)</th>
                    <!-- <th>Total ($)</th> -->
                    <th>Status</th>
                    <th>Cancelled On</th>
                    <th>Period Start</th>
                    <th>Period End</th>
                    <th>Original Invoice</th>
                    <th>Paid Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(invoice, idx) in props.row.invoices">
                    <tr v-if="invoice.status !== 'Created'" :key="invoice.id">
                      <td>
                        <input type="checkbox" v-model="invoicesToExport" :value="invoice">
                      </td>
                      <td>{{ toLocalDateString(invoice.created) }}</td>
                      <td>{{ invoice.service }}</td>
                      <td>{{ invoice.paymentProcessor === 'stripe' ? 'Credit Card' : 'PayPal' }}</td>
                      <!-- <td>{{ invoice.total }}</td> -->
                      <td>{{ invoice.amountPaid | round(2) }}</td>
                      <td>{{ _.startCase(invoice.status) }}</td>
                      <td>{{  isNextInvoiceHasSamePurchaseId(idx, props.row.invoices) ? '' : toLocalDateString(invoice.cancelledOn) }}</td>
                      <td>{{ toLocalDateString(invoice.billingPeriodStart) }}</td>
                      <td>{{ invoice.billingPeriodEnd ? toLocalDateString(invoice.billingPeriodEnd) : '' }}</td>
                      <td><button class="button is-warning is-small" @click="downloadInvoice(invoice, false)">Original Invoice</button></td>
                      <td><button class="button is-warning is-small" @click="downloadInvoice(invoice, true)">Paid Invoice</button></td>
                    </tr>
                    <tr :key="'refund'+invoice.id" v-if="paypalRefundData(idx, props.row.invoices, props.row)">
                      <td colspan="3">Refund on {{ toLocalDateString(paypalRefundData(idx, props.row.invoices, props.row).paidAt) }}</td>
                      <td colspan="100">{{ paypalRefundData(idx, props.row.invoices, props.row).description }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <div class="field is-horizontal">
                <div class="field-label"></div>
                <div class="field-body">
                  <div class="field">
                    <div class="control has-text-right">
                      <button @click="downloadInvoices(false)" class="button is-primary mb10" >
                        <span>Download Selected Original Invoices</span>
                      </button>
                      <button @click="downloadInvoices(true)" class="button is-primary mb10">
                        <span>Download Selected Paid Invoices</span>
                      </button>
                    </div> 
                  </div>
                </div>
              </div>
            </v-card>

            <v-card class="white-common-box pa-5 mt-5">
              <h3 class="subtitle-font mb10">Credit log</h3>
              <credit-logs
                :forAdminPage="true"
                :userId="props.row.id"
                ></credit-logs>
            </v-card>


            <v-card class="white-common-box pa-5 mt-5">
              <h3 class="subtitle-font mb10">AI Articles</h3>
              <a-i-writer-log-dashboard
                :forAdminPage="true"
                :userId="props.row.id"
                ></a-i-writer-log-dashboard>
            </v-card>


            <v-card class="white-common-box pa-5 mt-5" v-if="!currentUser.isAffiliate">
              <h3 class="subtitle">Notes</h3>
              <textarea class="textarea" placeholder="Write your comment for user" v-model="adminCommentText"></textarea>
              <div class="mt-3">
                <v-btn rounded color="profileAnchorColor" class="text-none" @click="addAdminComment(props.row.id)">
                  <span>Submit</span>
                </v-btn>
              </div>
              <br />
              <div style="max-height: 350px; overflow: auto;">
                <div v-for="comment in props.row.adminComments" :key="comment.id">
                  <h4 class="has-text-danger">{{ comment.creatorName }} - {{ comment.createdAt }}</h4>
                  <div class="has-text-info">{{ comment.message }}</div>
                  <hr>
                </div>
              </div>
            </v-card>
          </div>
        </template>
      </b-table>
    </div>

    <div class="modal" :class="{'is-active': showErrorUpdatingUserModal}">
      <div class="modal-background" @click="showErrorUpdatingUserModal = false; errorUpdatingUserMsg = ''"></div>
      <div class="modal-content">
        <div class="box modal-container">
          <p class="subtitle">{{ errorUpdatingUserMsg }}</p>
          <div>
            <v-btn rounded small
              color="profileAnchorColor"
              class="button-with-icon text-none mainColor--text"
              @click="showErrorUpdatingUserModal = false; errorUpdatingUserMsg = ''">
              Ok
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" :class="{'is-active': showManageSubscriptionModal}">
      <div class="modal-background" @click="showManageSubscriptionModal = false"></div>
      <div class="modal-content">
        <div class="box modal-container">
          <div v-if="selectedUser">
            <div class="title-font is-size-4 mb-5">
              Manage subscription [ {{ selectedUser.email }} ]
            </div>
            <div class="mt-5 mb-10 text-right">
              <v-btn rounded
                v-if="selectedUser.activeSubscriber && selectedUserActiveSubscription && selectedUserActiveSubscription.paymentMethod"
                color="profileAnchorColor"
                class="text-none whiteColor--text"
                @click="cancelSubscriptionForSelectedUser">
                Cancel subscription
              </v-btn>
            </div>
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>Subscription Id</th>
                  <th>Payment method</th>
                  <th>Subscribed at</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="p in purchaseDataForSubscriptionFind" :key="p.id">
                  <td>
                    {{ p.subscriptionId }}
                    {{ p.id == selectedUser.activeSubscription ? `( Current Subscription )` : '' }}
                  </td>
                  <td>{{ p.paymentMethod }}</td>
                  <td>{{ toLocalDateString(p.createdAt) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-right mt-5">
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon mainColor--text text-none"
              @click="showManageSubscriptionModal = false">
              <span>Close</span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" :class="{'is-active': showDownloadByCancellationDateModal}">
      <div class="modal-background" @click="showDownloadByCancellationDateModal = false"></div>
      <div class="modal-content" style="max-width: 600px;">
        <div class="box modal-container" style="overflow: hidden;">
          <div class="modal-content-root">
            <div class="title-font is-size-4 font-weight-bold mainColor--text">
              Download by cancellation date
            </div>
            <div class="mt-5">
              <date-picker v-model="downloadDateRange" range inline>
              </date-picker>
            </div>
            <div class="control flex mt-5">
              <div class="flex-grow-1">
              </div>
              <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none" @click="() => {
                  showDownloadByCancellationDateModal = false;
                  exportAllUserToExcel('all', true)
                }">
                <span>Download</span>
              </v-btn>
            </div>
          </div>
        </div>
        <button class="custom-modal-close-btn" aria-label="close" @click="showDownloadByCancellationDateModal = false"><i class="material-icons">close</i></button>
      </div>
    </div>

  </section>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker'
import DatePicker from 'vue2-datepicker';

import Multiselect from 'vue-multiselect'
import { mixin as ExportMixin } from '@/components/export_mixin'
// import { mixin as BaseMixin } from '@/components/mixin'
import { mixin as ServiceMixin } from '@/components/service_mixin'
import { toLocalDateString, toLocalDate, getPureServicePkgName } from '@/utils'
import {
  freePlans,
  additionalSeatPlans,
  
} from "@/components/plans_constants"


import CreditLogs from "@/components/credit-logs/CreditLogs.vue"
import AIWriterLogDashboard from "@/components/AIWriterLogDashboard.vue"
import { ALL_TABS as KEYWORD_TOOL_TABS } from "@/components/Campaigns/Index.vue";


const now = new Date()

export default {
  mixins: [ExportMixin, ServiceMixin],
  components: {
    Datepicker, DatePicker,
    Multiselect,
    CreditLogs,
    AIWriterLogDashboard,
  },
  data() {
    return {
      records: [],
      ready: true,
      progress: 0,
      sortField: 'id',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      perPage: 10,
      totalRows: 0,
      editting: false,
      edittingRow: {},
      currentEmail: '',
      currentPayPalEmail: '',
      currentAccountType: '',
      currentAccountCoupon: '',
      currentAffiliateCredits: 0,
      currentIsAffiliate: false,
      currentIsNoDomainRestrictionOnFreeTrial: false,
      currentAllowWhiteGlovePurchase: false,
      currentAllowMissionControlAsSpecialSubAccount: false,
      isAffiliateChange: false,
      currentAffiliateExpiry: new Date(now.getFullYear() + 99, now.getMonth(), now.getDate()),
      currentAffiliateId: null,
      currentMonthlyCreditsFree: 0,
      currentRemainedMonthlyCreditsMonths: 0,
      currentIsBlockedByAdmin: false,
      currentGrantedDevModeTools: [],

      searchText: '',
      filterField: 'email',
      perPageData: [],
      isSimple: false,
      isRounded: false,
      currentPage: 1,
      total: 0,
      isLoading: false, // this is not used because store loading is used so don't need
      createPurchase: false,
      currentAffiliatePercentage: 0.25,
      currentAffiliateCustomCode: '',
      currentAffiliateCoupons: [],
      affiliates: [],
      adminCommentText: '',
      showErrorUpdatingUserModal: false,
      errorUpdatingUserMsg: '',
      invoicesToExport: [],


      showManageSubscriptionModal: false,
      purchaseDataForSubscriptionFind: [],
      selectedUser: null,
      selectedUserActiveSubscription: null,
      
      showDownloadByCancellationDateModal: false,
      downloadDateRange: [moment().subtract(3, 'months').toDate(), moment().toDate()],
    }
  },
  computed: {
    KEYWORD_TOOL_TABS () {
      return KEYWORD_TOOL_TABS
    },

    isFreePlanSelected () {
      return freePlans.includes(this.currentAccountType)
    },
    currentUser() {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return {}
    },
    users() {
      return this.$store.state.allUsers
    },
    _() {
      return _
    },
    coupons () {
      return this.$store.state.coupons.filter((c) => c.couponType.toLowerCase() !== 'credits')
    }
  },
  methods: {
    isFreeUser (user) {
      if (!user) return false;
      if (freePlans.includes(user.accountType)) {
        return true;
      }
      if (user.isFreePeriodUser) {
        return true;
      }
      return false;
    },
    userLastCancelledOn(user) {
      if (!user || user.activeSubscriber) {
        return ''
      }
      try {
        let cancelledOn = ''
        for (let p of user.purchases) {
          if (!freePlans.includes(p.servicePackage) && p.subscriptionId && !additionalSeatPlans.includes(getPureServicePkgName(p.servicePackage))) {
            if (p.cancelledOn) {
              cancelledOn = p.cancelledOn
              break
            }
          }
        }
        return cancelledOn
      } catch (e) { }
      return 'null';
    },
    getCouponsUsedInPurchase (purchases) {
      return purchases.filter(p => {
        return p.couponInfo && p.couponInfo.code && !['incomplete'].includes(p.subscriptionStatus)
      }).map((p) => p.couponInfo)
    },
    resetUsageLimitForUser (user) {
      this.selectedUser = user
      this.$store.dispatch('adminResetUsageLimitForUser', {
        userId: this.selectedUser.id,
        email: this.selectedUser.email
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.$notify({
            group: 'info', type: 'success',
            text: 'Successfully reset usage limits for this user.'
          })
        } else {
          this.$notify({
            group: 'info', type: 'error',
            text: response.data.msg || 'Failed for this action.'
          })
        }
      })
    },
    manageSubscriptionForUser (user) {
      this.selectedUser = user
      let recentPurchases = user.purchases.filter(item => {
        if (item.id == user.activeSubscription) {
          this.selectedUserActiveSubscription = item
        }
        return !!item.subscriptionId
      })
      this.purchaseDataForSubscriptionFind = recentPurchases.slice(0, 10)
      this.showManageSubscriptionModal = true;
    },
    cancelSubscriptionForSelectedUser () {
      if (!window.confirm('Do you want to cancel this user\'s subscription?')) {
        return;
      }
      this.$store.commit('showLoading')
      this.$store.dispatch('adminCancelSubscription', {
        subscriptionId: this.selectedUserActiveSubscription.subscriptionId
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.$notify({
            group: 'info', type: 'success',
            text: 'Successfully cancelled subscription for this user.'
          })
        } else {
          this.$notify({
            group: 'info', type: 'error',
            text: response.data.msg || 'Failed for this action.'
          })
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      })
    },
    isNextInvoiceHasSamePurchaseId(invoiceIndex, invoices) {
      if (invoiceIndex + 1 >= invoices.length ) { return true }
      if (invoices[invoiceIndex].purchaseId == invoices[invoiceIndex+1].purchaseId) { return true }
      return false
    },
    paypalRefundData (invoiceIndex, invoices, user) {
      if (invoiceIndex + 1 >= invoices.length ) { return false }
      if (invoices[invoiceIndex].purchaseId == invoices[invoiceIndex+1].purchaseId) { return false }
      let arr = user.paypalRefunds.filter(refund => refund.purchase.id == invoices[invoiceIndex].purchaseId)
      if (arr.length > 0)
        return arr[0]
      else
        false
    },
    downloadInvoice (invoice, isPaid) {
      this.$store.commit('showLoading')
      this.$store.dispatch('downloadInvoices', { invoices: [ invoice ], isPaid: isPaid })
        .then((response) => {
          if (response.data.status === 'SUCCESS') {
            const { url, file } = response.data
            return this.downloadFile(url, file)
          }
        })
        .finally(() => this.$store.commit('hideLoading'))
    },
    downloadInvoices (isPaid) {
      if (this.invoicesToExport.length == 0) {
        alert ('You must select at least one invoice');
        return;
      }

      this.$store.commit('showLoading')
      this.$store.dispatch('downloadInvoices', { invoices: this.invoicesToExport, isPaid: isPaid })
        .then((response) => {
          if (response.data.status === 'SUCCESS') {
            const { url, file } = response.data
            return this.downloadFile(url, file)
          }
        })
        .finally(() => this.$store.commit('hideLoading'))
    },
    downloadFile(fileURL, fileName) {
      // for non-IE
      if (!window.ActiveXObject) {
          var save = document.createElement('a');
          save.href = fileURL;
          save.target = '_blank';
          var filename = fileURL.substring(fileURL.lastIndexOf('/')+1);
          save.download = fileName || filename;
          if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
          document.location = save.href; 
          // window event not working here
        } else{
          var evt = new MouseEvent('click', {
              'view': window,
              'bubbles': true,
              'cancelable': false
          });
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
        // for IE < 11
      } else if ( !! window.ActiveXObject && document.execCommand)     {
        var _window = window.open(fileURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName || fileURL)
        _window.close();
      }
    },
    onFilter () {
      this.$nextTick(() => {
        this.loadData()
      })
    },
    onPageChange (page) {
      this.page = page
      this.$nextTick(() => {
        this.loadData()
      })
    },
    onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
      this.loadData()
    },
    loadData () {
      this.$store.commit('showLoading')
      const params = [
        `sortField=${this.sortField}`,
        `sortOrder=${this.sortOrder}`,
        `page=${this.page}`,
        `perPage=${this.perPage}`,
        `filterField=${this.filterField}`,
        `filterText=${this.searchText.replace(/\s+/g, '')}` // remove all white space before searching. case will be ignored on server side
      ].join('&')
      return this.$store.dispatch('loadUsersForAdmin', { params })
        .then((response) => {
          const { users, totalUsers, affiliates } = response.data
          this.affiliates = affiliates
          this.$store.commit('setAllUsers', { users })
          this.total = totalUsers
          return this.$store.dispatch('loadCouponsForAdmin')
        })
        .then((response) => {
          this.$store.commit('setCoupons', { coupons: response.data.coupons })
          this.affiliates = response.data.affiliates
        })
        .catch((err) => {
          console.log('Error fetching admin users ', err)
        })
        .finally(() => {
          this.loading = false
          this.$store.commit('hideLoading')
        })
    },
    setCurrentAffiliateCoupon (coupon) {
      this.currentAffiliateCoupons.push(coupon)
    },
    setCurrentAffiliatePercentage (event) {
      let pct = parseFloat(event.target.value)
      if (pct > 0 && pct < 1) {
        this.currentAffiliatePercentage = pct
      } else {
        this.currentAffiliatePercentage = 0
      }
    },
    setCurrentAffiliatePayPalEmail (event) {
      this.currentPayPalEmail = event.target.value
    },
    setCurrentAffiliateCustomCode (event) {
      this.currentAffiliateCustomCode = event.target.value
    },
    onAffiliateSelected (event) {
      this.currentAffiliateId = parseInt(event.target.value)
    },
    setCurrentAffiliateCredits (event) {
      this.currentAffiliateCredits = parseInt(event.target.value)
    },
    onAffiliateExpirySelected (date) {
      this.currentAffiliateExpiry = date.toISOString()
    },
    setAffiliateStatus (event) {
      this.currentIsAffiliate = event.target.checked
      this.isAffiliateChange = true
    },
    toLocalDateString (dateStr) {
      return toLocalDateString(dateStr)
    },
    setCurrentAccountType (event) {
      this.currentAccountType = event.target.value
      this.createPurchase = true
    },
    setCurrentAccountCoupon (event) {
      this.currentAccountCoupon = event.target.value
      this.createPurchase = true
    },
    exportCsv () {
      let csvData = ['ID,Email,Account Type,Account Balance,Account Coupon']
      this.users.forEach((u) => {
        csvData.push(`${u.id},${u.email},${u.accountType},${u.accountBalance},${u.accountCoupon ? u.accountCoupon : ''}`)
      })
      const fileName = `User_Accounts_${now.getUTCFullYear()}_${(now.getUTCMonth()+1)}_${now.getUTCDate()}_Page${this.page}.csv`
      this.exportToCSV(csvData.join('\n'), fileName)
    },
    exportAllUserToExcel (downloadAccountType, byCancellationDateFilter=false) {
      let [ fromDate, toDate ] = this.downloadDateRange
      this.$store.commit('showLoading')
      this.$store.dispatch('downloadAllUserExcel', {
        downloadAccountType: downloadAccountType,
        byCancellationDateFilter: byCancellationDateFilter,
        fromDate: fromDate.valueOf(),
        toDate: toDate.valueOf(),
      })
      .then(response => {
        console.log(response.data)
        if (response.data.status === 'SUCCESS') {

          let taskId = response.data.taskId
          if (taskId) {
            let interval = setInterval(() => {
              this.$store.dispatch('getTaskStatus', taskId)
              .then(response => {
                console.log('task result is', response.data)
                if (response.data && response.data.status) {
                  if (response.data.status == 'SUCCESS') {
                    clearInterval(interval)
                    this.$store.commit('hideLoading')

                    let downloadAllUserUrl = `${API_URL}/download/?file=${response.data.url}`
                    let fileName = downloadAllUserUrl.split('/').pop()
                    
                    let downloadLink;
                    downloadLink = document.createElement("a");
                    downloadLink.download = fileName;
                    downloadLink.href = downloadAllUserUrl
                    downloadLink.style.display = "none";
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                  } else if (response.data.status == 'FAILURE') {
                    clearInterval(interval)
                    this.$store.commit('hideLoading')
                    this.$notify({
                      group: 'info', type: 'error',
                      text: 'Failed to download.'
                    })
                  }
                }
              })
            }, 5000)
          }
        } else {
          this.$store.commit('hideLoading')
        }
      })
      .finally(() => {})
    },
    startEditting (row) {
      this.$store.commit('showLoading');
      this.cancelEditting({})
      if (!_.isEmpty(this.edittingRow) && this.edittingRow.id) {
        this.$refs.userTable.toggleDetails(this.edittingRow)
      }
      this.edittingRow = row
      const {
        email, paypalEmail, accountType, affiliateCoupons, isAffiliate, isNoDomainRestrictionInTrial,
        affiliatePercentage, affiliateExpiry, affiliateCredits, affiliateCustomCode, accountCoupon, affiliateId,
        monthlyCreditsFree, remainedMonthlyCreditsMonths, allowMissionControlEmailAsSubAccount,
        allowWhiteGlovePurchase,
        isBlockedByAdmin, grantedDevModeTools
      } = row

      this.currentEmail = email
      this.currentPayPalEmail = paypalEmail || ''
      this.currentAccountType = accountType
      this.currentAccountCoupon = accountCoupon
      this.currentIsAffiliate = isAffiliate
      this.currentIsNoDomainRestrictionOnFreeTrial = isNoDomainRestrictionInTrial
      this.currentAllowWhiteGlovePurchase = allowWhiteGlovePurchase
      this.currentAllowMissionControlAsSpecialSubAccount = allowMissionControlEmailAsSubAccount
      this.currentAffiliateId = affiliateId
      this.createPurchase = false
      this.currentAffiliatePercentage = affiliatePercentage
      this.currentAffiliateExpiry = affiliateExpiry ? new Date(Date.parse(affiliateExpiry)) : new Date(now.getFullYear() + 99, now.getMonth(), now.getDate())
      this.currentAffiliateCredits = affiliateCredits
      this.currentAffiliateCustomCode = affiliateCustomCode
      this.currentAffiliateCoupons = affiliateCoupons

      this.currentMonthlyCreditsFree = monthlyCreditsFree || 0
      this.currentRemainedMonthlyCreditsMonths = remainedMonthlyCreditsMonths || 0
      this.currentIsBlockedByAdmin = isBlockedByAdmin == 1
      this.currentGrantedDevModeTools = grantedDevModeTools
      this.$store.commit('hideLoading');
    },
    cancelEditting (row) {
      this.$store.commit('showLoading');
      this.edittingRow = {}
      this.currentAffiliatePercentage = 0.25
      this.currentAffiliateExpiry = new Date(now.getFullYear() + 99, now.getMonth(), now.getDate())
      this.currentAffiliateCredits = 0
      this.currentAffiliateCustomCode = ''
      this.currentAffiliateCoupon = ''
      this.currentAffiliateId = ''
      this.invoicesToExport = []

      this.currentMonthlyCreditsFree = 0
      this.currentRemainedMonthlyCreditsMonths = 0
      this.$store.commit('hideLoading');
    },
    saveUserAccount (user) {
      this.$store.commit('showLoading')
      this.editting = true
      const email = !!this.currentEmail && this.currentEmail !== user.email ? this.currentEmail : user.email
      const accountCoupon = !!this.currentAccountCoupon && this.currentAccountCoupon !== user.accountCoupon ? this.currentAccountCoupon : user.accountCoupon
      const accountType = !!this.currentAccountType && this.currentAccountType !== user.accontType ? this.currentAccountType : user.accountType
      const isAffiliate = this.isAffiliateChange ? this.currentIsAffiliate : user.isAffiliate
      const isNoDomainRestrictionInTrial = this.currentIsNoDomainRestrictionOnFreeTrial
      const affiliateId = !!this.currentAffiliateId && this.currentAffiliateId !== user.affiliateId ? this.currentAffiliateId : user.affiliateId
      const affiliateExpiry = !!this.currentAffiliateExpiry ? this.currentAffiliateExpiry : user.affiliateExpiry
      const affiliatePercentage = !!this.currentAffiliatePercentage ? this.currentAffiliatePercentage : user.affiliatePercentage
      const affiliateCredits = !!this.currentAffiliateCredits && this.currentAffiliateCredits !== user.affiliateCredits ? this.currentAffiliateCredits : user.affiliateCredits
      const payPalEmail = !!this.currentPayPalEmail && this.currentPayPalEmail !== user.paypalEmail ? this.currentPayPalEmail : user.paypalEmail
      const affiliateCustomCode = !!this.currentAffiliateCustomCode && this.currentAffiliateCustomCode !== user.affiliateCustomCode ? this.currentAffiliateCustomCode : user.affiliateCustomCode
      const affiliateCoupons = this.currentAffiliateCoupons
      const updateAccountType = this.currentAccountType && user.accountType != this.currentAccountType
      const allowMissionControlEmailAsSubAccount = this.currentAllowMissionControlAsSpecialSubAccount ? 1 : 0
      const allowWhiteGlovePurchase = this.currentAllowWhiteGlovePurchase ? 1 : 0
      const isBlockedByAdmin = this.currentIsBlockedByAdmin ? 1 : 0
      const grantedDevModeTools = this.currentGrantedDevModeTools
      this.$store.dispatch('updateUserAccountForAdmin', {
        userId: user.id,
        email: email,
        accountCoupon,
        accountType,
        affiliateId,
        isAffiliate,
        isNoDomainRestrictionInTrial,
        affiliateExpiry,
        affiliatePercentage,
        affiliateCredits,
        affiliateCoupons,
        payPalEmail,
        affiliateCustomCode,
        updateAccountType,
        createPurchase: this.createPurchase,
        monthlyCreditsFree: this.currentMonthlyCreditsFree,
        remainedMonthlyCreditsMonths: this.currentRemainedMonthlyCreditsMonths,
        allowMissionControlEmailAsSubAccount,
        allowWhiteGlovePurchase,
        isBlockedByAdmin,
        grantedDevModeTools,
      })
      .then((response) => {
        if (response.data.status === 'FAILURE') {
          this.errorUpdatingUserMsg = response.data.errMsg
          this.showErrorUpdatingUserModal = true
        } else {
          // return this.loadData()
          this.$store.commit('updateUser', { user: response.data.account })
        }
        return 1
      })
      .catch((err) => {
        this.editting = false
        console.log('Error updating userAccount', err)
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    addAdminComment (userId) {
      if (this.adminCommentText == '') { return }
      // console.log( userId, this.adminCommentText, this.currentUser);
      this.$store.commit('showLoading')
      this.$store.dispatch('makeAdminUserComment', {
        userId: userId,
        message: this.adminCommentText 
      })
        .then((response) => {
          this.cancelEditting()
          this.adminCommentText = ''
          return this.loadData()
        })
        .catch((err) => {
          this.editting = false
          console.log('Error updating userAccount', err)
          this.cancelEditting()
          this.$store.commit('hideLoading')
        })
    },
    onClickPage(pageNum) {
      this.current = pageNum;
      this.perPageData = this.$store.state.allUsers.slice(this.perPage * (pageNum - 1), pageNum * this.perPage)
    },
    changeSearchText() {
      if (!this.searchText) {
        this.current = 1;
        this.total = this.$store.state.allUsers.length;
        this.perPageData =  this.$store.state.allUsers.slice(0, this.perPage);

      } else {
        const searchData = this.users.filter((u) => {
          const acctType = u.accountType === 'trial' ? 'Trial' : u.accountType === 'gold' ? 'Pay As You Go' : 'Unlimited'
          const toSearch = `${acctType} ${u.email} ${u.accountBalance} ${u.accountCoupon}`.toLowerCase()
          return toSearch.indexOf(this.searchText.toLowerCase()) > -1
        });
        this.current = 1;
        this.total = Math.ceil(searchData.length / this.perPage);
        this.perPageData = searchData.slice(0, this.perPage);
      }
    },
    resendRegistrationEmail (user) {
      console.log('USER INFO ', user)
      this.$store.commit('showLoading')
      this.$store.dispatch('resendRegistrationEmailForAdmin', { userId: user.id })
      .then(response => {
        if (response.data && response.data.status === 'SUCCESS') {
          alert('Successfully sent registration email again.')
        } else {
          alert(response.data.errMsg || 'Failed to send email again.')
        }
      })
      .finally(() => this.$store.commit('hideLoading'))
    },
    makeEmailAsValidated (user) {
      this.$store.dispatch('confirmEmailAsValidated', { userId: user.id })
      .then(response => {
        if (response.data && response.data.status === 'SUCCESS') {
          alert('This email is verified completely.')
        } else {
          alert(response.data.errMsg)
        }
      })
      .finally(() => this.$store.commit('hideLoading'))
    }
  },
  beforeMount () {
    // this.loadData()
  }
}
</script>

<style lang="scss" scoped>
.table-mobile-sort {
  display: none;
}

</style>

<template>
<div class="modal" :class="{'is-active': showModal}">
  <div class="modal-background" @click.stop="showModal = false"></div>
  <div class="modal-content" style="max-width: 500px;">
    <div class="box modal-container">
      <div class="modal-content-root">
        <p class="title-font is-size-4 mainColor--text">Domain keyword research</p>
        <div class="mt-5">
          <div>
            <div class="mb5 mainColor--text">
              Domain
            </div>
            <v-text-field outlined dense hide-details
              color="greenColor"
              class=""
              disabled
              v-model="targetUrl"></v-text-field>
          </div>
          <!-- <div class="mt-5">
            <div class="mb5 mainColor--text">
              Campaign name
            </div>
            <v-text-field outlined dense hide-details
              color="greenColor"
              class=""
              v-model="campaignName"></v-text-field>
          </div> -->
          <div class="mt-5">
            <div class="mb5 mainColor--text">
              Location
            </div>
            <v-autocomplete outlined dense hide-details="auto"
              :items="availableLocations"
              item-text="location_name"
              item-value="location_code"
              v-model="selectedLocation"
              color="grayColor">
            </v-autocomplete>
          </div>
          <div class="mt-5">
            <div class="mb5 mainColor--text">
              Language
            </div>
            <v-autocomplete outlined dense hide-details
              :items="targetLanguages"
              v-model="selectedLanguage"
              item-text="language_name"
              item-value="language_code"
              color="grayColor">
            </v-autocomplete>
          </div>
        </div>
        <div class="d-flex justify-end gap10 mt-5">
          <v-btn rounded outlined
            color="mainColor" class="button-with-icon text-none"
            @click.stop="() => {
              showModal = false;
            }">
            <span>Cancel</span>
          </v-btn>
          <v-btn rounded color="profileAnchorColor" class="button-with-icon whiteColor--text text-none"
            :disabled="!canProceed"
            @click.stop="createCampaign">
            <span>Start now</span>
          </v-btn>
        </div>
      </div>
    </div>
    <button
      class="custom-modal-close-btn"
      aria-label="close"
      @click="() => (showModal = false)">
      <i class="material-icons">close</i>
    </button>
  </div>
</div>
</template>


<script>
import { capitalize } from 'lodash';
import languages from '@/consts/dataforseo_languages.json';
import dataForSeoLocations from '@/consts/dataforseo_locations.json';
import { getHostName } from "@/utils";

export default {
  data () {
    return {
      showModal: false,


      targetUrl: '',
      campaignName: '',
      selectedLocation: '',
      selectedLanguage: '',
    }
  },
  computed: {
    // googleSearchLocations() {
    //   if (this.$store.getters.getGoogleLocations) {
    //     return this.$store.getters.getGoogleLocations.filter(item => !item.includes('Extension'))
    //   }
    //   return []
    // },
    // targetLanguages () {
    //   return this.$store.state.targetLanguages.map(item => {
    //     return {
    //       text: capitalize(item),
    //       value: item
    //     }
    //   })
    // },
    availableLocations () {
      return dataForSeoLocations.locations;
    },
    targetLanguages () {
      return languages;
    },
    site () {
      return this.$store.getters.siteById(parseInt(this.$route.params.siteId))
    },
    canProceed () {
      return this.selectedLocation && this.selectedLanguage
    }
  },
  watch: {
    'site': function (newVal, oldVal) {
      this.preActionForSiteChange();
    }
  },
  methods: {
    showModalAction() {
      this.showModal = true;
    },
    createCampaign () {
      let data = {
        campaignName: this.campaignName,
        siteId: this.site.id,
        targetUrl: this.targetUrl,
        isDomainLevelCampaign: true,
        locationCode: this.selectedLocation,
        languageCode: this.selectedLanguage
      }
      this.$store.commit('showLoading');
      this.$store.dispatch('createKeywordResearchCampaign', data).then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          let campaign = response.data.campaign;
          this.showModal = false;
          this.$router.push(`/sites/${ this.site.id }/campaign/${ campaign.id }`)
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      })
    },
    preActionForSiteChange () {
      if (this.site) {
        this.targetUrl = getHostName(this.site.baseURL)
      }
    }
  },
  mounted () {
    // if (this.googleSearchLocations.length == 0) {
    //   this.$store.dispatch('fetchGoogleSearchLocations')
    // }
    this.preActionForSiteChange();
    
  }
}
</script>


<style lang="scss" scoped>
.site-url-input {
  color: var(--v-mainColorr-base);
}

</style>
<template>
<section>
  <div class="register-page">
    <div v-if="currentStep == STEPS.ADD_INFO" class="add-info-step-box">
      <div class="left-part">
        <div>
          <router-link to='/login'>
            <img src="/static/POP@1.png" width="80px" />
          </router-link>
        </div>      
        <div class="flex-grow-1 input-part">
          <div>
            <div class="d-flex align-center">
              <div class="plan-title" v-html="simplifyProductName(computedTitle)">
              </div>
              <!-- <span v-if="computedTitle == 'Basic'" class="trial-markbox ml-5">7-Day Free Trial</span> -->
            </div>
            <div class="mt-3">In a few minutes you’ll be on your way to simple yet powerful traffic gains.</div>
            <div class="input-boxes mt-10">
              <!-- <v-text-field outlined
                type="text"
                label="Full name"
                color="greenColor"
                v-model="fullName"
              ></v-text-field> -->
              <v-text-field outlined
                type="text"
                label="Email"
                color="greenColor"
                class="mt-2"
                v-model="email"
                :error-messages="needDisplayValidationMessageForUserInfo && !validEmail ? 'must be a valid email address' : ''"
              ></v-text-field>
              <v-text-field outlined
                type="password"
                label="Password"
                color="greenColor"
                class="mt-2"
                v-model="password"
                :error-messages="needDisplayValidationMessageForUserInfo && !passwordMinLength ? 'password must be at least 6 characters long' : ''"
              ></v-text-field>
              <v-text-field outlined
                type="password"
                label="Verify password"
                color="greenColor"
                class="mt-2"
                v-model="verifyPassword"
                :error-messages="needDisplayValidationMessageForUserInfo && !passwordsMatch ? 'passwords must match' : ''"
              ></v-text-field>
              <!--
              <label class="checkbox mb20">
                <input type="checkbox" id="isThisForEndUser" v-model="isThisForEndUser">
                Check this box if you are creating this account as a 3rd party vendor
              </label>
              -->
              <template v-if="isThisForEndUser">
                <v-text-field outlined
                  type="text"
                  label="End user email"
                  color="greenColor"
                  class="mt-2"
                  v-model="endUserEmail"
                  :error-messages="needDisplayValidationMessageForUserInfo && !validEndUserEmail ? 'must be a valid email address' : ''"
                ></v-text-field>
                <v-text-field outlined
                  type="password"
                  label="End User Password"
                  color="greenColor"
                  class="mt-2"
                  v-model="endUserPassword"
                  :error-messages="needDisplayValidationMessageForUserInfo && !endUserPasswordMinLength ? 'password must be at least 6 characters long' : ''"
                ></v-text-field>
                <v-text-field outlined
                  type="password"
                  label="Verify End User Password"
                  color="greenColor"
                  class="mt-2"
                  v-model="endUserVerifyPassword"
                  :error-messages="needDisplayValidationMessageForUserInfo && !endUserPasswordsMatch ? 'passwords must match' : ''"
                ></v-text-field>
              </template>
            </div>

            <div>
              <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none"
                @click="validateUserInputAndGoPaymentPage">
                <span>Add payment method</span>
                <i class="material-icons">arrow_forward</i>
              </v-btn>
            </div>
            <div class="mt-5 darkGrayColor--text font-italic">
              Signing up for a POP account means you agree to the 
              <a class="a-link darkGrayColor--text" href="https://pageoptimizer.pro/privacy-policy/" target="_blank">Privacy Policy</a> and 
              <a class="a-link darkGrayColor--text" href="https://www.pageoptimizer.pro/terms-of-service" target="_blank">Terms of Service</a>
            </div>
          </div>
        </div>
      </div>
      <div class="right-part">
        <div class="img-block1">
          <img src="/static/login-page-img1.png" />
        </div>
        <div class="carousel-wrapper">
          <VueSlickCarousel v-bind="carouselSetting" class="carousel">
            <div class="carousel-item" v-for="(item, idx) in carouselInfo" :key="idx">
              <img src="/static/quota.png" class="quota-img" />
              <div class="user-say-text">
                {{ item.says }}
              </div>
              <div class="user-info">
                <img :src="`/static/${ item.img }`" />
                <div>
                  <div class="name">{{ item.name }}</div>
                  <div class="company">{{ item.company }}</div>
                </div>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
    <div v-if="currentStep == STEPS.PAYMENT" class="payment-step-box">
      <div class="top-part">
        <router-link to='/login'>
          <img src="/static/POP@1.png" width="80px" />
        </router-link>
      </div>
      <div class="main-part">
        <div>
          <div class="lets-complete">Let's complete your purchase.</div>
          <div class="signup-desc">
            You are about to sign up for <b>{{ computedTitle }} plan</b>
          </div>
          
          <div class="boxes">
            <div>
              <div class="order-box">
                <div class="block-section flex-wrap">
                  <div class="font-weight-bold text-h6">Order Summary</div>
                  <a v-if="!isShowInputCouponBox"
                    class="d-flex align-center" @click="isShowInputCouponBox = true;">
                    <i class="material-icons">add</i>
                    <span>Add coupon code</span>
                  </a>
                  <div v-if="isShowInputCouponBox">
                    <div class="input-promo-box" style="width: 200px;">
                      <a class="coupon-apply-btn" @click="applyCoupon">
                        Apply
                      </a>
                      <div class="field">
                        <div class="control">
                          <input type="text" placeholder="Enter promo or coupon code" class="input" v-model="promoCode">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width: 100%;">
                    <div v-if="serviceCouponsValid" class="help has-text-success text-right">{{ serviceCouponsMessage }}</div>
                    <div v-if="!serviceCouponsValid" class="help has-text-danger text-right">{{ serviceCouponsMessage }}</div>
                  </div>

                </div>
                <div class="block-section">
                  <div>{{ computedTitle }}</div>
                  <div v-if="periodFreeCouponApplied">{{ appliedCoupon?.freeDays }} days free</div>
                  <div v-else>$ {{ serviceFromRoute.price.toFixed(2) }} / {{ serviceFromRoute.timeUnit }}</div>
                </div>
                <div class="block-section">
                  <div>Due today</div>
                  <div>
                    <template v-if="periodFreeCouponApplied">
                      <span>
                        $ 0 USD
                      </span>
                    </template>
                    <!-- <template v-else-if="computedTitle == 'Basic'">
                      <span class="font-weight-bold">$ 0</span>
                      <span class="trial-markbox ml-2">7-Day Free Trial</span>
                    </template> -->
                    <div v-else class="font-weight-bold">
                      $ {{ serviceFromRoute.price.toFixed(2) }} USD
                    </div>
                  </div>
                </div>
                <div class="pay-block mt-5">
                  <template v-if="periodFreeCouponApplied">
                    <button
                      class="register-free-button"
                      :disabled="!validForm"
                      @click.stop="register('free')"
                    >
                      <span>Register For Free</span>
                    </button>
                  </template>
                  <template v-else>
                    <!-- <div class="select-your-method">Select your payment method</div> -->
                    <div v-if="isYearlyPackage">
                      <v-checkbox dense
                        color="mainColor"
                        v-model="payViaInvoice"
                        hide-details="auto"
                        label="I want to pay via invoice">
                      </v-checkbox>
                    </div>
                    <div class="mt-5 d-flex flex-col-gap-10">
                      <button
                        class="credit-card-button"
                        :disabled="!validForm"
                        @click.stop="register('stripe')"
                      >
                        <img src="/static/credit-white.png" />
                        <span>Credit Card</span>
                      </button>
                      <!-- <button
                        class="paypal-button"
                        :disabled="!validForm"
                        @click.stop="register('paypal')"
                      >
                        <img src="/static/paypal-white.png" />
                        <span>PayPal</span>
                      </button> -->
                    </div>
                    <div class="mt-6 darkGrayColor--text">
                      <i>
                        All transactions are secure and encrypted. Signing up for a POP account means you agree to the 
                        <a class="a-link darkGrayColor--text" href="https://pageoptimizer.pro/privacy-policy/" target="_blank">Privacy Policy</a> and 
                        <a class="a-link darkGrayColor--text" href="https://www.pageoptimizer.pro/terms-of-service" target="_blank">Terms of Service</a>.
                      </i>
                      <div class="mt-4">
                        You have selected the {{ computedTitle }} plan and your card will be charged ${{ periodFreeCouponApplied ? 0 : serviceFromRoute.price.toFixed(2) }} now. If you cancel your subscription within 7 days you will receive a full refund after completing the refund request form.  Subscriptions must be cancelled in your Account settings and cannot be cancelled via email or live chat. After the initial 7 days, there are no refunds.
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column">
              <div v-if="(!periodFreeCouponApplied || computedTitle.includes('Unlimited'))" class="plan-function-box flex-grow-1 align-center">
                <service-explain-list
                  v-if="serviceFromRoute"
                  :title="serviceFromRoute.title"
                  :price="serviceFromRoute.price"
                  :timeunit="serviceFromRoute.timeUnit"
                  :items="serviceFromRoute.items"
                  :affiliateCode="promoCode"
                  :periodFreeCouponApplied="periodFreeCouponApplied"
                  :coupon="appliedCoupon"
                  :showStarSignExplainText="serviceFromRoute.showStarSignExplainText">
                </service-explain-list>
              </div>
              <div class="plan-function-box" style="margin-top: 30px;">
                <div class="user-say-text-2">
                  I would recommend POP because it is the only tool which <b>gives you the assurance</b> that you've closed any gaps to your competitors.
                  <b>It digs so deep into so many factors</b> that would be much more complex and time-consuming to perform with a blend of other tools.
                </div>
                <div class="user-info-2">
                  <img :src="`/static/user4.png`" />
                  <div>
                    <div class="name">Henning Geiler</div>
                    <div class="company">Pinpoint Media Design</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="{'is-active': showErrorModal}">
    <div class="modal-background" @click.stop="showErrorModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container has-text-centered">
        <div class="modal-content-root">
          <div class="title-font is-size-4 mainColor--text">Registration Failure</div>
          <div class="subtitle-font mainColor--text mt-2" v-if="errorMsg">{{ errorMsg }}</div>
          <div class="subtitle-font mainColor--text mt-2" v-if="!errorMsg">Please note that you will not be able to make any purchases until you log in.</div>
          <div class="control flex mt20">
            <div class="flex-grow-1">
            </div>
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon text-none whiteColor--text"
              @click.stop="goToLogin">
              <span>Login</span>
            </v-btn>
            <v-btn rounded
              color="grayColor"
              class="button-with-icon text-none gray2Color--text"
              @click.stop="showErrorModal = false">Close</v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showErrorModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>
</section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

import _ from 'lodash'
import { segmentPage } from '@/utils'
import servicePackages from '@/components/service_package_constants'
import { CURRENT_YEARLY_PLANS, UNLIMITED_SUBSCRIPTION_SERVICES, PURE_PAKCAGE_TO_DISP_NAME_MAP } from '@/components/plans_constants'
// import DynamicServiceCard from '@/components/Register/DynamicServiceCard.vue'
import ServiceExplainList from '@/components/Register/ServiceExplainList.vue'
import { mixin as RegisterMixin } from '@/components/Register/register_mixin'
import { getPureServicePkgName } from "@/utils";

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'


export default {
  mixins: [RegisterMixin],
  components: {
    VueSlickCarousel,
    // ServiceCard: DynamicServiceCard,
    ServiceExplainList,
  },
  data () {
    return {
      serviceFromRoute: {},
      servicePackages: servicePackages,
      
      currentStep: 1, // 1: add info, 2: add payment
      isShowInputCouponBox: false,
      payViaInvoice: false,


      // colors: [
      //   'indigo',
      //   'warning',
      //   'pink darken-2',
      //   'red lighten-1',
      //   'deep-purple accent-4',
      // ],
      // slides: [
      //   'First',
      //   'Second',
      //   'Third',
      //   'Fourth',
      //   'Fifth',
      // ],
      carouselSetting: {
        "dots": true,
        "dotsClass": "slick-dots dot-item",
        "edgeFriction": 0.35,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "autoplay": true,
        "autoplaySpeed": 5000,
        "centerPadding": '50px'
      },
      carouselInfo: [
        {
          says: "Page Optimizer Pro is a game changer. We use it for developing great content for our client's websites. The ability to demonstrate to potential clients what changes are needed on their pages is a great sales tool. We have used POP on our own web pages to bolster our SERP.",
          name: "Chuck Hersey",
          company: "Partner @ Team 218 Web Services",
          img: "user1.png",
        },
        {
          says: "POP is one of those ninja tools that 'luckily' only serious SEO's tend to use. I have used reports from POP to close some of the largest clients I have ever worked with. I love POP, this will be my 5th? year of renewal and I do not see it coming to an end. Kyle Roof and his team have done a fantastic job.",
          name: "Everhardt Strauss",
          company: "Director of Organic Search @ Search Labs",
          img: "user2.png",
        },
        {
          says: "After reworking our pages through POP for the proper structure and content, nearly every, if not all, of our page 2 and 3+ listings broke onto page 1. Within weeks, this took our company from a good business to an extremely busy and profitable venture while receiving two unsolicited offers from competitors for a buyout.",
          name: "Cris Carillo",
          company: "SEM @ Idea Tree Media",
          img: "user3.png",
        },
      ]
    }
  },
  computed: {
    computedTitle () {
      if (this.periodFreeCouponApplied) {
        const purePackage = getPureServicePkgName(this.appliedCoupon?.product || '')
        let packageName = PURE_PAKCAGE_TO_DISP_NAME_MAP[purePackage]
        if (!packageName && purePackage && this.isUnlimitedPlan(purePackage)) packageName = 'Unlimited'

        return [packageName || '', 'Period Free'].filter(Boolean).join(' ')
      }
      switch (this.serviceFromRoute.title) {
        case 'Basic':
          return 'POP Basic Plan Monthly';
        case 'Premium':
          return 'POP Premium Plan Monthly'
        default:
          return this.serviceFromRoute.title;
      }
    },
    isYearlyPackage () {
      return CURRENT_YEARLY_PLANS.includes(this.serviceFromRoute.code)
    },
    isAgencyPackage () {
      if (!_.isEmpty(this.servicePackages) && !_.isEmpty(this.serviceFromRoute)) {
        const { SERVICE_PKG_F, SERVICE_PKG_G, SERVICE_PKG_H, SERVICE_PKG_I, SERVICE_PKG_J, SERVICE_PKG_K } = servicePackages
        return [
          SERVICE_PKG_F.code,
          SERVICE_PKG_G.code,
          SERVICE_PKG_H.code,
          SERVICE_PKG_I.code,
          SERVICE_PKG_J.code,
          SERVICE_PKG_K.code
        ].includes(this.serviceFromRoute.code)
      }

      return false
    },
  },
  methods: {
    isUnlimitedPlan (plan) {
      return UNLIMITED_SUBSCRIPTION_SERVICES.includes(plan)
    },
    validateUserInputAndGoPaymentPage () {
      this.needDisplayValidationMessageForUserInfo = true;
      if (!this.validateInput()) { return; }
      this.$store.commit('showLoading')
      this.$store.dispatch('emailIsAvailable', this.email)
        .then(response => {
          if (response.data && response.data.status == 'FAILURE') {
              this.showErrorModal = true
              this.errorMsg = response.data.errorMsg
              return;
          } else {
            this.currentStep = this.STEPS.PAYMENT
            // apply the coupon if there's coupon already entered from the url
            // if (this.coupon)
            if (this.promoCode) { // effect apply coupon button automatically
              this.applyCoupon()
            }
          }
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
    },
    goToLogin(){
      this.$router.push('/login')
    },
    makePaymentDefault () {
      const service = this.mapRouteParamToService()
      const { price, code, description } = service
      this.serviceFromRoute = service
      this.purchaseAmt = price
      this.selectedServicePkg = code
      this.serviceDescription = description
    },
    mapRouteParamToService() {
        const { service } = this.$route.params
        const product = this.$route.query.product || 'unlimited'
        const servicePkg = Object.values(servicePackages).find(svcPkg => svcPkg.queryParam.value === product)
        let rlt = servicePkg || servicePackages.SERVICE_PKG_B2
        return Object.assign({}, rlt) // clone
    },
    applyCoupon() {
      if (!this.promoCode) {
        return;
      }
      this.makePaymentDefault() // to prevent coupon to be applied several times

      return this.fetchValidatedCoupons(false, true).then(coupons => {
        console.log('coupon to apply', coupons)
        try {
          const coupon = coupons && coupons.length ? coupons[0] : null
          this.appliedCoupon = coupon
          const amountOff = coupon ? coupon.amountOff : 0

          let price = 0
          if (coupon?.couponType == "Percent Off") {
            price = (100 - amountOff) / 100 * this.serviceFromRoute.price
            price = Math.round(price * 100) / 100
          }
          else {
            price = this.serviceFromRoute.price - amountOff
          }

          this.serviceFromRoute = Object.assign(this.serviceFromRoute,  { price })
          this.purchaseAmt = price
        } catch(e) {
          console.error(e)
        }
      })
    },
    simplifyProductName(name) {
      if (!name) return '';
      let result = name;
      return result.replace('PageOptimizer ', '').replace('(', '<br/>(')
    },
  },
  mounted () {
    console.log(this.$route.params)

    this.$store.commit('clearStore')

    this.servicePackages = servicePackages
    this.promoCode = this.$route.query.affiliateCode && this.$route.query.affiliateCode!= "null" ? this.$route.query.affiliateCode : ''
    this.makePaymentDefault()
    if (this.promoCode) { // effect apply coupon button automatically
      this.isShowInputCouponBox = true;
      // this.applyCoupon() // this will be executed once user clicks the button 'Add payment method'
    }
    segmentPage('StandardRegister', { promoCode: this.promoCode })
    if (this.currentStep == this.STEPS.ADD_INFO) {
      this.viewItemEventActionForGTag() // g tag action for view item
    }

  }
}
</script>

<style scoped lang="scss">

.register-page {
  height: 100vh;
  overflow: auto;
  background: var(--v-whiteColor-base);
}

.a-link {
  text-decoration: underline;
}

.trial-markbox {
  border: 1px solid var(--v-mainColor-base);
  border-radius: 6px;
  padding: 3px 10px;
  font-size: 12.8px;
}

.add-info-step-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  
  .left-part {
    display: flex;
    flex-direction: column;
    padding: 5vh 7vw;
    color: var(--v-mainColor-base);

    .input-part {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .plan-title {
        font-size: 50px;
        line-height: 60px;
        font-weight: 800;
        color: var(--v-mainColor-base);
        display: flex;
        align-items: center;
      }
      .input-boxes {
        max-width: 500px;

      }
    }
  }
  .right-part {
    position: relative;
    background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #D7F5FC 100%);

    .img-block1 {
      text-align: right;
      margin-top: 8vh;
      margin-right: 8vh;
      img {
        width: 23vw;
      }
    }

    .carousel-wrapper {
      position: absolute;
      bottom: 80px;
      left: 50%;
      width: 500px;
      transform: translateX(-50%);
    }

    .carousel-item {
      position: relative;
      background: var(--v-blue2Color-base);
      padding: 0px 30px 10px 30px;
      height: 300px;
      border-radius: 10px;

      .quota-img {
        margin-left: 30px;
        width: 60px;
        height: auto;
      }
      .user-say-text {
        margin-top: 20px;
        color: var(--v-whiteColor-base);
        font-weight: 600;
        line-height: 142%;
      }
      .user-info {
        margin-top: 40px;
        display: flex;
        align-items: center;
        img {
          width: 48px;
          height: 48px;
          margin-right: 20px;
          border-radius: 100%;
        }
        .name {
          font-weight: 600;
          color: var(--v-whiteColor-base);
        }
        .company {
          color: var(--v-whiteColor-base);
        }
      }
    }
    .dot-item {
      width: 30px !important;
      height: 30px !important;
      color: red !important;
    }
  }

  
}


.payment-step-box {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .top-part {
    padding: 10px 40px 8px 40px;
    border-bottom: 1px solid var(--v-gray12Color-base);
  }
  .main-part {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    & > div {
      width: 80%;

      .lets-complete {
        font-size: 33px;
        text-align: center;
        font-weight: 800;
        color: var(--v-mainColor-base);
      }
      .signup-desc {
        color: var(--v-mainColor-base);
        text-align: center;
        margin: 20px 0 20px 0;
        font-size: 16px;
      }
      .boxes {
        display: grid;
        grid-template-columns: 500px 400px;
        justify-content: center;
        gap: 22px;
        color: var(--v-mainColor-base);
        .order-box {
          border: 1px solid var(--v-gray12Color-base);
          border-radius: 10px;
          .block-section {
            padding: 15px 22px;
            border-bottom: 1px solid var(--v-gray12Color-base);
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .pay-block {
            padding: 0px 27px 20px 27px;
            .select-your-method {
              margin-top: 5px;
              font-size: 20px;
              font-weight: 800;
            }

            .register-free-button {
              width: 100%;
              background: linear-gradient(89.67deg, var(--v-yellow5Color-base) -10.39%, var(--v-yellow6Color-base) 82.51%);
              height: 38px;
              border-radius: 50vh;
              color: var(--v-whiteColor-base);
              font-weight: 700;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 22px;
                height: auto;
                margin-right: 10px;
              }
            }
            .credit-card-button {
              flex-grow: 1;
              flex-shrink: 1;
              flex-basis: 0;
              background: linear-gradient(89.67deg, var(--v-yellow5Color-base) -10.39%, var(--v-yellow6Color-base) 82.51%);
              height: 38px;
              border-radius: 50vh;
              color: var(--v-whiteColor-base);
              font-weight: 700;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 22px;
                height: auto;
                margin-right: 10px;
              }
            }
            .paypal-button {
              flex-grow: 1;
              flex-shrink: 1;
              flex-basis: 0;
              background: linear-gradient(89.67deg, var(--v-yellow5Color-base) -10.39%, var(--v-yellow6Color-base) 82.51%);
              background: linear-gradient(89.63deg, var(--v-blue3Color-base) 2.49%, var(--v-blue4Color-base) 84.49%);
              height: 38px;
              border-radius: 50vh;
              color: var(--v-whiteColor-base);
              font-weight: 700;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 22px;
                height: auto;
                margin-right: 10px;
              }
            }
            .credit-card-button:disabled,
            .paypal-button:disabled {
              background: var(--v-darkGrayColor-base);
            }
          }
        }
      }
      .plan-function-box {
        border: 1px solid var(--v-gray12Color-base);
        border-radius: 10px;
        padding: 20px 27px;
      }
    }
  }
  
  .user-say-text-2 {

  }
  .user-info-2 {
    margin-top: 18px;
    display: flex;
    align-items: center;
    img {
      width: 48px;
      height: 48px;
      margin-right: 20px;
      border-radius: 100%;
    }
    .name {
      font-weight: 600;
    }
    .company {
    }
  }
}

@media screen and (max-width: 1100px) {
  .add-info-step-box {
    display: block;
  }
  .input-boxes {
    max-width: initial !important;
  }
  .right-part {
    display: none;
  }
}

.service-package {
  min-height: 230px;
}

.is-service-benefit {
  font-weight: bold;
}

.credit-card-button-and-text {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .credit-card-button-and-text {
    flex-direction: column;
  }
}

.tab-circles {
  display: flex;
  justify-content: center;
  span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #ffcc00;
    margin: 5px 7px;
    cursor: pointer;
  }
  span.active {
    background: #ffcc00;
  }
}
</style>

<template>
<div id="app" :class="{ 'muli-font': loginOrRegisterView }">
  <v-app>
  <!-- <v-theme-provider root> -->
    <template v-if="isAllowedToEmbed">
      <app-spinner></app-spinner>

      <template v-if="isIndependentRoute">
        <router-view />
      </template>
      <template v-else>
        <!-- <app-header v-if="showAppHeader"></app-header> -->
        <router-link
          class="text-logo" to="/"
          v-if="!$store.state.isHideSidebar && !loginOrRegisterView && showNewHeader">
          <img src="/static/POP_white.png" width="55" />
        </router-link>

        <section class="main-content-wrapper">
          <div style="position: relative;">
            <div
              style="position: absolute; height: 100vh;"
              v-show="showMenu && !isHideSidebar"
            >
              <app-menu ref="appMenu" />
            </div>
            <div :class="{ 'responsive-with-sidebar': showMenu && !isHideSidebar }" class="dashboard-right-area">
              <template v-if="isLoggedIn && user && !isFreeUser">
                <div v-if="!user.activeSubscriber && (activeSubscription || ( lastSubscriptionPurchase && lastSubscriptionPurchase.cancelledOn ))" class="trial-notify-topbar">
                  <template v-if="activeSubscription && isFutureDate(user.timeLeftCancelledSubscription)">
                    You cancelled your subscription on {{ toLocalDateString(activeSubscription.cancelledOn) }}. You will have access to your POP plan until {{ toLocalDateString(user.timeLeftCancelledSubscription) }}.<br/>
                    After your plan ends you will still have access to your reports and unused purchased POP credits.
                    <router-link
                      tag="a" class="alwaysWhiteColor--text text-decoration-underline"
                      :to="`/useraccount/${user.id}?mainTab=billing-and-payments&subTab=change-plan`">
                      Reactivate your subscription here
                    </router-link>
                  </template>
                  <template v-else-if="lastSubscriptionPurchase && lastSubscriptionPurchase.cancellationReason == 'payment_failed'">
                    We cancelled your subscription on {{ toLocalDateString(lastSubscriptionPurchase.cancelledOn) }} due to a failed charge. <br />
                    To continue using POP with your plan, please review your payment method and resubscribe in Account settings >> Billing and payments.
                  </template>
                  <template v-else-if="lastSubscriptionPurchase">
                    You cancelled your subscription on {{ toLocalDateString(lastSubscriptionPurchase.cancelledOn) }}.&nbsp;
                    <router-link
                      tag="a" class="alwaysWhiteColor--text text-decoration-underline"
                      :to="`/useraccount/${user.id}?mainTab=billing-and-payments&subTab=change-plan`">
                      Reactivate your subscription here
                    </router-link>
                  </template>
                </div>
                <div v-else-if="isTrialUser" class="trial-notify-topbar d-flex flex-wrap align-center justify-end">
                  <span v-if="isUserInTrialPlan" class="flex-grow-1">
                    Your trial period ends on
                    {{ toLocalDateString(user.trialEndingDate) }}. During your
                    trial period you cannot use a domain that you or someone else
                    has previously used.
                  </span>
                  <span v-if="isWatchdogOnlyUser && lastSubscriptionPurchase" class="flex-grow-1">
                    Your subscription was downgraded to the Watchdog Only plan on {{ toLocalDateString(lastSubscriptionPurchase.createdAt) }}
                  </span>
                  <trial-conversion-button class="ml-5" />
                  <v-btn rounded small
                    color="whiteColor"
                    class="text-none font-weight-bold blue1Color--text ml-2"
                    @click="() => $router.push(`/useraccount/${ user.id }/purchase`)">Upgrade now
                  </v-btn>
                </div>
                <div class="trial-notify-topbar" v-if="user.haveWatchdogBut0Credits">
                  We noticed that your POP credits have reached 0 and you have upcoming Watchdog runs scheduled. <br />
                  If you would like to continue to keep your pages monitored for Google updates or changes in your competitors, please add credits to your account or upgrade to a larger plan. 
                  <router-link class="alwaysWhiteColor--text text-decoration-underline"
                    :to="`/useraccount/${user.id}?mainTab=billing-and-payments&subTab=buy-credits`">
                    Buy more credits
                  </router-link>
                </div>
              </template>

              <template v-if="isLoggedIn && user && user.isPausedUnlimited">
                <div class="trial-notify-topbar">
                  Account Paused: Due to Fair Use Policy Violation, your account has been temporarily suspended. <br />
                  Don't worry, you can still access our services using purchased credits. Contact our support team to review and restart your account.
                </div>
              </template>
              <template v-if="showNewHeader">
                <div class="notifications-area">
                  <template v-if="hasNotifications">
                    <div
                      class="notification-in-dashboard"
                      v-for="n in notifications"
                      :key="n.id"
                      :style="{ background: n.color ? n.color : '#ff9b00' }"
                    >
                      <div class="flex justify-center has-text-white px-4">
                        <span class="has-text-weight-bold" v-html="n.message"></span>
                      </div>
                      <button class="delete"
                        @click="() => deleteNotification(n.id)"
                      ></button>
                    </div>
                  </template>
                </div>
                
                <new-header class="new-app-header" />
                <div
                  id="mainBodyScrollArea"
                  :class="{
                    'main-area-padding': showNewHeader,
                    'overflow-auto': true,
                  }"
                  :style="{ 'padding-bottom': `${showAppHeader ? 15 : 0}px` }"
                >
                  <div v-if="user.isVendor" class="has-text-centered is-italic what-account-im-in-now">
                    * 3rd party vendor accounts cannot create projects or create page reports.
                  </div>
                  <!-- <div v-if="isSpecialAdminUser" class="d-flex align-center my-2 mx-2">
                    <v-btn x-small color="profileAnchorColor"
                      class="text-none whiteColor--text"
                      @click="() => {
                        $router.push('/ui-pre-design?type=keyword-wizard')
                      }">
                      Open UI (Supporting keyword research)
                    </v-btn>
                  </div> -->
                  <div class="mt10 ml20 mr20 mb-5 navigate-bread-part" v-if="isShowQuickNav">
                    <div class="breadcrumbs-area">
                      <template>
                        <router-link v-if="['Pages', 'PageRuns', 'PageRun', 'GuardDashboard'].includes($route.name)" :to="`/sites/${site.id}/pages`"
                          class="back-to-home inline-flex align-center mainColor--text">
                          <span class="bread-crumb-size mainColor--text" :class="{ 'font-weight-bold': ['Pages'].includes($route.name) }">
                            {{ site.name }}
                          </span>
                        </router-link>
                        <router-link v-if="['PageRuns', 'PageRun', 'GuardDashboard'].includes($route.name)" :to="`/sites/${site.id}/pages/${page.id}`"
                          class="back-to-home inline-flex align-center mainColor--text">
                          <i class="material-icons arrow-right-size">chevron_right</i>
                          <span class="bread-crumb-size mainColor--text" :class="{ 'font-weight-bold': ['PageRuns', 'PageRun'].includes($route.name) }">
                            {{ page.name }}
                          </span>
                        </router-link>
                      </template>
                    </div>
                    <div class="grid-sort-filter-block" v-show="isShowQuickNav">
                      <span v-if="$route.name == 'Sites'">
                        <span class="">Sort by</span>
                        <div>
                          <select @change="e => { $store.commit('setSortModeSites', e.target.value); }">
                            <option
                              value="recent"
                              :selected="$store.state.sortModeSite == 'recent'"
                              >Most recent project
                            </option>
                            <option
                              value="recent_report"
                              :selected="
                                $store.state.sortModeSite == 'recent_report'
                              "
                              >Most recent report</option
                            >
                            <option
                              value="oldest_report"
                              :selected="
                                $store.state.sortModeSite == 'oldest_report'
                              "
                              >Oldest report</option
                            >
                            <option
                              value="alphabetic"
                              :selected="
                                $store.state.sortModeSite == 'alphabetic'
                              "
                              >Alphabetic</option
                            >
                            <option
                              value="best_score"
                              :selected="
                                $store.state.sortModeSite == 'best_score'
                              "
                              >Best score</option
                            >
                            <option
                              value="worst_score"
                              :selected="
                                $store.state.sortModeSite == 'worst_score'
                              "
                              >Worst score</option
                            >
                          </select>
                        </div>
                      </span>
                      <span v-if="['ListTools', 'Pages'].includes($route.name)">
                        <span class="">Sort by</span>
                        <div>
                          <select
                            @change="
                              e => {
                                $store.commit('setSortModePages', e.target.value);
                              }
                            "
                          >
                            <option
                              value="recent"
                              :selected="$store.state.sortModePage == 'recent'"
                              >Most recent page
                            </option>
                            <option
                              value="recent_report"
                              :selected="
                                $store.state.sortModePage == 'recent_report'
                              "
                              >Most recent report</option
                            >
                            <option
                              value="oldest_report"
                              :selected="
                                $store.state.sortModePage == 'oldest_report'
                              "
                              >Oldest report</option
                            >
                            <option
                              value="alphabetic"
                              :selected="
                                $store.state.sortModePage == 'alphabetic'
                              "
                              >Alphabetic</option
                            >
                            <option
                              value="best_score"
                              :selected="
                                $store.state.sortModePage == 'best_score'
                              "
                              >Best score</option
                            >
                            <option
                              value="worst_score"
                              :selected="
                                $store.state.sortModePage == 'worst_score'
                              "
                              >Worst score</option
                            >
                          </select>
                        </div>
                      </span>
                      <span v-if="$route.name == 'PageRuns'">
                        <span class="">Sort by</span>
                        <div>
                          <select
                            @change="
                              e => {
                                $store.commit(
                                  'setSortModePageruns',
                                  e.target.value
                                );
                              }
                            "
                          >
                            <option
                              value="recent"
                              :selected="$store.state.sortModePageRun == 'recent'"
                              >Most recent</option
                            >
                            <option
                              value="alphabetic"
                              :selected="
                                $store.state.sortModePageRun == 'alphabetic'
                              "
                              >Alphabetic</option
                            >
                            <option
                              value="worstscore"
                              :selected="
                                $store.state.sortModePageRun == 'worstscore'
                              "
                              >Worst score</option
                            >
                            <option
                              value="bestscore"
                              :selected="
                                $store.state.sortModePageRun == 'bestscore'
                              "
                              >Best score</option
                            >
                          </select>
                        </div>
                      </span>

                      <span v-if="['Sites'].includes($route.name) && user.ownerAccounts && user.ownerAccounts.length > 0">
                        <span>Account</span>
                        <select
                          v-model="selectedSubAccountRelation"
                          style="width: 200px"
                        >
                          <option selected disabled
                            >Please select one account</option
                          >
                          <option value="">My account</option>
                          <option disabled> ---------- </option>
                          <option disabled>
                            Accounts you have access to:
                          </option>
                          <option
                            :value="relation"
                            v-for="relation in user.ownerAccounts"
                            :key="relation.id"
                          >
                            {{ relation.agencyName }}
                            ( Permission:
                            {{
                              relation.isAllPermission
                                ? "All Projects"
                                : relation.permittedProjects.length == 1
                                ? relation.permittedProjects.length + " Project"
                                : +relation.permittedProjects.length +
                                  " Projects"
                            }}, Create Project:
                            {{ relation.canCreateProject ? "YES" : "NO" }} )
                          </option>
                        </select>
                      </span>

                      <span v-if="user.ownerDevAccounts && user.ownerDevAccounts.length > 0">
                        <span>Dev access</span>
                        <select
                          v-model="selectedDevAccountRelation"
                          style="width: 200px"
                        >
                          <option selected disabled
                            >Please select one account</option
                          >
                          <option value="">My account</option>
                          <option disabled> ---------- </option>
                          <option disabled>
                            Accounts you have access to as dev:
                          </option>
                          <option
                            :value="relation"
                            v-for="relation in user.ownerDevAccounts"
                            :key="relation.id"
                          >
                            {{ relation.ownerUserEmail }}
                          </option>
                        </select>
                      </span>
                    </div>
                  </div>
                  <router-view />
                </div>
              </template>
              <template v-else>
                <router-view />
              </template>
            </div>
          </div>
        </section>

        <div class="modal" :class="{ 'is-active': showSelectAccountModal }">
          <div class="modal-background"></div>
          <div class="modal-content" style="max-width: 650px">
            <div class="box modal-container" style="overflow: hidden;">
              <div>
                <div class="title-font mainColor--text is-size-4 mb20">
                  Please select one account
                </div>
                <div class="body-font mainColor--text is-italic mb20">
                  If you have been granted access to a team account select
                  that account from the drop down below
                </div>
                <div>
                  <div class="font-weight-bold mainColor--text mb10">Account</div>
                  <div class="select">
                    <select v-model="selectedSubAccountRelation">
                      <option selected :value="null" disabled
                        >Please Select One Account</option
                      >
                      <option value="">My account</option>
                      <option disabled> ---------- </option>
                      <option disabled> Accounts you have access to: </option>
                      <option
                        :value="relation"
                        v-for="relation in user.ownerAccounts"
                        :key="relation.id"
                      >
                        {{ relation.agencyName }}
                        ( Permission:
                        {{
                          relation.isAllPermission
                            ? "All Projects"
                            : relation.permittedProjects.length == 1
                            ? relation.permittedProjects.length + " Project"
                            : +relation.permittedProjects.length + " Projects"
                        }}, Create Project:
                        {{ relation.canCreateProject ? "YES" : "NO" }} )
                      </option>
                    </select>
                  </div>
                </div>
                <div class="control flex mt20">
                  <div class="flex-grow-1"></div>
                  <v-btn rounded
                    color="lightGreenColor"
                    class="button-with-icon whiteColor--text text-none"
                    :disabled="selectedSubAccountRelation == null"
                    @click="dismissSelectAccountModal"
                  >
                    Ok
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <notifications group="info" position="top right" style="width: 400px; max-width: 95vw;">
        <template slot="body" slot-scope="{ item, close }">
          <div class="custom-notification" @click="() => close()">
            <div class="icon-part"
              :class="{
                'success': item.type == 'success',
                'warning': item.type == 'warning',
                'error': item.type != 'success' && item.type != 'warning' }">
              <span v-if="item.type == 'success'" class="material-icons-outlined">check_circle</span>
              <span v-else-if="item.type == 'warning'" class="material-icons-outlined">report_problem</span>
              <span v-else class="material-icons-outlined">highlight_off</span>
            </div>
            <div class="text-part">
              <div class="title-text">
                <template v-if="item.type == 'success'">
                  {{ item.title || 'Success!' }}
                </template>
                <template v-else-if="item.type == 'warning'">
                  {{ item.title || 'Warning!' }}
                </template>
                <template v-else>
                  {{ item.title || 'Error!' }}
                </template>
              </div>
              <div class="body-text">
                {{ item.text }}
              </div>
            </div>
          </div>
        </template>
      </notifications>
      <notifications group="watchdog">
        <template slot="body" slot-scope="{ close }">
          <div class="watchdog-page-permission">
            <i class="fa fa-times close-btn" aria-hidden="true" @click="close"></i>
            <div>
              You are on Watchdog plan only. Please upgrade to subscription plan to make new runs.            
            </div>
            <div class="text-center mt20">
              <v-btn rounded outlined
                class="button-with-icon" @click="() => {
                close();
                $router.push(`/useraccount/${ user.id }/purchase`)
              }">Upgrade Now</v-btn>
            </div>
          </div>
        </template>
      </notifications>
    </template>
    <template v-else>
      <div class="not-allowed-message">
        <div>
          It's not allowed to embed.
        </div>
      </div>
    </template>

    <terms-and-condition-for-period-user ref="termsAndConditionForPeriodUser"></terms-and-condition-for-period-user>

    <tutorial-video></tutorial-video>
  <!-- </v-theme-provider> -->
  </v-app>
</div>
</template>

<script>
import { mixin } from "@/components/mixin";

import AppMenu from "@/components/Menu.vue";
import AppSpinner from "@/components/Spinner.vue";
import ReactivateSubscription from "@/components/ReactivateSubscription.vue";
import TrialConversionButton from "@/components/TrialConversionButton.vue";
import NewHeader from "@/components/NewHeader.vue";
import TermsAndConditionForPeriodUser from "@/components/TermsAndConditionForPeriodUser.vue";
import TutorialVideo from "@/components/TutorialVideo.vue";

import { toLocalDateString, isFutureDate, getPureServicePkgName, sendUserInfoToAppcues, serviceLabel } from "@/utils";
import { 
  PURE_TRIAL_PLANS, freePlans, additionalSeatPlans,
  PRICE_OF_EACH_VERSIONING, CURRENT_YEARLY_PLANS
} from "@/components/plans_constants";

export default {
  name: "App",
  mixins: [mixin],
  components: {
    AppSpinner,
    AppMenu,
    ReactivateSubscription,
    TrialConversionButton,
    NewHeader,
    TermsAndConditionForPeriodUser,
    TutorialVideo,
  },
  data() {
    return {
      isAvailableChromeExtension: false,
      includePageBreadCrumbRoutes: [
        "Pages",
        "Page",
        "NewPage",
        "PageRun",
        "PageRuns",
        "RerunAnalysis"
      ],
      includePageRunBreadCrumbRoutes: ["PageRun", "PageRuns", "RerunAnalysis"],

      showSelectAccountModal: false,
      selectedSubAccountRelation: null,
      selectedDevAccountRelation: null,
    };
  },
  watch: {
    selectedSubAccountRelation: function(newVal, oldVal) {
      if (this.isIndependentRoute) return;

      if (!this.isAuthenticated) {
        console.log(
          "You are not logged in yet so we are ignoring your sub account selection"
        );
        return;
      }

      this.$store.commit("setCurrentSubAccount", newVal);
      this.$store.commit("setCurrentSiteById", { siteId: -1 });
      this.$store.commit("setCurrentPageById", { pageId: -1 });
      this.loadSitesForAccountChange();
    },
    selectedDevAccountRelation: function(newVal, oldVal) {
      if (this.isIndependentRoute) return;
      if (!this.isAuthenticated) {
        console.log(
          "You are not logged in yet so we are ignoring your dev account selection"
        );
        return;
      }

      this.$store.commit("setCurrentDevAccount", newVal);
    },
    user: function(newVal, oldVal) {
      if (this.isIndependentRoute) return;

      if (Object.keys(newVal).length > 0 && newVal.isValidated) {
        if (Object.keys(oldVal).length == 0) {
          this.checkAndShowAutoDisplayModals(newVal);
          this.sendTokenToExtension();
          sendUserInfoToAppcues(newVal)
        }
        this.calculateCustomLogoBase64();
        let isDarkMode = newVal.settings ? newVal.settings['isDarkMode'] : false;
        if (isDarkMode) {
          this.$vuetify.theme.dark = true;
        }
      }
    },
    $route(to, from) {
      if (this.isRouteForProveSourceScript) {
        this.addProveSourceScript();
        this.addFacebookPixcelScript();
      } else {
        // console.log('removing prove source & facebook pixcel')
        this.removeProveSourceScript();
        this.removeFacebookPixcelScript();
      }

      if (this.isRouteForDiffUserScript) {
        this.addDiffUserScript()
      } else {
        this.removeDiffUserScript()
      }

      if (from && to && from.name == 'RegisterCheckEmail' && to.name == 'Sites') {
        this.checkAndShowAutoDisplayModals(this.user);
      }

    }
  },
  computed: {
    isAllowedToEmbed() {
      if (this.isIndependentRoute) {
        return true;
      }
      if (window.location !== window.parent.location) {
        return false;
      }
      return true;
    },
    isShowQuickNav() {
      const excludeRoutes = [
        'AdminIndex',  
        'UserProfile',
        'NewBulkRun',
        'ServicePurchase',
        'AIWriterLogDashboard',
        'GuardDashboard',
        'Campaign',
      ]
      if (excludeRoutes.includes(this.$route.name)) {
        return false;
      }
      return true;
    },
    loginOrRegisterView() {
      return this.$store.getters.isLoginOrRegister(this.$route.name);
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    jwt () {
      return this.$store.getters.getToken
    },
    isTrialUser() {
      return this.$store.getters.isTrialUser;
    },
    isFreeUser () {
      if (!this.user) return false;
      if (freePlans.includes(this.user.accountType)) {
        return true;
      }
      if (this.user.isFreePeriodUser) {
        return true;
      }
      return false;
    },
    isSpecialAdminUser () {
      return this.$store.getters.isSpecialAdminUser
    },
    isUserInTrialPlan () {
      if (!this.user) {
        return false;
      }
      return PURE_TRIAL_PLANS.includes(getPureServicePkgName(this.user.accountType))
    },
    isWatchdogOnlyUser() {
      return this.$store.getters.isWatchdogOnlyUser
    },
    isHideSidebar() {
      return this.$store.state.isHideSidebar;
    },

    isIndependentRoute() {
      const excludeRoutes = [
        "DemoPage",
        "DemoContentBriefPage",
        "UsageStatsPage",
        "ExtChecklist",
        "EmailChangeResult",
        "FullPageSourceEditor",
        "APIDoc",
        "OpenAITest",
        "UpdatePassword",
        "UnsubscribeWatchdog",
        "UIPreDesign",
        "PlaywithOpenai",
      ];
      return excludeRoutes.includes(this.$route.name);
    },
    isRouteForProveSourceScript() {
      const routeNames = [
        "StandardRegister",
        "DynamicRegister",
        "RegisterCheckEmail"
      ];
      return routeNames.includes(this.$route.name);
    },
    isRouteForDiffUserScript () {
      const routeNames = [
        "CancelSubscriptionConfirm",
        "TellUsYourExperience",
        "SubscriptionCancelSucceed"
      ];
      return routeNames.includes(this.$route.name);      
    },
    showMenu() {
      const excludeRoutes = [
        "Home",
        "Login",
        "PopDemo",
        "StandardRegister",
        "DynamicRegister",
        "RegisterNoCard",
        "RegisterCheckEmail",
        "UpdatePassword",
        "ForgotPassword",
        "RegisterService",
        "AdminIndex",
        "NewSite",
        "NewPage",
        "RerunAnalysis",
        "CancelSubscriptionConfirm",
        "TellUsYourExperience",
        "SubscriptionCancelSucceed",
      ];
      return !excludeRoutes.includes(this.$route.name);
    },
    showAppHeader() {
      const excludeRoutes = ["NewSite", "NewPage", "RerunAnalysis"];
      return !excludeRoutes.includes(this.$route.name);
    },
    showNewHeader() {
      const excludeRoutes = [
        "Login",
        "PopDemo",
        "StandardRegister",
        "DynamicRegister",
        "RegisterNoCard",
        "RegisterCheckEmail",
        "NewSite",
        "NewPage",
        "RerunAnalysis",
        "ForgotPassword",
        "CancelSubscriptionConfirm",
        "TellUsYourExperience",
        "SubscriptionCancelSucceed"
      ];
      return !excludeRoutes.includes(this.$route.name);
    },
    showUpgrade() {
      const excludeRoutes = [
        "ServicePurchase",
        "UserProfile",
        "Home",
        "Login",
        "PopDemo",
        "StandardRegister",
        "DynamicRegister",
        "RegisterCheckEmail",
        "UpdatePassword",
        "ForgotPassword",
        "RegisterService",
        "AdminIndex",
        "NewSite",
        "NewPage",
        "RerunAnalysis"
      ];
      return this.isAuthenticated && !excludeRoutes.includes(this.$route.name);
    },
    hasNotifications() {
      return this.showMenu && !!this.notifications.length;
    },
    isLogin() {
      return this.$route.name === "Login" || this.$route.name === "Home";
    },
    isLoggedIn () {
      if (this.user && Object.keys(this.user).length > 0) {
        return true;
      }
      return false;
    },
    user() {
      return this.$store.state.user;
    },
    activeSubscription() {
      try {
        return this.user.purchases.find(
          p => p.id == this.user.activeSubscription
        );
      } catch (e) { }
      return null
    },
    lastSubscriptionPurchase() {
      try {
        let found = this.user.purchases.find(p => {          
          return !freePlans.includes(p.servicePackage) && p.subscriptionId && !additionalSeatPlans.includes(getPureServicePkgName(p.servicePackage))
        })
        return found
      } catch (e) { }
      return null;
    },
    customLogo() {
      return this.user.customLogo;
    },
    notifications() {
      if (
        !this.user ||
        !this.user.notifications ||
        !this.user.notifications.length
      ) {
        return [];
      }
      return this.user.notifications;
    },
    unReadNotificaitons() {
      return this.notifications.filter(n => !n.isReadByUser);
    },
    pageBreadCrumb() {
      const obj = {
        show: this.includePageBreadCrumbRoutes.includes(this.$route.name)
      };
      if (obj.show) {
        obj.siteId = this.$route.params.siteId;
      }
      return obj;
    },
    pageRunBreadCrumb() {
      const obj = {
        show: this.includePageRunBreadCrumbRoutes.includes(this.$route.name)
      };
      if (obj.show) {
        obj.siteId = this.$route.params.siteId;
        obj.pageId = this.$route.params.pageId;
      }
      return obj;
    }
    //   selectedOwnerAccount () {
    //     return this.$store.state.selectedSubAccountRelation
    //   }
  },
  methods: {
    dispatch_custom_event: function ( name, data ) {
      if (!this.isAvailableChromeExtension) {
        console.log("chrome extension is not installed!")
        return window.alert("Please check you installed google extension!")
      }
      // the web application should fire this event to trigger the extension
      // the extension will listen for this event

      var event = new CustomEvent(

        "web_app_event",
        {
          detail: {
            name: name,
            data: data,
          },
          bubbles: true,
          cancelable: true
        }
      );
      console.log('Dispatching event to extension', name)
      document.dispatchEvent( event );
    },
    sendTokenToExtension () {
      setTimeout(() => {
        if (this.isAvailableChromeExtension) {
          return this.dispatch_custom_event( "set_localStorage", {
            token: this.jwt
          });
        }        
      }, 500);
      
    },
    firePurchaseGtagEvent (p) {
      try {
        let data = {
          event: "purchase",
          transaction_id: p.subscriptionId,
          ecommerce: {
            value: PRICE_OF_EACH_VERSIONING[p.servicePackage],
            currency: "USD",
            items: [
                {
                    item_id: p.servicePackage,
                    item_name: serviceLabel(p.servicePackage),
                    item_category: CURRENT_YEARLY_PLANS.includes(p.servicePackage) ? 'Yearly' : 'Monthly',
                    price: p.credits,
                    discount: PRICE_OF_EACH_VERSIONING[p.servicePackage] - p.credits,
                }
            ]}
        }
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push(data);
      } catch (e) {
        console.log('error on sending g tag event purchase', e)
      }
    },
    checkAndShowAutoDisplayModals (userObj) {
      console.log('check auto display modal stuff', userObj)
      // console.log(userObj, !userObj, !userObj.email, ! Object.keys(userObj).length == 0)
      if (!userObj || !userObj.email || Object.keys(userObj).length == 0) return; // prevent modal open if no user obj provided.

      console.log('checkAndShowAutoDisplayModals', userObj)
      if (!userObj.activeSubscription && userObj.purchases && userObj.purchases.length > 0) { // disable modal appaer if registration confirm with paypal because this will redirect to paypal.
        const purchase = userObj.purchases[0]
        if (['RegisterCheckEmail'].includes(this.$route.name)) {
          if (!purchase.subscriptionId && purchase.approvalUrl && !purchase.subscriptionStatus) {
            return;
          }
        }
      }

      if (this.$route.query.silenceModal) {
        if (userObj.purchases.length == 1 && userObj.purchases[0].subscriptionId) {
          console.log('fire g tag purchase complete action', userObj.purchases[0])
          this.firePurchaseGtagEvent(userObj.purchases[0])
        }
        return;
      }

      const settings = Object.assign({}, userObj.settings);

      if ( userObj.ownerAccounts && userObj.ownerAccounts.length > 0) {
        this.showSelectAccountModal = true;
      }
      if (userObj.isFreePeriodUser && !settings.acknowlegedForPeriodUser) {
        if (this.activeSubscription?.couponInfo?.isIMGcoupon) {
          this.$refs.termsAndConditionForPeriodUser.show();
        }
      }
    },
    loadSitesForAccountChange() {
      this.$store.commit("showLoading");
      this.$store
        .dispatch("loadSites")
        .then(response => {
          if (response.data && response.data.status === "SUCCESS") {
            this.$store.commit("setSites", response.data.sites);
            // this.$router.push('/sites')
          }
        })
        .finally(() => this.$store.commit("hideLoading"));
    },
    dismissSelectAccountModal() {
      if (this.selectedSubAccountRelation == null) {
        return;
      }
      this.showSelectAccountModal = false;
    },
    toLocalDateString(date) {
      return toLocalDateString(date);
    },
    isFutureDate(dateStr) {
      return isFutureDate(dateStr);
    },
    deleteNotification(notificationId) {
      return this.$store
        .dispatch("deleteUserNotification", {
          userId: this.user.id,
          notificationId: notificationId
        })
        .then(() => {
          return this.$store.dispatch("loadUser").then(response => {
            this.$store.commit("setUser", response.data.user);
          });
        });
    },
    addTawkChat() {
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src='https://embed.tawk.to/5b7a8cacafc2c34e96e7b941/default';
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1,s0);
    },
    addAppcues () {
      if (IS_LIVE_MODE) {
        window.AppcuesSettings = {
          enableURLDetection: true
        };
        
        const script = document.createElement('script');
        script.src = '//fast.appcues.com/147712.js'; // Replace with the actual Appcues script URL
        script.async = true;
        document.head.appendChild(script);
        script.onload = this.initializeAppcues;
      }
    },
    addProveSourceScript() {
      let proveSourceScript = document.createElement("script");
      proveSourceScript.charset = "UTF-8";
      proveSourceScript.setAttribute("crossorigin", "*");
      proveSourceScript.setAttribute("id", "prove_source_script_tag");
      proveSourceScript.innerHTML = `
        !function(o,i){window.provesrc&&window.console&&console.error&&console.error("ProveSource is included twice in this page."),provesrc=window.provesrc={dq:[],display:function(){this.dq.push(arguments)}},o._provesrcAsyncInit=function(){provesrc.init({apiKey:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50SWQiOiI1ZWMzYWU2YmRiMGNiMjRlOGU2NmQ0NTMiLCJpYXQiOjE1ODk4ODI0NzV9.d9k5j_50uNXIFmR9msJ0pgGS3tTlsDePb8u-qpUyPpo",v:"0.0.4"})};var r=i.createElement("script");r.type="text/javascript",r.async=!0,r["ch"+"ar"+"set"]="UTF-8",r.src="https://cdn.provesrc.com/provesrc.js";var e=i.getElementsByTagName("script")[0];e.parentNode.insertBefore(r,e)}(window,document);
      `;
      document.head.appendChild(proveSourceScript);
    },
    removeProveSourceScript() {
      let proveSourceScript = document.querySelector(
        "#prove_source_script_tag"
      );
      if (proveSourceScript) {
        proveSourceScript.parentNode.removeChild(proveSourceScript);
      }
    },
    addFacebookPixcelScript() {
      let facebookPixcelScript1 = document.createElement("script");
      facebookPixcelScript1.charset = "UTF-8";
      facebookPixcelScript1.setAttribute("crossorigin", "*");
      facebookPixcelScript1.setAttribute("id", "facebook_pixcel_script1");
      facebookPixcelScript1.innerHTML = `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '307261890422391');
          fbq('init', '639688966659976');
          fbq('track', 'PageView');
      `;
      document.head.appendChild(facebookPixcelScript1);
    },
    removeFacebookPixcelScript() {
      let facebookPixcelScrip1 = document.querySelector(
        "#facebook_pixcel_script1"
      );
      if (facebookPixcelScrip1) {
        facebookPixcelScrip1.parentNode.removeChild(facebookPixcelScrip1);
      }
    },
    addDiffUserScript () {
      let diffUserScript = document.createElement("script");
      diffUserScript.charset = "UTF-8";
      diffUserScript.setAttribute("crossorigin", "*");
      diffUserScript.setAttribute("id", "diff_user_script1");
      diffUserScript.innerHTML = `
        (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
        vgo('setAccount', '25569980');
        vgo('setTrackByDefault', true);
        vgo('process');
      `;
      document.head.appendChild(diffUserScript);
    },
    removeDiffUserScript() {
      let diffUserScript = document.querySelector("#diff_user_script1");
      if (diffUserScript) {
        diffUserScript.parentNode.removeChild(facebookPixcelScrip1);
      }
    },
    initializeAppcues () {
      // Initialize Appcues or use its functions here
      // For example:
      // window.Appcues.identify('user_id', { });
      // window.Appcues.page();
      // ...other Appcues functions
    },

    calculateCustomLogoBase64() {
      function toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
            callback(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      }
      if (this.customLogo) {
        toDataURL(this.customLogo, dataUrl => {
          console.log("RESULT:", dataUrl);
          this.$store.commit("setCustomLogoBase64", dataUrl);
        });
      }
    },
    checkExtensionIsInstalled () {
      if ( document.readyState === "interactive" || document.readyState === "complete" ) {
        if ( document.documentElement.dataset.page_optimizer_browser_extension_version ) {
          this.isAvailableChromeExtension = true
          // console.log('Adding event listener for POP extension!!')
          // document.addEventListener( "page_optimizer_browser_extension_event", this.extensionEventListener)
        } else {
          this.isAvailableChromeExtension = false
          console.log('can not find extension in this browser')
        };
      };
    },
  },
  mounted() {
    this.addAppcues();
    this.checkExtensionIsInstalled();
    
    if (this.isIndependentRoute) {
      console.log("this is independent rotue");
      return;
    }
    if (!this.isAllowedToEmbed) {
      console.log("detected someone to trying embed the page.");
      return;
    }
    this.selectedSubAccountRelation = this.$store.state.selectedSubAccountRelation;
    this.addTawkChat();
    if (this.isAuthenticated) {
      this.$store.dispatch("fetchGoogleSearchLocations");
      this.checkAndShowAutoDisplayModals(this.user);
      this.sendTokenToExtension();
    }

    if (this.isRouteForProveSourceScript) {
      this.addProveSourceScript();
      this.addFacebookPixcelScript();
    }

    this.calculateCustomLogoBase64();
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.text-logo {
  position: fixed;
  top: 30px;
  left: 20px;
  font-size: 2rem;
  font-weight: bold;
  z-index: 5;
  & span:first-child {
    color: black;
  }
  & span:nth-child(2) {
    color: #ff9b00;
  }
}

.what-account-im-in-now {
  color: #ff9b00;
  font-size: 1rem;
}
.navigate-bread-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;


  .breadcrumbs-area {
    display: flex;
    align-items: center;
    overflow: hidden;
    & > * {
      min-width: 0;
    }
    .arrow-right-size {
      margin-left: 5px;
      margin-right: 3px;
      font-size: 18px;
    }
    .bread-crumb-size {
      // these 2 from design
      /* font-size: 18px;
      line-height: 23px; */
      font-size: 15px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

}

.dashboard-right-area {
  box-sizing: border-box;
  // position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--v-mainBgColor-base);
  .main-area-padding {
    flex-grow: 1;
    position: relative;
    background: var(--v-mainBgColor-base);
  }

  .y-scroll-area {
    height: calc(100vh - 100px);
    /* overflow-y: auto; */
  }
}
.new-app-header {
  padding-top: 5px;
  background: var(--v-topHeaderBg-base);
}

.trial-notify-topbar {
  background-color: var(--v-blue1Color-base);
  color: white; // for making this always white on light and dark mode
  padding: 15px 15px;
  font-weight: bold;
  text-align: center;
}

.notification-show-hide-button {
  position: relative;
  font-size: 1rem;
  &:focus {
    color: white;
  }
  .badge {
    position: absolute;
    background: #fd0064;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 0.75rem;
    line-height: 20px;
    border: 1px solid white;
    top: -10px;
    right: -10px;
  }
}

.custom-notification {
  display: flex;
  background: var(--v-cardBg-base);
  border-radius: 10px;
  overflow: hidden;
  margin-top: 5px;
  .icon-part {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
  }
  .text-part {
    padding: 15px 10px;
    flex-grow: 1;
    .title-text {
      font-weight: 800;
      font-size: 1rem;
      color: var(--v-mainColor-base);
    }
    .body-text {
      margin-top: 5px;
      font-size: 0.9rem;
      color: var(--v-mainColor-base);
    }
  }
  .icon-part.success {
    background: var(--v-green21Color-base);
  }
  .icon-part.warning {
    /* background: #FE9A2D; */
    background: var(--v-yellow2Color-base);
  }
  .icon-part.error {
    background: var(--v-green21Color-base);
  }
}

.watchdog-page-permission {
  position: relative;
  margin-top: 10px;
  background: #fec671;
  border: 1px solid #ffb03a;
  border-radius: 10px;
  padding: 25px 25px 15px 25px;
  // color: white;
  font-weight: bold;

  .close-btn {
    position: absolute;
    top: 7px;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
  }
}
</style>

<template>
<div class="d-flex pl20 pr20">
  <div>
    <div class="all-tools-label">{{ site ? site.name : '' }}</div>
    <div class="new-tool-root mt-10">
      <pages-card></pages-card>
      <ai-writers-card></ai-writers-card>
      <keyword-research-card v-if="showKeywordTool"></keyword-research-card>
      <!-- <div class="tool-card pa-5"></div> -->
    </div>
  </div>
</div>
</template>

<script>
import { mixin } from '@/components/mixin'

import PagesCard from "@/components/Tools/PagesCard.vue";
import AiWritersCard from "@/components/Tools/AiWritersCard.vue";
import KeywordResearchCard from "@/components/Tools/KeywordResearchCard.vue";

export default {
  mixins: [ mixin ],
  components: {
    PagesCard,
    AiWritersCard,
    KeywordResearchCard
  },
  computed: {
    currentUser () {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return null
    },
    isSpecialAdminUser () {
      return this.$store.getters.isSpecialAdminUser
    },
    isSpecialOwnerSelected () {
      return this.$store.getters.isSpecialOwnerSelected
    },
    showKeywordTool () {
      if (this.isSpecialAdminUser || this.isSpecialOwnerSelected) {
        return true;
      }
      if (this.currentUser && this.currentUser.grantedDevModeTools?.length > 0) {
        return true;
      }
      return false;
    }
  },
  data () {
    return {
      
    }
  }
}
</script>


<style lang="scss">
@import "./index.scss";
</style>